import React, { useState } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import { useSetAtom } from 'jotai';
import { ENUM_DEVICE_TYPE } from '@/hooks/useDeviceType';
import { atomMenuTheme, atomLogoTheme } from '@/atom/atomPanelVisible';
import ContactUs from '../../common/ContactUs';
import SectionAbout from './modules/sectionAbout';
import SectionProduct from './modules/SectionProduct';
// import SectionNews from "./modules/sectionNews";
import CallUs from './modules/callUs';
import { cultureContent, getCultureContent } from './config';
import './index.scss';

const Culture = () => {
    const getDeviceType = (_clientWidth: number) => {
        if (_clientWidth < 768) {
            return ENUM_DEVICE_TYPE.MOBILE;
        } else if (_clientWidth > 768 && _clientWidth < 1024) {
            return ENUM_DEVICE_TYPE.IPAD;
        }
        return ENUM_DEVICE_TYPE.PC;

    };
    const [targetPage, setTargetPage] = useState(0);
    const pageSize = getDeviceType(document.body.clientWidth) === 'H5' ? 8 : 7;
    const scrollItem = Array.from({ length: pageSize }, (_, index) => index);
    const themeMap = ({
        PC: [
            'white',
            'white',
            'white',
            'white',
            'white',
            'black',
            'white',
            'white'],

        H5: [
            'white',
            'white',
            'white',
            'white',
            'white',
            'black',
            'black',
            'white',
            'white'],

        Ipad: [
            'white',
            'white',
            'white',
            'white',
            'white',
            'black',
            'black',
            'white']

    } as const);
    const themeLogoMap = ({
        PC: [
            'white',
            '#FF5010',
            '#FF5010',
            '#FF5010',
            '#FF5010',
            '#FF5010',
            '#FF5010',
            '#FF5010'],

        H5: [
            'white',
            'white',
            'white',
            'white',
            'white',
            '#FF5010',
            '#FF5010',
            'white',
            'white'],

        Ipad: [
            'white',
            'white',
            'white',
            'white',
            'white',
            '#FF5010',
            '#ff5010',
            '#FF5010']

    } as const);
    const setAtomDrawerFlag = useSetAtom(atomMenuTheme);
    const setAtomDrawerLogo = useSetAtom(atomLogoTheme);
    const anchors = Array.from(
        { length: pageSize },
        (_, index) => `page${index}`
    );

    return (
        <div className="culture">
            <div>
                <ReactFullpage
                    credits={{}}
                    // 每一部分的锚点, 就是url#后面的部分
                    anchors={anchors}
                    // navigation // 是否显示右侧小圆圈
                    // navigationTooltips={anchors} // 鼠标放到小圆圈上显示的提示
                    // 每一个部分你的背景色
                    sectionsColor={['#ffffff', '#ffffff', '#ffffff']}
                    // 离开某一部分你的回调, 参数分别表示从哪里开 去哪里 方向. 前两者是自定义类型nn, 方向是up或down
                    onLeave={(origin, destination, direction) => {}}
                    render={({ state, fullpageApi }) => {
                        if (state?.destination?.index || state?.destination?.index === 0) {
                            setTargetPage(state?.destination.index);
                        }
                        const width = document.body.clientWidth;const deviceType = getDeviceType(width);
                        setAtomDrawerFlag(
                            themeMap[deviceType][state?.destination?.index || 0]
                        );
                        setAtomDrawerLogo(
                            themeLogoMap[deviceType][state?.destination?.index || 0]
                        );
                        return (
                            <div style={{ width: '100vw', height: '100vh' }}>
                                <SectionAbout destination={state?.destination?.index} />
                                {cultureContent.map((item) => {
                                    return (
                                        <SectionProduct
                                            key={item.title()}
                                            {...item}
                                            isAboutPage={true}
                                            fullpageApi={fullpageApi}
                                            drawerFlag={
                                                themeMap[deviceType][state?.destination?.index || 0]}
                                            drawerLogo={
                                                themeLogoMap[deviceType][state?.destination?.index || 0]}
                                        />
                                    );


                                })}
                                {/* <SectionNews /> */}
                                <CallUs />
                                <ContactUs />
                            </div>
                        );


                    }}
                />


            </div>
            <div className="scroll">
                {scrollItem.map((item, key) => {
                    return (
                        <div
                            key={key}
                            className={item === 0 ? 'targetScroll' : ''}
                            style={{
                                transform: `translateY(calc(${targetPage * 100 / pageSize}*var(--vh)))`
                            }}
                        />
                    );


                })}
            </div>
        </div>
    );


};

export default Culture;