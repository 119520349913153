/*
 * @Author: liuliangliang liuliangliang@sniffgroup.com
 * @Date: 2024-03-01 16:10:24
 * @LastEditors: shiguang
 * @LastEditTime: 2024-03-12 10:57:16
 * @FilePath: /ckb-official-website-fed/src/container/JoinUs/index.tsx
 * @Description: 加入我们
 */
import React, { useEffect, useState } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import { useSetAtom } from 'jotai';
import useDeviceType, { ENUM_DEVICE_TYPE } from '@/hooks/useDeviceType';
import Scrollbar from '@/common/Scrollbar';
import { atomLogoTheme, atomMenuTheme } from '@/atom/atomPanelVisible';
import { strings } from '@/i18n/i18n';
import ContactUs from '../../common/ContactUs';
import SectionItem from './comonents/SectionItem';
import { ENUM_CONTENT_TYPE, JoinUsContentItem } from './config';
import FlagPageH5 from './comonents/FlagPageH5';

const JoinUs = () => {
    const setAtomDrawerFlag = useSetAtom(atomMenuTheme);
    const setAtomDrawerLogo = useSetAtom(atomLogoTheme);
    const { deviveType2 } = useDeviceType();
    const [currentPageSize, setCurrentPageSize] = useState(0);
    /** 加入我们-内容 */
    const joinUsContent: JoinUsContentItem[] = [
        {
            flag: strings.t('我们立志成为全球优秀雇佣公司'),
            flagDesc: strings.t(
                '我们的目标：消除办公场地、时间、距离限制、享受工作 与生活平衡的乐趣'
            ),

            contentTitle: strings.t('爱心'),
            contentType: ENUM_CONTENT_TYPE.LOVE,
            imgUrlPC:
    'https://static-s.theckb.com/BusinessMarket/offcialWebsite/joinus_bg_pc_1.png',
            imgUrlH5:
    'https://static-s.theckb.com/BusinessMarket/offcialWebsite/joinus_bg_h5_2.png?xx=111',
            btnText: strings.t('立即投递简历'),
            contentDesc: [
                strings.t('定期发放宠物基金，让铲屎官们轻松陪伴爱宠'),
                strings.t(
                    'SNIFF GROUP是一个温暖有爱的公司，我们深知宠物是铲屎官们非常重要的朋友和家人。为了让员工能更轻松的享受爱宠的陪伴，帮助员工照顾宠物，公司提供了专属的宠物福利——“宠物基金”。宠物基金每月按时发放，公司用实际行动为萌宠加餐啦！'
                )]
        },
        {
            flag: strings.t('我们立志成为全球优秀雇佣公司'),
            flagDesc: strings.t(
                '我们的目标：消除办公场地、时间、距离限制、享受工作 与生活平衡的乐趣'
            ),
            contentTitle: strings.t('孝心'),
            imgUrlPC:
    'https://static-s.theckb.com/BusinessMarket/offcialWebsite/joinus_bg_pc_1.png',
            imgUrlH5:
    'https://static-s.theckb.com/BusinessMarket/offcialWebsite/joinus_bg_h5_3.png',
            btnText: strings.t('立即投递简历'),
            contentType: ENUM_CONTENT_TYPE.SOLICITUDE,
            contentDesc: [
                strings.t('年末向员工父母发放慰问基金，感恩她们的付出'),
                strings.t(
                    'SNIFF 父母德高，子女良教。SNIFF GROUP在感念每一位员工为公司作出的奉献的同时，也感谢他们的父母为社会和公司培养了诸多优秀人才。对于父母的辛苦付出，SNIFF GROUP感念于心，感恩于行。为父母设立了感恩基金，每年直接向父母打款，以此来表达SNIFF GROUP对父母的尊敬和感谢。'
                )]

        },
        {
            flag: strings.t('我们立志成为全球优秀雇佣公司'),
            flagDesc: strings.t(
                '我们的目标：消除办公场地、时间、距离限制、享受工作 与生活平衡的乐趣'
            ),
            imgUrlPC:
    'https://static-s.theckb.com/BusinessMarket/offcialWebsite/joinus_bg_pc_1.png',
            imgUrlH5:
    'https://static-s.theckb.com/BusinessMarket/offcialWebsite/joinus_bg_h5_4.png',
            btnText: strings.t('立即投递简历'),
            contentTitle: strings.t('健康'),
            contentType: ENUM_CONTENT_TYPE.HEALTH,
            contentDesc: [
                strings.t('定期员工体检，鼓励同学们锻炼健康的体魄'),
                strings.t(
                    'SNIFF GROUP非常注重员工的身心健康，健康的身体是优质工作和生活的前提保障。SNIFF GROUP鼓励同学们经常锻炼身体，塑造健康的工作生活习惯。并为全体员工提供年度员工体检，以此来保障员工的身体健康。同时还有旅游基金，年度假期等等福利，让员工有时间放松自己，陪伴家人，更好的平衡工作和生活。'
                )]
        },
        {
            flag: strings.t('我们立志成为全球优秀雇佣公司'),
            flagDesc: strings.t(
                '我们的目标：消除办公场地、时间、距离限制、享受工作 与生活平衡的乐趣'
            ),
            contentTitle: strings.t('开放'),
            btnText: strings.t('立即投递简历'),
            imgUrlPC:
    'https://static-s.theckb.com/BusinessMarket/offcialWebsite/joinus_bg_pc_1.png',
            imgUrlH5:
    'https://static-s.theckb.com/BusinessMarket/offcialWebsite/joinus_bg_h5_5.png',
            contentType: ENUM_CONTENT_TYPE.OPENNESS,
            contentDesc: [
                strings.t('定期语言交流学习，多国家同学碰撞思想火花'),
                strings.t(
                    'SNIFF GROUP是一个国际化的公司，拥有多元的文化交流背景，日、韩、泰等多个国家地区的员工一起上班，互相交流学习，共同进步。我们一直以来坚持开放包容的态度，鼓励各国员工积极交流，互相探讨，不断开阔彼此视野。还会定期开放语言交流学习，来帮助同学们了解不同国家的文化，让多国同学们碰撞出更璀璨的思想火花。'
                )]

        },
        {
            flag: strings.t('我们立志成为全球优秀雇佣公司'),
            flagDesc: strings.t(
                '我们的目标：消除办公场地、时间、距离限制、享受工作 与生活平衡的乐趣'
            ),
            btnText: strings.t('立即投递简历'),
            imgUrlPC:
    'https://static-s.theckb.com/BusinessMarket/offcialWebsite/joinus_bg_pc_1.png',
            imgUrlH5: '',
            contentType: ENUM_CONTENT_TYPE.OFFICE_CITY
        }];

    const [pageSize, setPageSize] = useState(joinUsContent.length);


    useEffect(() => {
        window.fullpage_api.silentMoveTo(1);
    }, []);

    useEffect(() => {
        if (ENUM_DEVICE_TYPE.MOBILE === deviveType2) {
            setAtomDrawerFlag('white');
        } else {
            setAtomDrawerFlag('black');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initFullPage = (state) => {
    /** 获取当前页数 */
        if (state.destination) {
            setCurrentPageSize((state.destination.index as number));
            if (ENUM_DEVICE_TYPE.MOBILE === deviveType2) {
                if (state.destination.index === 6) {
                    setAtomDrawerLogo('#FF5010');
                    setAtomDrawerFlag('black');
                } else {
                    setAtomDrawerLogo('white');
                    setAtomDrawerFlag('white');
                }
            } else {
                if (state.destination.index === 5) {
                    setAtomDrawerLogo('#FF5010');
                    setAtomDrawerFlag('white');
                } else {
                    setAtomDrawerLogo('white');
                    setAtomDrawerFlag('black');
                }
            }
        }
        /** 初始化完成，获取总页数 */
        if (state.initialized) {
            setPageSize(state.sectionCount);
        }
    };
    return (
        <div className="culture">
            <div>
                <ReactFullpage
                    credits={{}}
                    // anchors={anchors} // 每一部分的锚点, 就是url#后面的部分
                    // navigation // 是否显示右侧小圆圈
                    // navigationTooltips={anchors} // 鼠标放到小圆圈上显示的提示
                    // 每一个部分你的背景色
                    sectionsColor={['rgba(0,0,0,0)', 'rgba(0,0,0,0)', 'rgba(0,0,0,0)']}
                    // 离开某一部分你的回调, 参数分别表示从哪里开 去哪里 方向. 前两者是自定义类型nn, 方向是up或down
                    onLeave={(origin, destination, direction) => {


                        // console.log("onLeave event", { origin, destination, direction });
                    }} render={({ state, fullpageApi }) => { initFullPage(state);return (
                        <div style={{ width: '100vw', height: 'calc(var(--vh) * 100)' }}>
                            {deviveType2 === ENUM_DEVICE_TYPE.MOBILE && <FlagPageH5 />}
                            {joinUsContent?.map((item) => { return <SectionItem {...item} key={item.contentType} />;
                            })}
                            <ContactUs />
                        </div>
                    );


                    }}
                />


                <Scrollbar length={pageSize} currentPageSize={currentPageSize} pageSize={pageSize} />
            </div>
        </div>
    );


};

export default JoinUs;