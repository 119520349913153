/*
 * @Author: shiguang
 * @Date: 2023-04-28 11:51:00
 * @LastEditors: liuliangliang liuliangliang@sniffgroup.com
 * @LastEditTime: 2024-03-07 21:26:24
 * @Description: interceptors
 */

import { message } from 'antd';
import { api, apiInstanceList } from '@/service';
// import { getRouter } from '../router/createRouter';
// import { getRouter } from '@/App';
export interface User {
  loginInfo: {
    userId: string;
    username: string;
  };
  userInfo: {};
  auth: never[];
  token: string;
}
const getUserInLocal = () => {
    const usrStr = window.localStorage.getItem('user');
    if (!usrStr) return;
    try {
        const user = JSON.parse(usrStr).user as User;
        return user;
    } catch (e) {
        return undefined;
    }
};


export enum ENUM_RES_CODE{
    /** token 过期 */
    EXPIRE = '10000000',
}

// export const MAP_RES_CODE = new Map([
//     [ENUM_RES_CODE.EXPIRE, {
//         handle: () => {
//             if(window.location.port){
//                 return getRouter().navigate('/dev/login');
//             }
//             window.location.pathname = '/summary';
//         }
//     }],
// ]);

apiInstanceList.forEach(item => {
    // 请求拦截
    item.instance.instance.interceptors.request.use(function (config) {
        config.headers = config.headers ?? {};
        const isPost = ['POST', 'post'].includes(config.method!);
        const token = getUserInLocal()?.token;
        const X_GRAY_TAG = '20240304-website';
        if (token) {
            config.headers['X-AuthToken'] = token;
        }
        if (isPost) {
            if (!config.data && config.params) {
                config.data = config.params;
            }
            config.params = {};
            config.headers['Content-Type'] = 'application/json';
        }
        if (X_GRAY_TAG) {
            config.headers['X-GRAY-TAG'] = X_GRAY_TAG;
        }
        return config;
    });

    // 返回拦截
    item.instance.instance.interceptors.response.use(function (response) {
        // const code = response.data?.code;
        // const codeConfig = MAP_RES_CODE.get(code);
        // console.log(96789, codeConfig);
        // if(codeConfig){
        //     codeConfig.handle();
        //     return Promise.reject(response.data.msg);
        // }
        if (response.data?.code === '0' && response.data.success === true) return response;
        message.error('请求错误：' + response.data.msg);
        return Promise.reject(response.data.msg);
    }, function (error) {
        message.error('请求错误：' + String(error));
        return Promise.reject(error);
    });
});
export const request = api;