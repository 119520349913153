import React, { useCallback, useEffect, useState } from 'react';
import './index.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
// import { Divider } from 'antd';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import moment from 'moment';
import { strings } from '@/i18n/i18n';
import { atomLogoTheme, atomMenuTheme } from '@/atom/atomPanelVisible';
import { ENUM_SYSTEM_LANGUAGE, atomSystemLanguage } from '@/atom/atomSystemLanguage';
import { atomRoutePathLangPre } from '@/atom/atomRoutePathLangPre';
import useDeviceType, { ENUM_DEVICE_TYPE } from '@/hooks/useDeviceType';
import { ArtcleDetailItem, ArticleListItem, getArticleListApi, getNewsDetailByNoApi, icon_arrow_left } from './config';


/** 资讯详情 */
const NewsDetail = () => {

    const setAtomDrawerFlag = useSetAtom(atomMenuTheme);
    const setAtomDrawerLogo = useSetAtom(atomLogoTheme);
    setAtomDrawerFlag('black');
    setAtomDrawerLogo('#FF5010');
    const [newsDetail, setNewsDetail] = useState<ArtcleDetailItem>();
    const [prevNextArticles, setPrevNextArticles] = useState<
    {prev: ArticleListItem | null;next: ArticleListItem | null;}>(
        {
            prev: null,
            next: null
        });
    const navigate = useNavigate();
    const [search] = useSearchParams();
    const no = (search.get('no') as string);
    const { deviceType } = useDeviceType();
    const systemLanguage = useAtomValue(atomSystemLanguage);

    /**
  * 获取资讯详情
  */
    const getNewDetail = useCallback(async () => {
        if (!no) {
            return;
        }
        const res = await getNewsDetailByNoApi(no);

        setNewsDetail(res.data);
    }, [no]);

    const getNewsList = useCallback(async () => {
        const res = await getArticleListApi();
        const currentNewsIndex = res.data.findIndex((item) => item.no === no);

        setPrevNextArticles({
            prev: res.data[currentNewsIndex - 1],
            next: res.data[currentNewsIndex + 1]
        });
    }, [no]);

    const getNewsContent = () => {
        if (systemLanguage === ENUM_SYSTEM_LANGUAGE.CHINESE) {
            return newsDetail?.content;
        }
        return newsDetail?.enContent as string;

    };

    const getNewsTitle = (_newsDetail?: ArticleListItem) => {
        const _detail = _newsDetail ?? newsDetail;
        if (systemLanguage === ENUM_SYSTEM_LANGUAGE.CHINESE) {
            return _detail?.title;
        }
        return _detail?.enTitle;

    };

    const handleClickPrev = async () => {
        window.scrollTo(0, 0);
        jumpTo(`/newsDetail?no=${prevNextArticles?.prev?.no}`);
    };

    const handleClickNext = async () => {
        window.scrollTo(0, 0);
        jumpTo(`/newsDetail?no=${prevNextArticles?.next?.no}`);
    };

    useEffect(() => {
        getNewDetail();
        getNewsList();
    }, [getNewDetail, getNewsList]);
    const routePathLangPre = useAtomValue(atomRoutePathLangPre);
    const jumpTo = (path: string) => {
        navigate(`/${routePathLangPre}${path}`);
    };
    useAtomValue(atomSystemLanguage);
    return (
        <>
            <div className="headMenuBg" />
            <div className="news-detail">
                <div className="news-detail-wrap">
                    {
                        deviceType !== ENUM_DEVICE_TYPE.MOBILE &&
          <div className="news-detail-nav">
              <div className="news-detail-link" onClick={() => jumpTo('/')}>{strings.t('首页')}</div>
              <div style={{ padding: '0 4px' }}>{'>'}</div>
              <div className="news-detail-link" onClick={() => jumpTo('/news')}>{strings.t('最新资讯')}</div>
              {/*  style={{color: '#FF5010'}} */}
              <div style={{ padding: '0 4px' }}>{'>'}</div>
              <div className="news-detail-link">{getNewsTitle()}</div>
          </div>}


                    <div className="news-detail-title !leading-[1.2] !font-bold">{getNewsTitle()}</div>
                    <div className="news-detail-date">{moment(newsDetail?.date).format('YYYY-MM-DD').replace(/-/g, '.')}</div>
                    <div
                        className="news-detail-content" dangerouslySetInnerHTML={{
                            __html: (getNewsContent() as string)
                        }}
                    />


                    {/* <Divider className='news-detail-footer-divider' /> */}
                    <div style={{ width: '100%', height: '1px', backgroundColor: 'var(--color-alpha-b10, rgba(0, 0, 0, 0.10))', margin: '48PX 0' }} />
                    <div className="news-detail-footer">
                        {
                            prevNextArticles.prev ?
                                <div className="news-detail-footer-pre">
                                    <img src={icon_arrow_left} alt="" className="pre-arrow-left" />
                                    <div onClick={handleClickPrev} className="footer-pre-title">
                                        {getNewsTitle((prevNextArticles?.prev as ArticleListItem))}
                                    </div>
                                </div> :
                                <div />}


                        {prevNextArticles.next &&
            <div className="news-detail-footer-next">
                <div className="footer-next-title" onClick={handleClickNext}>
                    {getNewsTitle((prevNextArticles?.next as ArticleListItem))}
                </div>
                <img src={icon_arrow_left} alt="" className="next-arrow-right" />
            </div>}

                    </div>
                </div>
            </div>
        </>
    );


};

export default NewsDetail;