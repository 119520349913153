import { serviceConfig } from '@/config/request/swaggerServiceConfig';
import { Api as Customer } from './customer';


export const Api = { Customer };

const warpperServiceConfig = (serviceConfig: any, ctx: { name: string; basePath: string; }) => {
    const newConfig = { ...serviceConfig };
    if (newConfig.baseURL) {
        newConfig.baseURL = newConfig.baseURL + ctx.basePath;
    }
    return newConfig;
};

const customer = new Customer(warpperServiceConfig(serviceConfig, { name: 'customer', basePath: '/customer' }));

export const apiInstanceList = [{ key: 'customer', instance: customer }];

export const api = { customer };