/*
 * @Author: liuliangliang liuliangliang@sniffgroup.com
 * @Date: 2024-03-05 15:41:04
 * @LastEditors: shiguang
 * @LastEditTime: 2024-03-12 20:36:26
 * @FilePath: /ckb-official-website-fed/src/container/Home/modules/sectionNews.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import './news.scss';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
// import { useSearchParams } from "react-router-dom";
import { useRequest } from 'ahooks';
// import { useTranslation } from "react-i18next";
import axios from 'axios';
import moment from 'moment';
import { useAtomValue } from 'jotai';
import { strings } from '@/i18n/i18n';
import { atomSystemLanguage, ENUM_SYSTEM_LANGUAGE } from '@/atom/atomSystemLanguage';
import { atomRoutePathLangPre } from '@/atom/atomRoutePathLangPre';
const SectionNews = () => {

    const _atomSystemLanguage = useAtomValue(atomSystemLanguage);
    // const { i18n } = useTranslation();
    function getArtcles() {
        return axios({
            url: `https://static-s.theckb.com/sniffGroup/article/articleList.prod.json?_time=${new Date().getTime()}`
        });
    }
    // const [searchParams] = useSearchParams();
    // const { lang: langInPath } = useParams();
    // const langInPath = "cn";
    // const currentPageNo = Number(searchParams.get("pageNo")) || 1;
    // const pageSize = Number(searchParams.get("pageSize")) || 10;

  interface artcleItem {
    classification: string;
    date: string;
    enImgUrl: string;
    enOverview: string;
    enTitle: string;
    formInstId: string;
    gmtCreate: string;
    gmtModified: string;
    imgUrl: string;
    overview: string;
    title: string;
  }

  const [artcles, setArtcles] = useState(([] as artcleItem[]));
  // const articleStation = langMapArticleStation[langInPath];
  const { run } = useRequest(getArtcles, {
      onSuccess(data) {
          const list = data.data
              .filter(({ title, enTitle }) => {
                  if (_atomSystemLanguage === ENUM_SYSTEM_LANGUAGE.ENGLISH && enTitle) {
                      return true;
                  }
                  if (_atomSystemLanguage !== ENUM_SYSTEM_LANGUAGE.ENGLISH && title) {
                      return true;
                  }
                  return false;
              });
          // setArtclesLength(list.length)

          // const displayRangeStart = currentPageNo * pageSize - pageSize;
          // const displayRangeEnd = currentPageNo * pageSize - 1;
          const _artcles = list.filter(
              (_, index) => index < 3
          );

          setArtcles(_artcles);
          console.log('_artcles', _artcles);
      }
  });

  useEffect(() => {
      run();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nav = useNavigate();
  const routePathPre = useAtomValue(atomRoutePathLangPre);
  const navigatorToDetail = (article) => {
      nav(`/${routePathPre}/newsDetail?no=${article.no}`);
  };
  return (
      <div className="section sectionNews" style={{ width: '100vw', height: 'calc(var(--vh) * 100)' }}>
          {/* <div className='headMenuBg'></div> */}
          <div className="title">{strings.t('SNIFF最新资讯')}</div>
          <div className="tip">{strings.t('最新资讯，随时随地让客户见证我们的成长。')}</div>
          <div className="btn" onClick={() => nav(`/${routePathPre}/news`)}>
              {strings.t('查看更多')}
              <img src="https://static-s.theckb.com/BusinessMarket/offcialWebsite/swap-right.png" alt="" />
          </div>
          <div className="articleBox">
              {
                  artcles.map((art) => {
                      return (
                          <div className="article" onClick={() => navigatorToDetail(art)} key={art.title}>
                              <div className="articleImg">
                                  <img src={_atomSystemLanguage === ENUM_SYSTEM_LANGUAGE.ENGLISH ? art.enImgUrl : art.imgUrl} alt="" />
                              </div>
                              <div className="articleTitle">{_atomSystemLanguage === ENUM_SYSTEM_LANGUAGE.ENGLISH ? art.enTitle : art.title}</div>
                              <div className="articleTime">
                                  {moment(art.date).format('YYYY-MM-DD').replace(/-/g, '.')}
                              </div>
                          </div>
                      );


                  })}


          </div>
      </div>
  );


};


export default SectionNews;