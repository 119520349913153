/*
 * @Author: liuliangliang liuliangliang@sniffgroup.com
 * @Date: 2024-03-04 16:25:12
 * @LastEditors: shiguang
 * @LastEditTime: 2024-03-14 21:43:21
 * @FilePath: /ckb-official-website-fed/src/container/JoinUs/comonents/CityInfoModal/index.tsx
 * @Description: 办公城市信息弹窗
 */
import React, { useEffect } from 'react';
import { Modal } from 'antd';
import useDeviceType, { ENUM_DEVICE_TYPE } from '@/hooks/useDeviceType';
import { ENUM_OFFICE_CITY, MAP_OFFICE_CITY_INFO } from '../../config';
import './index.scss';

function handleStopWheel(e) {
    e.stopPropagation();
}

interface CityInfoModalProps {
  /** 是否显示 */
  visible: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  cityCode: ENUM_OFFICE_CITY;
}
/** 办公城市信息弹窗 */
const CityInfoModal = (props: CityInfoModalProps) => {
    const { visible, cityCode, setIsModalOpen } = props;
    const { deviceType } = useDeviceType();
    /** 城市信息 */
    const cityInfo = MAP_OFFICE_CITY_INFO.get(cityCode);

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        if (!document.querySelector('.ant-modal-content')) return;
        if (visible) {
      document.querySelector('.ant-modal-content')!.addEventListener('wheel', (e) => {
          e.stopPropagation();
      });
        } else {
      document.querySelector('.ant-modal-content')!.removeEventListener('wheel', handleStopWheel);
        }
        return () => {
            if (!document.querySelector('.ant-modal-content')) return;
      document.querySelector('.ant-modal-content')!.removeEventListener('wheel', handleStopWheel);
        };
    }, [visible]);
    return (
        <Modal
            open={visible}
            footer={null}
            width={deviceType === ENUM_DEVICE_TYPE.PC ? '62.5%' : '100%'}
            onCancel={handleCancel}
            wrapProps={{

            }}
            className="city-info"
        >


            <div className="content">
                <div className="content-city-name">
                    {cityInfo?.cityName()}
                </div>
                {
                    cityInfo?.cityDesc().map((item) => {
                        return (
                            <div
                                className="content-city-desc" key={item} onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >


                                {item}
                            </div>
                        );


                    })}

                {
                    cityInfo?.cityDescImg.map((item) => {
                        return (
                            <img src={item} className="content-city-descImg" alt="" key={item} />);
                    })}

            </div>
        </Modal>
    );


};

export default CityInfoModal;