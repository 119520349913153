/*
 * @Author: liuliangliang liuliangliang@sniffgroup.com
 * @Date: 2024-03-05 11:08:38
 * @LastEditors: shiguang
 * @LastEditTime: 2024-03-12 14:12:38
 * @FilePath: /ckb-official-website-fed/src/container/Culture/config.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { strings } from '@/i18n/i18n';

export interface CultureContentItem {
  /** 标题 */
  title: () => string;
  /** 描述 */
  descList: (() => string)[];
  /** 背景图 */
  imgUrlPC?: string;
  imgUrlH5?: string;
  imgUrlIpad?: string;
}
export const maskImgPC = 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_pc_mask.png';
export const maskImgIpad = 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_pc_mask.png';
export const maskImgH5 = 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_h5_mask.png';

/** 文化模块-内容 */
export const cultureContent: CultureContentItem[] = [
    {
        title: () => strings.t('一个有生命力的团队'),
        descList: [
            () => strings.t('一个有文化的团队才称得上是团队，他们拥有共同的目标、愿景、思想、沟通方式、类似的兴趣爱好等等，这样才称得上是有生命力的团队。')],

        imgUrlPC: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_pc_1.png',
        imgUrlH5: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_h5_1.png',
        imgUrlIpad: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_ipad_1.png'
    },
    {
        title: () => strings.t('企业愿景'),
        descList: [() => strings.t('成为世界一流的跨境电商综合系统服务商。')],


        imgUrlPC: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_pc_2.png',
        imgUrlH5: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_h5_2.png',
        imgUrlIpad: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_ipad_2.png'
    },
    {
        title: () => strings.t('企业使命'),
        descList: [() => strings.t('让世界贸易变得更简单'), () => strings.t('帮助中国制造走出去，他国制造走进来，实现全球买、全球卖。')],


        imgUrlPC: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_pc_3.png',
        imgUrlH5: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_h5_3.png',
        imgUrlIpad: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_ipad_3.png'
    },
    {
        title: () => strings.t('企业文化'),
        descList: [() => strings.t('感恩 、分享、助力客户、成就员工。')],


        imgUrlPC: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_pc_4.png',
        imgUrlH5: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_h5_4.png',
        imgUrlIpad: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_pc_4.png'
    },
    {
        title: () => strings.t('企业核心能力'),
        descList: [() => strings.t('多语种、智能化、专业性、前瞻性。')],


        imgUrlPC: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_pc_5.png',
        imgUrlH5: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_h5_5.png',
        imgUrlIpad: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_pc_5.png'
    },
    {
        title: () => strings.t('企业核心价值观'),
        descList: [() => strings.t('感恩分享、不断创新、成就客户、贡献社会。')],


        imgUrlPC: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_pc_6.png',
        imgUrlH5: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_h5_6.png',
        imgUrlIpad: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_pc_6.png'

    }];