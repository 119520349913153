// import { useEffect } from "react";
import { useSetAtom } from 'jotai';
import { atomMenuTheme, atomLogoTheme } from '@/atom/atomPanelVisible';
import CallUs from '../Home/modules/callUs';
import './index.scss';

const CallUsPage = () => {

    const setAtomDrawerFlag = useSetAtom(atomMenuTheme);
    const setAtomDrawerLogo = useSetAtom(atomLogoTheme);
    setAtomDrawerFlag('black');
    setAtomDrawerLogo('#FF5010');
    // useEffect(() => {
    // }, []);
    return (
        <div className="callUsSection">
            <div className="fp-overflow">
                <CallUs/>
            </div>
        </div>
    );
};

export default CallUsPage;