/*
 * @Author: liuliangliang liuliangliang@sniffgroup.com
 * @Date: 2024-03-05 15:43:38
 * @LastEditors: liuliangliang liuliangliang@sniffgroup.com
 * @LastEditTime: 2024-03-05 15:48:59
 * @FilePath: /ckb-official-website-fed/src/atom/atomPanelVisible.ts
 * @Description: 面板是否可见
 */
import { atom } from 'jotai';

export const atomMenuTheme = atom<string>('white');
export const atomLogoTheme = atom<string>('white');