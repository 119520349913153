/*
 * @Author: yusha
 * @Date: 2024-03-07 21:50:31
 * @LastEditors: shiguang
 * @LastEditTime: 2024-03-12 16:26:25
 * @Description:
 */
import React, { useEffect, useRef } from 'react';
import './index.scss';
import { useAtomValue } from 'jotai';
import useDeviceType, { ENUM_DEVICE_TYPE } from '@/hooks/useDeviceType';
import { ENUM_SYSTEM_LANGUAGE, atomSystemLanguage } from '@/atom/atomSystemLanguage';
// import { useInViewport } from 'ahooks';
const videoMap = {
    [ENUM_DEVICE_TYPE.PC]: {
        [ENUM_SYSTEM_LANGUAGE.CHINESE]: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/aboutUsVideo_PC_CN.mp4',
        [ENUM_SYSTEM_LANGUAGE.ENGLISH]: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/aboutUsVideo_PC_EN.mp4'
    },
    [ENUM_DEVICE_TYPE.MOBILE]: {
        [ENUM_SYSTEM_LANGUAGE.CHINESE]: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/aboutUsVideo_PHONE_CN.mp4',
        [ENUM_SYSTEM_LANGUAGE.ENGLISH]: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/aboutUsVideo_PHONE_EN.mp4'
    },
    [ENUM_DEVICE_TYPE.IPAD]: {
        [ENUM_SYSTEM_LANGUAGE.CHINESE]: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/aboutUsVideo_PAD_CN.mp4',
        [ENUM_SYSTEM_LANGUAGE.ENGLISH]: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/aboutUsVideo_PAD_EN.mp4'
    }
};
const SectionAbout = ({ destination }: any) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const { deviveType2 } = useDeviceType();
    const _atomSystemLanguage = useAtomValue(atomSystemLanguage);
    // const ref = useRef(null);
    // const [inViewport] = useInViewport(videoRef);
    useEffect(() => {
        if (destination === 0) {
            try {
                videoRef?.current?.play();
            } catch (error) {
                console.log(error);
            }
        }
    }, [destination]);
    return (
        <div className="section" style={{ width: '100vw', height: 'calc(var(--vh) * 100)' }}>
            <video playsInline className="video-wrap" autoPlay={true} preload="auto" muted loop ref={videoRef}>
                <source src={videoMap[deviveType2][_atomSystemLanguage]} type="video/mp4" />
            </video>
        </div>
    );


};


export default SectionAbout;