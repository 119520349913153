/*
 * @Author: shiguang
 * @Date: 2023-02-23 15:13:00
 * @LastEditTime: 2024-03-15 11:28:14
 * @LastEditors: shiguang
 * @Description: react-i18next-config
 */

import { createContext } from 'react';
import queryString from 'query-string';
import extractConfig from '@/i18n/locales/extractConfig.json';
import jp from '@/i18n/locales/ja_JP.json';
import kr from '@/i18n/locales/ko_KR.json';
import zh from '@/i18n/locales/zh_CN.json';
import en from '@/i18n/locales/en_GB.json';

const qs = queryString.parse(window.location.search);

// export const lang: string | undefined = (() => {
//     let lang = qs?.lang as string | undefined;
//     lang = lang ? lang.toLocaleLowerCase() : undefined;

//     if (lang === 'ja' || lang === 'jp') {
//         lang = 'ja';
//     }

//     return lang;
// })();

/** 本地语言文件枚举，从query中lang的语言类型 */
export enum Local {
	ZH = 'zh',
	/** 日文 */
	JA = 'ja',
	/** 韩文 */
	KO = 'ko',
	/** 英文 */
	EN = 'en'
}

/** 语言枚举 */
export enum Lang {
	zh_CN = 'zh_CN',
	/** 日文 */
	ja_JP = 'ja_JP',
	/** 韩文 */
	ko_KR = 'ko_KR',
	/** 英文 */
	en_GB = 'en_GB'
}

export const getLocalByLang = (() => {
    const getLocalByLangMap = new Map();
    getLocalByLangMap.set(Lang.zh_CN, Local.ZH);
    getLocalByLangMap.set(Lang.ja_JP, Local.JA);
    getLocalByLangMap.set(Lang.ko_KR, Local.KO);
    getLocalByLangMap.set(Lang.en_GB, Local.EN);
    return getLocalByLangMap;
})();

// url query 获取带过来的语言
const getQuerylang = () => {
    if(window.location.pathname.includes('/zh/')){
        return Lang.zh_CN;
    }
    if(window.location.pathname.includes('/en/')){
        return Lang.en_GB;
    }
    const query = queryString.parse(window.location.search) as { lang: Local; };
    return (
        {
            [Local.ZH]: Lang.zh_CN,
            [Local.JA]: Lang.ja_JP,
            [Local.KO]: Lang.ko_KR,
            [Local.EN]: Lang.en_GB
        }[query.lang]
    );
};

/** 初始化获取当前的语言 */
export const getLang = () => {
    // return Lang.ja_JP;
    // // 1.获取从query来的语言。如果存在就设置到缓存中。并返回
    // if(window.location.pathname.includes('/zh/')){
    //     return Lang.zh_CN;
    // }
    // if(window.location.pathname.includes('/en/')){
    //     return Lang.en_GB;
    // }
    let queryLang = getQuerylang();
    if(!queryLang && localStorage.getItem('web_site_lang')) {
        queryLang = localStorage.getItem('web_site_lang') as Lang;
    }
    const currentSystemLanguage = queryLang ?? Lang.en_GB;
    // debugger
    localStorage.setItem('web_site_lang', currentSystemLanguage);
    return currentSystemLanguage;

    // // 2.如果query没有数据。获取缓存中的语言数据。oldLocalLang === ja_JP
};

const initLang = getLang();
export const LocalContext = createContext(initLang);
const zhMap = {
    联系我们1: '联系我们',
    企业文化_企业文化: '企业文化',
    企业文化_文化: '企业文化',
    最新资讯_资讯: '最新资讯',
};
export const strings = {
    t: (text: string, options: { data?: Record<string, any>; } = {}): string => {

        const initLang = getLang();
        // hack 中文
        if(initLang === Lang.zh_CN && zhMap[text]){
            return zhMap[text];
        }
        const { pages, common } = extractConfig;
        // let [pathname] = window.location.pathname.split('#');
        // pathname = window.location.pathname.replace('/en/', '/').replace('/zh/', '/');
        const pathname = window.location.pathname.replace('/en/', '/').replace('/zh/', '/');


        const urlPathname = pathname === '/' ? '/home' : pathname;
        const item = pages
            .map((item: any) => {
                return {
                    ...item,
                    linkTo: [...item?.linkTo ?? [], item.path]
                };
            })
            .find((item) => {
                return item.urlPathname === urlPathname;
            })!;
        const linkTo = item?.linkTo ?? [];
        const getConfig = (() => {
            const ja_JP = window.g_locales ?
                window.g_locales[initLang] :
                strings[initLang] ?? {};
            const commonTranslates = common.reduce((pre, cur) => {
                return {
                    ...pre,
                    ...ja_JP?.[cur.path] ?? {}
                };
            }, {} as any);

            return linkTo.reduce((pre, cur) => {
                return {
                    ...commonTranslates,
                    ...pre,
                    ...ja_JP?.[cur]
                };
            }, {} as any);
        })();

        let lastText = ['', undefined, null].includes(getConfig[text]) ?
            text :
            (getConfig[text] as string);

        if (options.data) {
            for (const key in options.data) {
                if (Object.prototype.hasOwnProperty.call(options.data, key)) {
                    const value = options.data[key];
                    const templateStr = `{{${key}}}`;
                    lastText = lastText.replace(templateStr, value as string);
                }
            }
        }
        return lastText;
    },
    [Lang.zh_CN]: zh,
    [Lang.ja_JP]: jp,
    [Lang.ko_KR]: kr,
    [Lang.en_GB]: en
};