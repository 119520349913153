/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AddAdditionItemReqDTO */
export interface AddAdditionItemReqDTO {
  /** @format int32 */
  additionConfigId?: number;
  /** @format int32 */
  ingredientStatus?: number;
  /** @format int32 */
  ingredientsCount?: number;
  ingredientsSku?: string;
  /** @format int32 */
  spotCheckFlag?: number;
  spotCheckRatio?: number;
}

/** AddAdditionReqDTO */
export interface AddAdditionReqDTO {
  additionList?: AddAdditionItemReqDTO[];
  /** @format int32 */
  additionScene?: number;
  addressAddition?: ShopAddressAdditionReqDTO;
  categoryCodeList?: number[];
  /** @format int32 */
  noAdditionalFlag?: number;
}

/** AddBannerReqDTO */
export interface AddBannerReqDTO {
  /** @format int64 */
  bannerApplicableTemplateId?: number;
  bannerDesignatedUserId?: string;
  bannerImgUrl?: string;
  bannerJumpLink?: string;
  bannerName?: string;
  /** @format int32 */
  bannerStatus?: number;
  /** @format int32 */
  bannerType?: number;
  /** @format date-time */
  endTime?: string;
  /** @format int64 */
  mallCategoryId?: number;
  /** @format date-time */
  startTime?: string;
  /** 站点code */
  stationCode?: string;
  /** @format int32 */
  systemSource?: number;
}

/** AddClearanceAddressReqDTO */
export interface AddClearanceAddressReqDTO {
  address?: string;
  addressEn?: string;
  area?: string;
  areaEn?: string;
  city?: string;
  cityEn?: string;
  /** 市名称 */
  cityName?: string;
  /** 通关码 */
  clearanceCode?: string;
  /** @format int32 */
  clearanceType?: number;
  companyCode?: string;
  companyName?: string;
  companyNameEn?: string;
  countryCode?: string;
  /** 国家名称 */
  countryName?: string;
  /** @format int32 */
  defaultAddress?: number;
  email?: string;
  /** 国家代码 */
  nationCode?: string;
  /** 国家id */
  nationId?: string;
  phone?: string;
  postalCode?: string;
  /** 省名称 */
  provinceName?: string;
  receiveName?: string;
  receiveNameEn?: string;
}

/** AddCustomerShopReqDTO */
export interface AddCustomerShopReqDTO {
  clientId?: string;
  clientSecret?: string;
  /** @format int64 */
  customerId?: number;
  customerName?: string;
  customerShopLogo?: string;
  customerShopName?: string;
  /** @format int32 */
  customerShopPlatform?: number;
  customerShopUrl?: string;
  /** @format int64 */
  mainCustomerId?: number;
  /** 站点code */
  stationCode?: string;
  wareCode?: string;
}

/** AddDepartmentReqDTO */
export interface AddDepartmentReqDTO {
  directorId?: string;
  id?: string;
  name?: string;
  parentId?: string;
  /** @format int32 */
  sort?: number;
  status?: string;
  treePath?: string;
}

/** AddFrogArticleReqDTO */
export interface AddFrogArticleReqDTO {
  frogArticleAuthor?: string;
  frogArticleContent?: string;
  /** @format int32 */
  frogArticleEnableStatus?: number;
  frogArticleImgUrl?: string;
  frogArticleSubTitle?: string;
  frogArticleTitle?: string;
  /** @format int32 */
  frogArticleType?: number;
  /** @format int64 */
  frogArticleViewCount?: number;
  /**
   * 未登录限制；1.可查看全文；2.部分可查看；3.不可查看
   * @format int32
   */
  noLoginRestriction?: number;
  /**
   * 未入会限制；1.可查看全文；2.部分可查看；3.不可查看
   * @format int32
   */
  noMembershipRestriction?: number;
  stationCode?: string;
}

/** AddManageUserReqDTO */
export interface AddManageUserReqDTO {
  avatar?: string;
  deptId?: string;
  /** @format int32 */
  directoried?: number;
  email?: string;
  /** @format int32 */
  gender?: number;
  mobile?: string;
  nickname?: string;
  password?: string;
  relationDd?: string;
  relationDdDeptId?: string;
  relationDdDeptName?: string;
  relationDdUserId?: string;
  relationDdUserName?: string;
  roleIds?: string[];
  /** @format int32 */
  status?: number;
  username?: string;
  wareCode?: string[];
}

/** AddMenuReqDTO */
export interface AddMenuReqDTO {
  backEndPath?: string;
  btnPerm?: string;
  code?: string;
  icon?: string;
  name?: string;
  parentId?: string;
  redirect?: string;
  routeName?: string;
  routePath?: string;
  /** @format int32 */
  sort?: number;
  /** @format int32 */
  type?: number;
  /** @format int32 */
  visible?: number;
}

/** AddRoleReqDTO */
export interface AddRoleReqDTO {
  deptId?: string;
  name?: string;
  remark?: string;
  /** @format int32 */
  sort?: number;
  /** @format int32 */
  status?: number;
}

/** AddShippingAddressReqDTO */
export interface AddShippingAddressReqDTO {
  address?: string;
  addressEn?: string;
  area?: string;
  areaEn?: string;
  city?: string;
  cityEn?: string;
  /** 市名称 */
  cityName?: string;
  countryCode?: string;
  /** 国家名称 */
  countryName?: string;
  /** @format int32 */
  defaultAddress?: number;
  email?: string;
  /** 国家代码 */
  nationCode?: string;
  /** 国家id */
  nationId?: string;
  phone?: string;
  postalCode?: string;
  /** 省名称 */
  provinceName?: string;
  receiveName?: string;
  receiveNameEn?: string;
}

/** AddShopTaxDeductionAccountDTO */
export interface AddShopTaxDeductionAccountDTO {
  /** 分行代码 */
  branchCode?: string;
  /**
   * 客户店铺ID
   * @format int64
   */
  customerShopId?: number;
  /** 金融机构代码 */
  financialInstitutionCode?: string;
  /** 进口商标准代码 */
  importerCode?: string;
  /**
   * 是否是默认设置 0 否 1 是
   * @format int32
   */
  isDefault?: number;
  /** 扣税账号 */
  taxDeductionAccount?: string;
}

/** AddSubCustomerReqDTO */
export interface AddSubCustomerReqDTO {
  chatWorkId?: string;
  countryCode?: string;
  customerEmail?: string;
  customerMobile?: string;
  customerName?: string;
  /** @format int64 */
  customerShopId?: number;
  /** @format int32 */
  customerStatus?: number;
  /** @format int32 */
  gender?: number;
  loginName?: string;
  password?: string;
  skype?: string;
  verificationCode?: string;
}

/** AddUserRoleReqDTO */
export interface AddUserRoleReqDTO {
  roleId?: string;
  userId?: string;
}

/** AdditionItemRespDTO */
export interface AdditionItemRespDTO {
  /** @format int32 */
  additionCode?: number;
  /** @format int32 */
  additionConfigId?: number;
  /** @format int32 */
  additionId?: number;
  additionName?: string;
  additionNameJp?: string;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  createTime?: string;
  /** @format int64 */
  customerShopId?: number;
  imageUrl?: string;
  /** @format int32 */
  ingredientStatus?: number;
  /** @format int32 */
  ingredientsCount?: number;
  ingredientsSku?: string;
  /** @format int64 */
  shopAdditionId?: number;
  /** @format int64 */
  shopAdditionItemId?: number;
  /** @format int32 */
  spotCheckFlag?: number;
  spotCheckRatio?: number;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  updateTime?: string;
}

/** AdditionTemplateItemRespDTO */
export interface AdditionTemplateItemRespDTO {
  /** @format int32 */
  additionConfigId?: number;
  /** @format int32 */
  additionId?: number;
  additionName?: string;
  additionNameJp?: string;
  /** @format int32 */
  additionTemplateId?: number;
  /** @format int64 */
  additionTemplateItemId?: number;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  createTime?: string;
  imageUrl?: string;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  updateTime?: string;
}

/** AdditionTemplateRespDTO */
export interface AdditionTemplateRespDTO {
  /** @format int32 */
  additionTemplateId?: number;
  additionTemplateImg?: string;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  createTime?: string;
  items?: AdditionTemplateItemRespDTO[];
  /** @example "yyyy-MM-dd HH:mm:ss" */
  updateTime?: string;
}

/** AppShopRespDTO */
export interface AppShopRespDTO {
  /** @format int64 */
  abnormalOrderCount?: number;
  /** @format int64 */
  apiOrderCount?: number;
  availableAmount?: number;
  /** @format int64 */
  canDeliveryOrderCount?: number;
  /** @format int32 */
  canceledCount?: number;
  /** @format int64 */
  completeOrderCount?: number;
  /** @format int64 */
  customerId?: number;
  /** @format int64 */
  customerShopId?: number;
  customerShopName?: string;
  /** @format int32 */
  customerShopPlatform?: number;
  /** @format int64 */
  dealOrderCount?: number;
  /** @format int32 */
  deletedFlag?: number;
  /** @format int32 */
  noBindAmazonSkuCount?: number;
  /** @format int32 */
  noConfirmReplenishPlanCount?: number;
  orderAmount?: number;
  /** @format int32 */
  orderCount?: number;
  /** @format int64 */
  partDeliveryOrderCount?: number;
  /** @format int32 */
  pendingInventoryCount?: number;
  /** @format int32 */
  systemSource?: number;
  /** @format int32 */
  yesterdayOrderQuantity?: number;
  /** @format int32 */
  yesterdayShipmentsQuantity?: number;
}

/** BaseQueryPage */
export interface BaseQueryPage {
  /** @format int32 */
  pageNum?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int32 */
  startIndex?: number;
}

/** BaseResponse */
export interface BaseResponse {
  code?: string;
  msg?: string;
  success?: boolean;
}

/** BatchModifyPurchaseReqDTO */
export interface BatchModifyPurchaseReqDTO {
  /** @format int32 */
  modifyType?: number;
  records?: ModifyPurchaseRecordDTO[];
  userId?: string;
  userName?: string;
}

/** BindThirdReqDTO */
export interface BindThirdReqDTO {
  /**
   * 0-直采 1-代采
   * @format int32
   */
  buType?: number;
  /** 渠道 */
  channel?: string;
  /** chartWorkId */
  chatWorkId?: string;
  /** 区号 */
  countryCode?: string;
  /** 国家code */
  countryPlaceCode?: string;
  /** 邮箱地址 */
  customerEmail?: string;
  /**
   * 用户id
   * @format int64
   */
  customerId?: number;
  /** 客户手机号 */
  customerMobile?: string;
  /** 联系人姓名 */
  customerName?: string;
  /**
   * 客户状态：1-正常；2-停用
   * @format int32
   * @example 1
   */
  customerStatus?: number;
  /**
   * 性别：1-男；2-女；3-未知
   * @format int32
   * @example 1
   */
  gender?: number;
  /** 三方邀请码 */
  invitationCode?: string;
  /**
   * 邀请平台:0-内部,1-aucfan
   * @format int32
   */
  invitationPlatform?: number;
  /** 语言代码 */
  langcode?: string;
  /** 登录名 */
  loginName?: string;
  /** 登录密码 */
  password?: string;
  /**
   * 平台类型:1网页端,2APP
   * @format int32
   */
  platformType?: number;
  /** 三方推荐邮箱 */
  recommendationEmail?: string;
  /** skype */
  skype?: string;
  /** spm */
  spm?: string;
  /** 站点code */
  stationCode?: string;
  /**
   * 隐私协议id
   * @format int64
   */
  termId?: number;
  /** 三方平台用户账号,可以理解为唯一标识 */
  thirdCustomerId?: string;
  /** 三方平台用户名称 */
  thirdCustomerName?: string;
  /** 三方平台用户邮箱 */
  thirdEmail?: string;
  /** 三方平台标识 */
  thirdPlatform?: string;
  /** 时区 */
  utcTimeZone?: string;
  /** 验证码 */
  verificationCode?: string;
}

/** BizResponse */
export interface BizResponse {
  code?: string;
  data?: object;
  msg?: string;
  success?: boolean;
}

/** CategoryAdditionRespDTO */
export interface CategoryAdditionRespDTO {
  /** @format int64 */
  categoryCateId?: number;
  categoryCateName?: string;
  categoryCateNameEn?: string;
  categoryCateNameJp?: string;
  categoryCateNameKr?: string;
  /** @format int32 */
  categoryLevel?: number;
  /** @format int64 */
  categoryParentId?: number;
  /** @format int32 */
  categoryStaus?: number;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  createTime?: string;
  /** @format int64 */
  customerShopId?: number;
  /** @format int64 */
  shopAdditionConfigCategoryId?: number;
  /** @format int64 */
  shopAdditionId?: number;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  updateTime?: string;
}

/** CategoryCodeRespDTO */
export interface CategoryCodeRespDTO {
  levelOneCategoryCode?: string;
  levelTwoCategoryCodes?: string[];
}

/** ChangeEmailReqDTO */
export interface ChangeEmailReqDTO {
  /** @format int64 */
  customerId?: number;
  newCustomerEmail?: string;
  password?: string;
  verificationCode?: string;
}

/** ChangeMobileReqDTO */
export interface ChangeMobileReqDTO {
  /** 区号 */
  countryCode?: string;
  /** 新手机号 */
  newMobile?: string;
  /** 密码 */
  password?: string;
  /** 验证码 */
  verificationCode?: string;
}

/** CheckEmailReqDTO */
export interface CheckEmailReqDTO {
  /**
   * 业务类型 0直采 1代采
   * @format int32
   */
  buType?: number;
  countryPlaceCode?: string;
  customerEmail?: string;
  langcode?: string;
}

/** Customer */
export interface Customer {
  /** @format int32 */
  accountType?: number;
  /**
   * 业务类型 0 直采 1 代采
   * @format int32
   */
  buType?: number;
  chatWorkId?: string;
  countryCode?: string;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  createTime?: string;
  customerEmail?: string;
  /** @format int64 */
  customerId?: number;
  customerMobile?: string;
  customerName?: string;
  /** @format int32 */
  customerStatus?: number;
  /** @format int32 */
  defaultLogin?: number;
  /** @format int32 */
  defectiveProductRiskFlagMain?: number;
  defectiveProductRiskFlagMainRemark?: string;
  /** @format int32 */
  defectiveProductRiskFlagSub?: number;
  defectiveProductRiskFlagSubRemark?: string;
  /** @format int32 */
  gender?: number;
  googleUrl?: string;
  headerUrl?: string;
  /** @format int32 */
  internalFlag?: number;
  langcode?: string;
  /** @format date-time */
  lastLoginTime?: string;
  loginName?: string;
  /** @format int64 */
  mainCustomerId?: number;
  /** @format int64 */
  managerId?: number;
  /** @format int32 */
  memberChangeFlag?: number;
  nationCode?: string;
  /** @format int64 */
  oldCustomerStatus?: number;
  outsideLink?: string;
  password?: string;
  /** @format int32 */
  passwordChangeFlag?: number;
  /**
   * 建议补全手机号是否已读:0-没有,1-已读
   * @format int32
   */
  phoneCompletedFlag?: number;
  /** @format int32 */
  platformType?: number;
  salt?: string;
  skype?: string;
  stationCode?: string;
  /** @format int64 */
  superCustomerId?: number;
  /** @format int64 */
  supervisionManagerId?: number;
  /** @format int32 */
  systemSource?: number;
  timeZone?: string;
  unificationCustomerFullName?: string;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  updateTime?: string;
  /** @format int32 */
  zeroChangeFlag?: number;
}

/** CustomerCanTransformRespDTO */
export interface CustomerCanTransformRespDTO {
  /**
   * 账号类型：1-主账号；2-子账号；
   * @format int32
   */
  accountType?: number;
  /**
   * 用户id
   * @format int64
   */
  customerId?: number;
  /** 用户名 */
  customerName?: string;
  /**
   * 店铺id
   * @format int64
   */
  customerShopId?: number;
  /** 店铺名称 */
  customerShopName?: string;
  /**
   * 客户店铺平台: 0-其他; 1-Base; 2-Shopify; 3-Stores; 4-Amazon
   * @format int32
   */
  customerShopPlatform?: number;
  /** 登录名 */
  loginName?: string;
  /**
   * 来源，1- d2c ,2- b2b
   * @format int32
   */
  systemSource?: number;
}

/** CustomerConfigRespDTO */
export interface CustomerConfigRespDTO {
  configCode?: string;
  configName?: string;
  configValue?: string;
}

/** CustomerCount */
export interface CustomerCount {
  platformType?: string;
}

/** CustomerDetailManagerRespDTO */
export interface CustomerDetailManagerRespDTO {
  /** @format int64 */
  account?: number;
  /** @format int32 */
  accountType?: number;
  automaticRenewalStatusInfo?: string;
  automaticToggleStaus?: boolean;
  availableAmount?: number;
  /**
   * 业务类型 0直采 1代采
   * @format int32
   */
  buType?: number;
  chatWorkId?: string;
  /** 国家 */
  country?: string;
  countryCode?: string;
  /** @format date-time */
  createTime?: string;
  customerDetailRespDTOS?: CustomerDetailRespDTO[];
  customerEmail?: string;
  /** @format int64 */
  customerId?: number;
  customerMobile?: string;
  customerName?: string;
  /** @format int64 */
  customerShopId?: number;
  customerShopList?: CustomerShopRespDTO[];
  /** @format int32 */
  customerStatus?: number;
  /** @format int32 */
  defaultLogin?: number;
  /** @format int32 */
  deleteStatus?: number;
  /** @format int32 */
  gender?: number;
  headerUrl?: string;
  /** @format int32 */
  internalFlag?: number;
  /** 语言代码:ja-日语,en-英语,th-泰语,ko-韩语 */
  langcode?: string;
  /** @format date-time */
  lastLoginTime?: string;
  loginName?: string;
  /** @format int64 */
  mainCustomerId?: number;
  /** @format int32 */
  manageStatus?: number;
  /** @format int64 */
  managerId?: number;
  managerName?: string;
  membership?: CustomerMembershipResDTO;
  membershipPreChargeOrder?: string;
  nationCode?: string;
  outsideLink?: string;
  /**
   * 业务类型 0直采 1代采
   * @format int32
   */
  platformType?: number;
  shopAddressAdditionRespDTO?: ShopAddressAdditionRespDTO;
  shopPurchaseRespDTO?: ShopPurchaseRespDTO;
  skype?: string;
  stationCode?: string;
  /** @format int64 */
  superCustomerId?: number;
  /** @format int64 */
  supervisionManagerId?: number;
  supervisionManagerName?: string;
  /** @format int32 */
  systemSource?: number;
  thirdBind?: string[];
  timeZone?: string;
  unificationCustomerFullName?: string;
  /** @format date-time */
  updateTime?: string;
  wareCode?: string;
  wareName?: string;
}

/** CustomerDetailRespDTO */
export interface CustomerDetailRespDTO {
  /** @format int32 */
  accountType?: number;
  availableAmount?: number;
  chatWorkId?: string;
  countryCode?: string;
  /** @format date-time */
  createTime?: string;
  customerEmail?: string;
  customerEmailChecked?: boolean;
  /** @format int64 */
  customerId?: number;
  customerMobile?: string;
  customerName?: string;
  /** @format int64 */
  customerShopId?: number;
  customerShopList?: CustomerShopRespDTO[];
  /** @format int32 */
  customerStatus?: number;
  /** @format int32 */
  defaultLogin?: number;
  /** @format int32 */
  gender?: number;
  googleUrl?: string;
  headerUrl?: string;
  initialPassword?: boolean;
  /** @format int32 */
  internalFlag?: number;
  langcode?: string;
  /** @format date-time */
  lastLoginTime?: string;
  loginName?: string;
  /** @format int64 */
  mainCustomerId?: number;
  /** @format int32 */
  manageStatus?: number;
  /** @format int64 */
  managerId?: number;
  managerName?: string;
  membership?: CustomerMembershipResDTO;
  nationCode?: string;
  outsideLink?: string;
  shopName?: string;
  showMembershipInfo?: boolean;
  skype?: string;
  /** @format int32 */
  splitRemindFlag?: number;
  stationCode?: string;
  /** @format int64 */
  superCustomerId?: number;
  /** @format int64 */
  supervisionManagerId?: number;
  supervisionManagerName?: string;
  /** @format int32 */
  systemSource?: number;
  unificationCustomerFullName?: string;
  /** @format date-time */
  updateTime?: string;
  utcTimeZone?: string;
}

/** CustomerDistributeQueryRespDTO */
export interface CustomerDistributeQueryRespDTO {
  /** @format int64 */
  customerId?: number;
  merchandiserUserId?: string;
  merchandiserUserName?: string;
  oemPurchaseUserId?: string;
  oemPurchaseUserName?: string;
  purchaseUserId?: string;
  purchaseUserName?: string;
}

/** CustomerGuide */
export interface CustomerGuide {
  /** @format date-time */
  createTime?: string;
  /** @format int64 */
  customerGuideId?: number;
  /** @format int64 */
  customerId?: number;
  guideProperties?: string;
  /** @format date-time */
  updateTime?: string;
}

/** CustomerItemRespDTO */
export interface CustomerItemRespDTO {
  /** @format int32 */
  accountType?: number;
  chatWorkId?: string;
  countryCode?: string;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  createTime?: string;
  customerEmail?: string;
  /** @format int64 */
  customerId?: number;
  customerMobile?: string;
  customerName?: string;
  /** @format int32 */
  customerStatus?: number;
  /** @format int32 */
  gender?: number;
  headerUrl?: string;
  /** @format int32 */
  internalFlag?: number;
  langcode?: string;
  /** @format date-time */
  lastLoginTime?: string;
  loginName?: string;
  /** @format int64 */
  mainCustomerId?: number;
  membership?: CustomerMembershipResDTO;
  outsideLink?: string;
  password?: string;
  salt?: string;
  skype?: string;
  /** @format int64 */
  superCustomerId?: number;
  /** @format int32 */
  systemSource?: number;
  unificationCustomerFullName?: string;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  updateTime?: string;
}

/** CustomerJpushReqDTO */
export interface CustomerJpushReqDTO {
  customerId?: string;
  extrasParam?: string;
  msgContent?: string;
  msgTitle?: string;
  notificationTitle?: string;
}

/** CustomerLoginRespDTO */
export interface CustomerLoginRespDTO {
  customerRespDTO?: CustomerRespDTO;
  customerShopList?: CustomerShopRespDTO[];
  memberShipResp?: CustomerMemberShipRespDTO;
  token?: string;
  uuid?: string;
}

/** CustomerMemberShipRespDTO */
export interface CustomerMemberShipRespDTO {
  /** @format int32 */
  basicFlag?: number;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  createTime?: string;
  /** @format int64 */
  customerMembershipId?: number;
  /** @format int32 */
  customerMembershipStatus?: number;
  customerName?: string;
  discountActualPrice?: number;
  /** @format date-time */
  expiresDate?: string;
  /**
   * 推广规模id
   * @format int32
   */
  membershipConfigId?: number;
  /**
   * 0-默认仅展示普通会员,1-仅展示特殊会员,2-展示全部会员
   * @format int32
   */
  membershipShowStatus?: number;
  /** @format int64 */
  membershipTemplateId?: number;
  membershipTemplateName?: string;
  /** @format int64 */
  membershipTemplatePriceId?: number;
  membershipTemplatePriceName?: string;
  sellingPrice?: number;
  /**
   * 会员类型:0-普通,1-特殊
   * @format int32
   */
  specialType?: number;
  /** @format int64 */
  superCustomerId?: number;
  /**
   * 会员等级，从低到高，0最低
   * @format int32
   */
  templateLevel?: number;
  /**
   * 试用标志:0-不是,1是
   * @format int32
   */
  trialFlag?: number;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  updateTime?: string;
}

/** CustomerMembershipReqDTO */
export interface CustomerMembershipReqDTO {
  automaticRenewalSuperCustomerIdList?: number[];
  /** @format date-time */
  createTime?: string;
  /** @format int64 */
  customerMembershipId?: number;
  /** @format int32 */
  customerMembershipStatus?: number;
  customerName?: string;
  expired?: boolean;
  /** @format date-time */
  expiresDate?: string;
  /** @format int64 */
  membershipTemplateId?: number;
  membershipTemplateName?: string;
  /** @format int64 */
  membershipTemplatePriceId?: number;
  membershipTemplatePriceName?: string;
  /**
   * 会员类型:0-普通,1-特殊
   * @format int32
   */
  specialType?: number;
  /** @format int64 */
  superCustomerId?: number;
  /**
   * 试用标志:0-不是,1是
   * @format int32
   */
  trialFlag?: number;
  /** @format date-time */
  updateTime?: string;
}

/** CustomerMembershipResDTO */
export interface CustomerMembershipResDTO {
  /** @format int32 */
  basicFlag?: number;
  /** @format date-time */
  createTime?: string;
  /** @format int64 */
  customerMembershipId?: number;
  /** @format int32 */
  customerMembershipStatus?: number;
  customerName?: string;
  discountActualPrice?: number;
  /** @format date-time */
  expiresDate?: string;
  /** @format int64 */
  membershipTemplateId?: number;
  membershipTemplateName?: string;
  /** @format int64 */
  membershipTemplatePriceId?: number;
  membershipTemplatePriceName?: string;
  sellingPrice?: number;
  stationCode?: string;
  /** @format int64 */
  superCustomerId?: number;
  /** @format date-time */
  updateTime?: string;
}

/** CustomerOverviewRespDTO */
export interface CustomerOverviewRespDTO {
  /** @format int32 */
  accountType?: number;
  availableAmount?: number;
  customerEmail?: string;
  /** @format int64 */
  customerId?: number;
  customerMobile?: string;
  customerName?: string;
  customerShop?: CustomerShopRespDTO;
  customerShopList?: CustomerShopRespDTO[];
  /**
   * 店铺数量
   * @format int32
   */
  customerShopQuantity?: number;
  /** @format int32 */
  customerStatus?: number;
  /** @format int32 */
  daysTogether?: number;
  /** @format int32 */
  gender?: number;
  headerUrl?: string;
  loginName?: string;
  /** @format int64 */
  mainCustomerId?: number;
  membership?: CustomerMembershipResDTO;
  /** @format int64 */
  superCustomerId?: number;
  /** @format int32 */
  systemSource?: number;
}

/** CustomerPageDTO */
export interface CustomerPageDTO {
  /** @format int32 */
  accountType?: number;
  chatWorkId?: string;
  countryCode?: string;
  /** @format date-time */
  createTime?: string;
  customerEmail?: string;
  /** @format int64 */
  customerId?: number;
  customerMobile?: string;
  customerName?: string;
  /** @format int32 */
  customerStatus?: number;
  /** @format int32 */
  defaultLogin?: number;
  /** @format int32 */
  gender?: number;
  headerUrl?: string;
  /** @format int32 */
  internalFlag?: number;
  /** @format date-time */
  lastLoginTime?: string;
  loginName?: string;
  /** @format int64 */
  mainCustomerId?: number;
  /** @format int64 */
  managerId?: number;
  /** @format int64 */
  oldCustomerStatus?: number;
  outsideLink?: string;
  password?: string;
  salt?: string;
  skype?: string;
  /** @format int64 */
  superCustomerId?: number;
  /** @format int64 */
  supervisionManagerId?: number;
  /** @format int32 */
  systemSource?: number;
  unificationCustomerFullName?: string;
  /** @format date-time */
  updateTime?: string;
}

/** CustomerRespDTO */
export interface CustomerRespDTO {
  /** @format int32 */
  accountType?: number;
  /** @format date-time */
  createTime?: string;
  customerEmail?: string;
  /** @format int64 */
  customerId?: number;
  customerMobile?: string;
  customerName?: string;
  /** @format int32 */
  gender?: number;
  /** @format date-time */
  lastLoginTime?: string;
  loginName?: string;
  /** @format int64 */
  mainCustomerId?: number;
  /**
   * 推广规模id
   * @format int32
   */
  membershipConfigId?: number;
  /**
   * 0-默认仅展示普通会员,1-仅展示特殊会员,2-展示全部会员
   * @format int32
   */
  membershipShowStatus?: number;
  nationCode?: string;
  /** @format int32 */
  platformType?: number;
  /** @format int64 */
  shopId?: number;
  stationCode?: string;
  /** @format int64 */
  superCustomerId?: number;
  /** @format int32 */
  systemSource?: number;
  unificationCustomerFullName?: string;
  /** @format date-time */
  updateTime?: string;
}

/** CustomerShopAddressRespDTO */
export interface CustomerShopAddressRespDTO {
  /** @format int64 */
  customerShopId?: number;
  /** @format int64 */
  logisticsId?: number;
  logisticsName?: string;
}

/** CustomerShopBySuperRespDTO */
export interface CustomerShopBySuperRespDTO {
  customerShopList?: CustomerShopRespDTO[];
  /** @format int64 */
  superCustomerId?: number;
}

/** CustomerShopOptDTO */
export interface CustomerShopOptDTO {
  /**
   * 是否自动下单
   * @format int32
   */
  customerShopAutoOrderFlag?: number;
  /** @format int64 */
  customerShopId?: number;
  /**
   * 是否使用在途库存
   * @format int32
   */
  customerShopUseOnTheWayStockFlag?: number;
}

/** CustomerShopPageDTO */
export interface CustomerShopPageDTO {
  /** @format int32 */
  activeSubAccountFlag?: number;
  clientId?: string;
  clientSecret?: string;
  /** @format date-time */
  createTime?: string;
  /** @format int64 */
  customerId?: number;
  customerName?: string;
  /** @format int32 */
  customerShopAuthStatus?: number;
  /** @format int32 */
  customerShopAutoOrderFlag?: number;
  /** @format int64 */
  customerShopId?: number;
  customerShopLogo?: string;
  customerShopName?: string;
  customerShopPermission?: string;
  /** @format int32 */
  customerShopPlatform?: number;
  customerShopUrl?: string;
  /** @format int64 */
  mainCustomerId?: number;
  mainCustomerName?: string;
  /** @format date-time */
  updateTime?: string;
  /** @format int32 */
  virtualFlag?: number;
  wareCode?: string;
  wareName?: string;
}

/** CustomerShopQueryDTO */
export interface CustomerShopQueryDTO {
  customerIds?: number[];
  /** @format int32 */
  pageNum?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int32 */
  startIndex?: number;
}

/** CustomerShopRespDTO */
export interface CustomerShopRespDTO {
  /** @format int64 */
  abnormalOrderCount?: number;
  /** @format int32 */
  activeSubAccountFlag?: number;
  /** @format int64 */
  apiOrderCount?: number;
  /** @format int32 */
  apiOrderStatusFailCount?: number;
  /** @format int32 */
  authExpireStatus?: number;
  /** @format int64 */
  authStatus?: number;
  availableAmount?: number;
  /** @format int32 */
  badAmount?: number;
  /** @format int64 */
  canDeliveryOrderCount?: number;
  /** @format int32 */
  canceledCount?: number;
  clientId?: string;
  clientSecret?: string;
  /** @format int64 */
  completeOrderCount?: number;
  /** @format date-time */
  createTime?: string;
  /** @format int64 */
  customerId?: number;
  customerName?: string;
  /** @format int32 */
  customerShopAuthStatus?: number;
  /** @format int32 */
  customerShopAutoOrderFlag?: number;
  /** @format int64 */
  customerShopId?: number;
  customerShopName?: string;
  customerShopPermission?: string;
  /** @format int32 */
  customerShopPlatform?: number;
  customerShopUrl?: string;
  /** @format int64 */
  dealOrderCount?: number;
  /** @format int32 */
  deletedFlag?: number;
  /** @format int32 */
  flAmount?: number;
  /** @format int32 */
  isExistOem?: number;
  loginName?: string;
  /** @format int64 */
  mainCustomerId?: number;
  managerIds?: string[];
  /** @format int32 */
  noBindAmazonSkuCount?: number;
  /** @format int32 */
  noBindApiSkuCount?: number;
  /** @format int64 */
  noCompleteOrderCount?: number;
  /** @format int32 */
  noConfirmReplenishPlanCount?: number;
  /** @format int64 */
  nonDoneRechargeCount?: number;
  orderAmount?: number;
  /** @format int32 */
  orderCount?: number;
  /** @format int64 */
  partDeliveryOrderCount?: number;
  /** @format int32 */
  pendingInventoryCount?: number;
  /** @format int32 */
  productAmount?: number;
  /** @format int32 */
  quotedOem?: number;
  /** @format int32 */
  rePurchaseOem?: number;
  /** @format int32 */
  searchSuccessOem?: number;
  /** @format int64 */
  shopPlatform?: number;
  stationCode?: string;
  /** @format int32 */
  systemSource?: number;
  /** @format int32 */
  toBeQuotedOem?: number;
  unificationCustomerFullName?: string;
  /** @format int32 */
  virtualFlag?: number;
  /** @format int32 */
  waitConfirm?: number;
  /** @format int64 */
  waitPayOrderCount?: number;
  /** @format int32 */
  waitedOem?: number;
  wareCode?: string;
  wareName?: string;
  wareNameLangList?: WareNameLangDTO[];
  /** @format int32 */
  yesterdayOrderQuantity?: number;
  /** @format int32 */
  yesterdayShipmentsQuantity?: number;
}

/** CustomerShopWmsRespDTO */
export interface CustomerShopWmsRespDTO {
  /** @format int64 */
  customerShopId?: number;
  customerShopName?: string;
}

/** CustomerSplitRemindFlagDTO */
export interface CustomerSplitRemindFlagDTO {
  /** @format int64 */
  customerId?: number;
}

/** CustomerStationDTO */
export interface CustomerStationDTO {
  /**
   * id
   * @format int64
   */
  id?: number;
  /**
   * 是否删除 0:未删除 1:已删除
   * @format int32
   */
  isDelete?: number;
  /** 语言代码 */
  languageCode?: string;
  /** 国家代码 */
  nationCode?: string;
  /** 国家名称 */
  nationName?: string;
  /** 站点代码 */
  stationCode?: string;
  /** 站点名称 */
  stationName?: string;
}

/** CustomerTagDTO */
export interface CustomerTagDTO {
  /** @format int64 */
  customerId?: number;
  /** @format int64 */
  id?: number;
  /** @format int64 */
  mainCustomerId?: number;
  /** @format int32 */
  tag?: number;
}

/** CustomerTagSearchReq */
export interface CustomerTagSearchReq {
  customerIds?: number[];
  /** @format int32 */
  customerTag?: number;
}

/** CustomerThirdBindRespDTO */
export interface CustomerThirdBindRespDTO {
  /**
   * 系统用户id
   * @format int64
   */
  customerId?: number;
  /** 三方用户id唯一 */
  thirdCustomerId?: string;
  /** 三方用户名 */
  thirdCustomerName?: string;
  /** 三方邮箱 */
  thirdEmail?: string;
  /** 三方手机号 */
  thirdPhoneNumber?: string;
  /** 三方平台 */
  thirdPlatform?: string;
}

/** DataDictRespDTO */
export interface DataDictRespDTO {
  /** @format date-time */
  createTime?: string;
  /** @format int32 */
  dataDictId?: number;
  dictKey?: string;
  dictName?: string;
  dictValue?: string;
  /** @format int32 */
  parentId?: number;
  remark?: string;
  /** @format int32 */
  sort?: number;
  /** @format int32 */
  status?: number;
  /** @format int32 */
  type?: number;
  /** @format date-time */
  updateTime?: string;
}

/** DeclarationWhitelistPageDTO */
export interface DeclarationWhitelistPageDTO {
  /** 主客户ID */
  mainCustomerIds?: number[];
  /** 用户名 */
  mainCustomerName?: string;
  /** @format int32 */
  pageNum?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int32 */
  startIndex?: number;
  /** 统一客户全名 */
  unificationCustomerFullName?: string;
}

/** DeclarationWhitelistRespDTO */
export interface DeclarationWhitelistRespDTO {
  /** @format date-time */
  createTime?: string;
  /** @format int64 */
  id?: number;
  /** @format int64 */
  mainCustomerId?: number;
  mainCustomerName?: string;
  unificationCustomerFullName?: string;
}

/** DefaultLoginReqDTO */
export interface DefaultLoginReqDTO {
  /** @format int32 */
  systemSource?: number;
  uuid?: string;
}

/** DelUserRoleReqDTO */
export interface DelUserRoleReqDTO {
  roleId?: string[];
  userId?: string[];
}

/** DepartmentDetailRespDTO */
export interface DepartmentDetailRespDTO {
  code?: string;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  createTime?: string;
  director?: string;
  directorId?: string;
  id?: string;
  mobile?: string;
  name?: string;
  parentId?: string;
  /** @format int32 */
  sort?: number;
  /** @format int32 */
  status?: number;
  treePath?: string;
  /** @format int32 */
  type?: number;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  updateTime?: string;
}

/** DepartmentTreeRespDTO */
export interface DepartmentTreeRespDTO {
  children?: DepartmentTreeRespDTO[];
  icon?: string;
  id?: string;
  label?: string;
  path?: string;
  roles?: ManageRoleRespDTO[];
  /** @format int32 */
  size?: number;
  /** @format int32 */
  type?: number;
  users?: ManageUserRespDTO[];
  /** @format int32 */
  visible?: number;
}

/** DirectorSetReqDTO */
export interface DirectorSetReqDTO {
  /** @format int32 */
  status?: number;
  userId?: string;
}

/** EmailLoginReqDTO */
export interface EmailLoginReqDTO {
  /** @format int32 */
  buType?: number;
  customerEmail?: string;
  /** @format int32 */
  platformType?: number;
  /** 三方平台用户账号,可以理解为唯一标识 */
  thirdCustomerId?: string;
  /** 三方平台用户名称 */
  thirdCustomerName?: string;
  /** 三方平台用户邮箱 */
  thirdEmail?: string;
  /** 三方平台标识 */
  thirdPlatform?: string;
  verificationCode?: string;
}

/** FeedbackInfoReqDTO */
export interface FeedbackInfoReqDTO {
  companyName?: string;
  email?: string;
  message?: string;
  phone?: string;
  userName?: string;
}

/** FeedbackInfoRspDTO */
export interface FeedbackInfoRspDTO {
  companyName?: string;
  /** @format date-time */
  createTime?: string;
  email?: string;
  /** @format int64 */
  id?: number;
  message?: string;
  phone?: string;
  /** @format date-time */
  updateTime?: string;
  userName?: string;
}

/** FrogArticleArchiveSortRespDTO */
export interface FrogArticleArchiveSortRespDTO {
  /** @format int32 */
  articleNum?: number;
  /** @format int32 */
  frogArticleMonth?: number;
  /** @format int32 */
  frogArticleYear?: number;
}

/** FrogArticleDetailRespDTO */
export interface FrogArticleDetailRespDTO {
  /** @format date-time */
  createTime?: string;
  frogArticleContent?: string;
  /** @format int32 */
  frogArticleEnableStatus?: number;
  /** @format int64 */
  frogArticleId?: number;
  frogArticleImgUrl?: string;
  frogArticleSubTitle?: string;
  frogArticleTitle?: string;
  /** @format int32 */
  frogArticleType?: number;
  /** @format int64 */
  frogArticleViewCount?: number;
  /**
   * 未登录限制；1.可查看全文；2.部分可查看；3.不可查看
   * @format int32
   */
  noLoginRestriction?: number;
  /**
   * 未入会限制；1.可查看全文；2.部分可查看；3.不可查看
   * @format int32
   */
  noMembershipRestriction?: number;
  stationCode?: string;
  /** @format int64 */
  viewNum?: number;
}

/** FrogArticlePrepAndNextRespDTO */
export interface FrogArticlePrepAndNextRespDTO {
  /** @format int64 */
  nextArticleId?: number;
  nextArticleTitle?: string;
  /** @format int64 */
  prepArticleId?: number;
  prepArticleTitle?: string;
}

/** FrogArticleRespDTO */
export interface FrogArticleRespDTO {
  /** @format date-time */
  createTime?: string;
  /** @format int32 */
  frogArticleEnableStatus?: number;
  /** @format int64 */
  frogArticleId?: number;
  frogArticleImgUrl?: string;
  frogArticleSubTitle?: string;
  frogArticleTitle?: string;
  /** @format int32 */
  frogArticleType?: number;
  /** @format int64 */
  frogArticleViewCount?: number;
  /**
   * 未登录限制；1.可查看全文；2.部分可查看；3.不可查看
   * @format int32
   */
  noLoginRestriction?: number;
  /**
   * 未入会限制；1.可查看全文；2.部分可查看；3.不可查看
   * @format int32
   */
  noMembershipRestriction?: number;
  stationCode?: string;
  /** @format int64 */
  viewNum?: number;
}

/**
 * GetActivitiesRespDTO
 * 获取直播间列表
 */
export interface GetActivitiesRespDTO {
  /** 回放列表 */
  backActivities?: VideoActivityDTO[];
  /** 直播中列表 */
  livingActivities?: VideoActivityDTO[];
  /** 预约列表 */
  reserveActivities?: VideoActivityDTO[];
}

/** GetAllFrogArticlePageDTO */
export interface GetAllFrogArticlePageDTO {
  /** @format int32 */
  pageNum?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int32 */
  startIndex?: number;
  stationCode?: string;
  typeList?: string[];
}

/** GetCustomerByMainCustomerReqDTO */
export interface GetCustomerByMainCustomerReqDTO {
  customerName?: string;
  /** @format int64 */
  mainCustomerId?: number;
  /** @format int32 */
  systemSource?: number;
}

/**
 * GetCustomerGoogleListReqDTO
 * 查询用户谷歌表列表请求DTO
 */
export interface GetCustomerGoogleListReqDTO {
  /**
   * 客户id
   * @format int32
   */
  customerId?: number;
  /** 谷歌表id */
  googleId?: string;
  /** 负责人名称 */
  managerName?: string;
  /** @format int32 */
  pageNum?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int32 */
  startIndex?: number;
  /** 统一客户全名 */
  unificationCustomerFullName?: string;
  /**
   * 用户id
   * @format int64
   */
  userId?: number;
}

/** GetCustomerListReqDTO */
export interface GetCustomerListReqDTO {
  /** @format int32 */
  accountType?: number;
  /** @format int32 */
  assign?: number;
  /** @format int64 */
  customerId?: number;
  customerIds?: number[];
  customerName?: string;
  email?: string;
  loginName?: string;
  multiMatch?: string;
  /** @format int32 */
  pageNum?: number;
  /** @format int32 */
  pageSize?: number;
  phone?: string;
  /** @format int32 */
  startIndex?: number;
  stationCode?: string;
  superCustomerIds?: number[];
  /** @format int32 */
  systemSource?: number;
  unificationCustomerFullName?: string;
  wareCode?: string;
}

/** GetCustomerShopDTO */
export interface GetCustomerShopDTO {
  /** @format int64 */
  accountType?: number;
  /** @format int64 */
  customerId?: number;
  /** @format int64 */
  customerShopId?: number;
  /** @format int64 */
  mainCustomerId?: number;
  /** @format int32 */
  systemSource?: number;
  /**
   * 是否查看前10个店铺: 1是,0否-查看剩余店铺
   * @format int32
   */
  viewFirstTenStores?: number;
}

/** GetCustomerShopListByShopIdListDTO */
export interface GetCustomerShopListByShopIdListDTO {
  customerShopIdList?: number[];
}

/** GetCustomerShopListReqDTO */
export interface GetCustomerShopListReqDTO {
  /** @format int64 */
  customerId?: number;
  customerShopIdList?: number[];
  /** @format int32 */
  customerShopPlatform?: number;
  /** @format int64 */
  mainCustomerId?: number;
  /** @format int32 */
  pageNum?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int32 */
  shopAuthStatus?: number;
  /** @format int32 */
  startIndex?: number;
}

/** GetDepartmentTreeByIdReqDTO */
export interface GetDepartmentTreeByIdReqDTO {
  deptId?: string;
  types?: number[];
}

/** GetDepartmentTreeByNameReqDTO */
export interface GetDepartmentTreeByNameReqDTO {
  nickname?: string;
  types?: number[];
}

/** GetDepartmentTreeReqDTO */
export interface GetDepartmentTreeReqDTO {
  types?: number[];
}

/** GetDepartmentUserReqDTO */
export interface GetDepartmentUserReqDTO {
  createTimeOrder?: boolean;
  depId?: string;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  endTime?: string;
  info?: string;
  /** @format int32 */
  pageNum?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int32 */
  startIndex?: number;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  startTime?: string;
}

/** GetFrogArticleArchivePageDTO */
export interface GetFrogArticleArchivePageDTO {
  frogArticleMonth?: string;
  frogArticleYear?: string;
  /** @format int32 */
  pageNum?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int32 */
  startIndex?: number;
  stationCode?: string;
}

/** GetFrogArticlePageDTO */
export interface GetFrogArticlePageDTO {
  /** @format int32 */
  frogArticleType?: number;
  /** @format int32 */
  pageNum?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int32 */
  startIndex?: number;
  stationCode?: string;
}

/** GetFrogArticlePrepAndNextReqDTO */
export interface GetFrogArticlePrepAndNextReqDTO {
  /** @format int64 */
  frogArticleId?: number;
  stationCode?: string;
  /** @format int32 */
  type?: number;
}

/** GetManageBannerPageReqDTO */
export interface GetManageBannerPageReqDTO {
  /** @format int64 */
  bannerApplicableTemplateId?: number;
  bannerDesignatedUser?: boolean;
  /** @format int32 */
  bannerStatus?: number;
  /** @format date-time */
  endTime?: string;
  name?: string;
  /** @format int32 */
  pageNum?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int32 */
  startIndex?: number;
  /** @format date-time */
  startTime?: string;
  stationCode?: string;
  /** @format int32 */
  type?: number;
}

/** GetManageFrogArticlePageDTO */
export interface GetManageFrogArticlePageDTO {
  /** @format int32 */
  frogArticleEnableStatus?: number;
  frogArticleTitle?: string;
  /** @format int32 */
  frogArticleType?: number;
  /** @format int32 */
  pageNum?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int32 */
  startIndex?: number;
  stationCode?: string;
}

/** GetRolesUserListReqDTO */
export interface GetRolesUserListReqDTO {
  name?: string;
  roleId?: string[];
}

/** GetShopListByCustomerReqDTO */
export interface GetShopListByCustomerReqDTO {
  /** @format int64 */
  customerId?: number;
  customerName?: string;
  /** @format int64 */
  mainCustomerId?: number;
  /** @format int32 */
  systemSource?: number;
}

/** GetThirdAuthLinkReqDTO */
export interface GetThirdAuthLinkReqDTO {
  /**
   * 类型 0-直采 1-代采
   * @format int32
   * @example "http://2peuvm.natappfree.cc/oauth/callback/google"
   */
  buType: number;
  /**
   * 回调地址
   * @example "http://2peuvm.natappfree.cc/oauth/callback/google"
   */
  redirectUrl: string;
  /**
   * 三方平台标识
   * @example "google"
   */
  thirdPlatform: string;
}

/** GetThirdDataReqDTO */
export interface GetThirdDataReqDTO {
  /**
   * 回调地址上带的授权Code
   * @example "4%2F0Adeu5BXKXVMj0i3b3L7IBojphwa_mrwL46nHLmvJMgK_y_Npt3mBBltKKhuyRRtggDh54Q"
   */
  authCode: string;
  /**
   * 0-直采 1-代采
   * @format int32
   */
  buType: number;
  /**
   * 三方邮箱,苹果会在回调时返回,特殊逻辑
   * @example "111@163.com"
   */
  email?: string;
  /**
   * idToken,苹果特殊逻辑
   * @example "4%2F0Adeu5BXKXVMj0i3b3L7IBojphwa_mrwL46nHLmvJMgK_y_Npt3mBBltKKhuyRRtggDh54Q"
   */
  idToken?: string;
  /**
   * 回调地址
   * @example "http://2peuvm.natappfree.cc/oauth/callback/google"
   */
  redirectUrl: string;
  /**
   * 回调链接上带的state参数
   * @example "b1b0971bfa724c8c512847b33eb78353"
   */
  state: string;
  /**
   * 三方平台标识
   * @example "google"
   */
  thirdPlatform: string;
  /**
   * 三方名称,苹果会在回调时返回,特殊逻辑
   * @example 111
   */
  username?: string;
}

/** GetThirdDataRespDTO */
export interface GetThirdDataRespDTO {
  /** 三方平台用户邮箱 */
  thirdCustomerEmail?: string;
  /** 三方平台用户账号,可以理解为唯一标识 */
  thirdCustomerId?: string;
  /** 三方平台用户昵称 */
  thirdCustomerName?: string;
  /** 三方平台用户手机号 */
  thirdCustomerPhone?: string;
  /** 三方平台标识 */
  thirdPlatform?: string;
}

/** GetUserListReqDTO */
export interface GetUserListReqDTO {
  createTimeOrder?: boolean;
  deptId?: string;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  endTime?: string;
  endTimeOrder?: boolean;
  mobile?: string;
  nickname?: string;
  /** @format int32 */
  pageNum?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int32 */
  startIndex?: number;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  startTime?: string;
  /** @format int32 */
  status?: number;
}

/** HolidayDateDTO */
export interface HolidayDateDTO {
  /**
   * 日期（格式:20230101）
   * @format int64
   */
  date?: number;
  /**
   * 天
   * @format int32
   */
  day?: number;
  /**
   * 月
   * @format int32
   */
  month?: number;
  /**
   * 1为工作日，2为周末，3为节假日
   * @format int32
   */
  type?: number;
  workDay?: boolean;
  /**
   * 年
   * @format int32
   */
  year?: number;
}

/** HotFrogArticleRespDTO */
export interface HotFrogArticleRespDTO {
  /** @format date-time */
  createTime?: string;
  /** @format int32 */
  frogArticleEnableStatus?: number;
  /** @format int64 */
  frogArticleId?: number;
  frogArticleImgUrl?: string;
  frogArticleSubTitle?: string;
  frogArticleTitle?: string;
  /** @format int32 */
  frogArticleType?: number;
  /** @format int64 */
  frogArticleViewCount?: number;
  /**
   * 未登录限制；1.可查看全文；2.部分可查看；3.不可查看
   * @format int32
   */
  noLoginRestriction?: number;
  /**
   * 未入会限制；1.可查看全文；2.部分可查看；3.不可查看
   * @format int32
   */
  noMembershipRestriction?: number;
  /** @format int64 */
  viewNum?: number;
}

/** HotFrogArticleSortDTO */
export interface HotFrogArticleSortDTO {
  /** @format int64 */
  frogArticleId?: number;
  /** @format int64 */
  realViewCount?: number;
}

/** IntAreaDTO */
export interface IntAreaDTO {
  /** 区号 */
  areaNum?: string;
  /** 子级地区 */
  children?: IntAreaDTO[];
  /** 区域代码 */
  code?: string;
  /**
   * id
   * @format int64
   */
  intAreaId?: number;
  /**
   * 层级:1洲 2国家 3省 4市
   * @format int64
   */
  level?: number;
  /** 中文名称 */
  name?: string;
  /** 英文名称 */
  nameEn?: string;
  /** 日文名称 */
  nameJa?: string;
  /** 韩文名称 */
  nameKr?: string;
  /** 中文拼音 */
  namePinyin?: string;
  /**
   * 父id
   * @format int64
   */
  pid?: number;
}

/** IntAreaQueryDTO */
export interface IntAreaQueryDTO {
  /** 区号 */
  areaNum?: string;
  /** 区域代码 */
  code?: string;
  /**
   * 地区id
   * @format int64
   */
  intAreaId?: number;
  /** 地区id */
  intAreaIdList?: number[];
  /**
   * 层级:1洲 2国家 3省....
   * @format int64
   */
  level?: number;
  /** 中文名称 */
  name?: string;
  /** 英文名称 */
  nameEn?: string;
  /** 日文名称 */
  nameJa?: string;
  /** 中文拼音 */
  namePinyin?: string;
  /**
   * 父id
   * @format int64
   */
  pid?: number;
}

/** InvitationInfoRespDTO */
export interface InvitationInfoRespDTO {
  /** 国家：日本：JP 韩国：KR */
  country?: string;
  /** 国家中文描述 */
  countryDesc?: string;
  /** 语言编码 */
  langCode?: string;
  /** 站点代码 */
  stationCode?: string;
}

/** JSONObject */
export type JSONObject = Record<string, object>;

/** ManageBannerReqDTO */
export interface ManageBannerReqDTO {
  categoryList?: number[];
}

/** ManageBannerRespDTO */
export interface ManageBannerRespDTO {
  /** @format int64 */
  bannerApplicableTemplateId?: number;
  bannerApplicableTemplateName?: string;
  /** @format int32 */
  bannerClickNum?: number;
  bannerDesignatedUserId?: string;
  /** @format int64 */
  bannerId?: number;
  bannerImgUrl?: string;
  bannerJumpLink?: string;
  bannerName?: string;
  /** @format int32 */
  bannerStatus?: number;
  /** @format int32 */
  bannerUserClickNum?: number;
  /** @format date-time */
  endTime?: string;
  /** @format int64 */
  mallCategoryId?: number;
  /** @format date-time */
  startTime?: string;
  stationCode?: string;
  /** @format int32 */
  systemSource?: number;
}

/** ManageFrogArticleRespDTO */
export interface ManageFrogArticleRespDTO {
  /** @format date-time */
  createTime?: string;
  /** @format int32 */
  frogArticleEnableStatus?: number;
  /** @format int64 */
  frogArticleId?: number;
  frogArticleSubTitle?: string;
  frogArticleTitle?: string;
  frogArticleType?: string;
  /** @format int64 */
  frogArticleViewCount?: number;
  /**
   * 未登录限制；1.可查看全文；2.部分可查看；3.不可查看
   * @format int32
   */
  noLoginRestriction?: number;
  /**
   * 未入会限制；1.可查看全文；2.部分可查看；3.不可查看
   * @format int32
   */
  noMembershipRestriction?: number;
  stationCode?: string;
}

/** ManageLoginRespDTO */
export interface ManageLoginRespDTO {
  containCustomerIds?: number[];
  containUserIds?: string[];
  /** @format int32 */
  directoried?: number;
  /** @format int64 */
  expiresIn?: number;
  loginWareHouse?: string;
  nick?: string;
  roles?: string[];
  token?: string;
  userId?: string;
  username?: string;
  warehouses?: UserWarehouseRespDTO[];
}

/** ManageMenuRespDTO */
export interface ManageMenuRespDTO {
  backEndPath?: string;
  btnPerm?: string;
  code?: string;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  createTime?: string;
  icon?: string;
  id?: string;
  name?: string;
  parentId?: string;
  redirect?: string;
  routeName?: string;
  routePath?: string;
  /** @format int32 */
  sort?: number;
  /** @format int32 */
  type?: number;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  updateTime?: string;
  /** @format int32 */
  visible?: number;
}

/** ManageRoleRespDTO */
export interface ManageRoleRespDTO {
  code?: string;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  createTime?: string;
  deptId?: string;
  manageRoleId?: string;
  name?: string;
  remark?: string;
  /** @format int32 */
  sort?: number;
  /** @format int32 */
  status?: number;
  type?: string;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  updateTime?: string;
}

/** ManageUserDetailRespDTO */
export interface ManageUserDetailRespDTO {
  avatar?: string;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  createTime?: string;
  deptId?: string;
  /** @format int32 */
  directoried?: number;
  email?: string;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  endTime?: string;
  /** @format int32 */
  gender?: number;
  id?: string;
  menus?: string[];
  mobile?: string;
  nickname?: string;
  relationDd?: string;
  relationDdDeptId?: string;
  relationDdDeptName?: string;
  relationDdUserId?: string;
  relationDdUserName?: string;
  roleIds?: string[];
  /** @format int32 */
  status?: number;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  updateTime?: string;
  username?: string;
}

/** ManageUserInfoRespDTO */
export interface ManageUserInfoRespDTO {
  id?: string;
  menus?: string[];
  roles?: string[];
}

/** ManageUserLoginRespDTO */
export interface ManageUserLoginRespDTO {
  avatar?: string;
  change?: boolean;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  createTime?: string;
  deptId?: string;
  /** @format int32 */
  directoried?: number;
  email?: string;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  endTime?: string;
  /** @format int32 */
  gender?: number;
  id?: string;
  mobile?: string;
  nickname?: string;
  perms?: string[];
  relationDd?: string;
  relationDdDeptId?: string;
  relationDdDeptName?: string;
  relationDdUserId?: string;
  relationDdUserName?: string;
  roles?: string[];
  /** @format int32 */
  status?: number;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  updateTime?: string;
  username?: string;
  warehouses?: UserWarehouseRespDTO[];
}

/** ManageUserRespDTO */
export interface ManageUserRespDTO {
  /** @example "yyyy-MM-dd HH:mm:ss" */
  createTime?: string;
  deptId?: string;
  /** @format int32 */
  directoried?: number;
  email?: string;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  endTime?: string;
  /** @format int32 */
  gender?: number;
  manageUserId?: string;
  mobile?: string;
  nickname?: string;
  relationDd?: string;
  relationDdDeptId?: string;
  relationDdDeptName?: string;
  relationDdUserId?: string;
  relationDdUserName?: string;
  roleIdStrs?: string;
  roleNames?: string;
  /** @format int32 */
  status?: number;
  type?: string;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  updateTime?: string;
  username?: string;
  wareCodeList?: UserWarehouseRespDTO[];
}

/** MembershipOrderDTO */
export interface MembershipOrderDTO {
  /** @format int32 */
  approveStatus?: number;
  /** @format date-time */
  createTime?: string;
  /** @format int32 */
  creationChannel?: number;
  /** @format int64 */
  customerId?: number;
  customerName?: string;
  exchangeCurrencyFrom?: string;
  exchangeCurrencyTo?: string;
  exchangeRate?: number;
  exchangeRateFloat?: number;
  /** @format date-time */
  expiresDate?: string;
  /** @format int32 */
  getChannel?: number;
  /** @format int64 */
  membershipOrderId?: number;
  /** @format int64 */
  membershipTemplateId?: number;
  membershipTemplateName?: string;
  /** @format int64 */
  membershipTemplatePriceId?: number;
  membershipTemplatePriceName?: string;
  /** @format int64 */
  oldMembershipTemplateId?: number;
  oldMembershipTemplateName?: string;
  orderNo?: string;
  /** @format int32 */
  orderType?: number;
  originCurrencyCode?: string;
  originDiscountPrice?: number;
  originPaymentAmount?: number;
  originReceiptAmount?: number;
  originSellAmount?: number;
  /** @format int32 */
  payChannel?: number;
  /** @format int32 */
  payStatus?: number;
  /** @format date-time */
  payTime?: string;
  payeeBankName?: string;
  payerBankName?: string;
  payerName?: string;
  /** @format date-time */
  paymentDate?: string;
  paypalCycleDate?: string;
  paypalOrderId?: string;
  paypalSubscriptionId?: string;
  /** @format date-time */
  receiptDate?: string;
  /**
   * 会员类型:0-普通,1-特殊
   * @format int32
   */
  specialType?: number;
  /** @format int64 */
  superCustomerId?: number;
  systemSource?: string;
  targetCurrencyCode?: string;
  targetDiscountPrice?: number;
  targetPaymentAmount?: number;
  targetReceiptAmount?: number;
  targetSellAmount?: number;
  txnId?: string;
  unificationCustomerFullName?: string;
  /** @format date-time */
  updateTime?: string;
  /** @format int32 */
  validPeriod?: number;
  /** @format int32 */
  validPeriodUnit?: number;
  zhongyinExchangeRate?: number;
}

/** MenuTreeListRespDTO */
export interface MenuTreeListRespDTO {
  children?: MenuTreeListRespDTO[];
  component?: string;
  icon?: string;
  id?: string;
  name?: string;
  parentId?: string;
  path?: string;
  redirect?: string;
  /** @format int32 */
  sort?: number;
  /** @format int32 */
  visible?: number;
}

/** MenuTreeRespDTO */
export interface MenuTreeRespDTO {
  children?: MenuTreeRespDTO[];
  icon?: string;
  id?: string;
  label?: string;
  path?: string;
  /** @format int32 */
  size?: number;
  /** @format int32 */
  type?: number;
  users?: ManageUserRespDTO[];
  /** @format int32 */
  visible?: number;
}

/** Meta */
export interface Meta {
  icon?: string;
  roles?: string[];
  title?: string;
}

/** ModifyPurchaseRecordDTO */
export interface ModifyPurchaseRecordDTO {
  /** @format int64 */
  customerId?: number;
  originUserName?: string;
  userId?: string;
  userName?: string;
}

/** MoveUserDepartmentReqDTO */
export interface MoveUserDepartmentReqDTO {
  deptId?: string;
  userList?: string[];
}

/** NotifyPrivatePublishVO */
export interface NotifyPrivatePublishVO {
  content?: string;
  /** @format int64 */
  targetCustomerShopId?: number;
  title?: string;
}

/** NotifyPrivateReadVO */
export interface NotifyPrivateReadVO {
  /** @format int64 */
  notifyTemplatePrivateId?: number;
  /** @format int32 */
  operateType?: number;
}

/** NotifyPrivateVO */
export interface NotifyPrivateVO {
  content?: string;
  /** @format date-time */
  createTime?: string;
  extraContent?: string;
  hyperlink?: string;
  hyperlinkParams?: string;
  notifyStatus?: boolean;
  /** @format int64 */
  notifyTemplateId?: number;
  /** @format int64 */
  notifyTemplatePrivateId?: number;
  notifyType?: string;
  /** @format int32 */
  operateType?: number;
  sourceBizId?: string;
  sourceBizType?: string;
  sourceId?: string;
  /** @format int64 */
  targetCustomerId?: number;
  /** @format int64 */
  targetCustomerShopId?: number;
  targetCustomerShopName?: string;
  targetLoginName?: string;
  /** @format int64 */
  targetMainCustomerId?: number;
  targetMainReadStatus?: boolean;
  /** @format date-time */
  targetMainReadTime?: string;
  targetSubReadStatus?: boolean;
  /** @format date-time */
  targetSubReadTime?: string;
  targetSystemSource?: string;
  title?: string;
  /** @format date-time */
  updateTime?: string;
}

/** NotifyPublicReadVO */
export interface NotifyPublicReadVO {
  /** @format int64 */
  notifyTemplatePublicId?: number;
}

/** NotifyPublicVO */
export interface NotifyPublicVO {
  content?: string;
  /** @format date-time */
  createTime?: string;
  hyperlink?: string;
  hyperlinkParams?: string;
  notifyStatus?: boolean;
  /** @format int64 */
  notifyTemplateId?: number;
  /** @format int64 */
  notifyTemplatePublicId?: number;
  notifyType?: string;
  readStatus?: boolean;
  /** @format date-time */
  readTime?: string;
  sourceBizId?: string;
  sourceBizType?: string;
  sourceId?: string;
  title?: string;
  /** @format date-time */
  updateTime?: string;
}

/** NotifyTemplateUpsertVO */
export interface NotifyTemplateUpsertVO {
  content?: string;
  /** @format date-time */
  createTime?: string;
  description?: string;
  hyperlink?: string;
  /** @format int64 */
  isMarketingNotification?: number;
  langcode?: string;
  /** @format int64 */
  notifyTemplateId?: number;
  notifyTemplateType?: string;
  notifyType?: string;
  title?: string;
  /** @format date-time */
  updateTime?: string;
}

/** OrderItem */
export interface OrderItem {
  asc?: boolean;
  column?: string;
}

/** PageMenuReqDTO */
export interface PageMenuReqDTO {
  menuId?: string;
  name?: string;
  /** @format int32 */
  pageNum?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int32 */
  startIndex?: number;
  /** @format int32 */
  visible?: number;
}

/** PasswordLoginReqDTO */
export interface PasswordLoginReqDTO {
  /** @format int32 */
  buType?: number;
  nameOrEmail?: string;
  password?: string;
  /** @format int32 */
  platformType?: number;
  recaptchaCode?: string;
  /** 三方平台用户账号,可以理解为唯一标识 */
  thirdCustomerId?: string;
  /** 三方平台用户名称 */
  thirdCustomerName?: string;
  /** 三方平台用户邮箱 */
  thirdEmail?: string;
  /** 三方平台标识 */
  thirdPlatform?: string;
}

/** QueryCustomerByManagerDTO */
export interface QueryCustomerByManagerDTO {
  /** @format int32 */
  accountType?: number;
  /** @format int64 */
  autoRenawal?: number;
  /** @format int64 */
  beforeWareCode?: number;
  /**
   * 业务类型 0 直采 1 代采
   * @format int32
   */
  buType?: number;
  chatWorkId?: string;
  countryCode?: string;
  countryPlaceCode?: string;
  /** @format int64 */
  currentCode?: number;
  customerEmail?: string;
  /** @format int64 */
  customerId?: number;
  customerIdList?: number[];
  customerMobile?: string;
  customerName?: string;
  /** @format int64 */
  customerShopId?: number;
  customerShopIds?: number[];
  /** @format int32 */
  customerStatus?: number;
  /** @format int32 */
  defaultLogin?: number;
  /** @format int32 */
  gender?: number;
  /** @format int64 */
  hasAssignSuccess?: number;
  /** @format int64 */
  hasSub?: number;
  /** @format int32 */
  internalFlag?: number;
  /** 语言代码:ja-日语,en-英语,th-泰语,ko-韩语 */
  langcode?: string;
  /** @format date-time */
  lastLoginTime?: string;
  loginName?: string;
  /** @format int64 */
  mainCustomerId?: number;
  /** @format int64 */
  managerId?: number;
  /** @format int64 */
  membershipId?: number;
  /** @format int64 */
  onlyBelongMe?: number;
  outsideLink?: string;
  /** @format int32 */
  pageNum?: number;
  /** @format int32 */
  pageSize?: number;
  password?: string;
  /**
   * 平台类型:1网页端,2APP 3PLUGIN,4H5
   * @format int32
   */
  platformType?: number;
  salt?: string;
  skype?: string;
  /** @format int32 */
  startIndex?: number;
  stationCode?: string;
  /** @format int64 */
  superCustomerId?: number;
  /** @format int64 */
  supervisionManagerId?: number;
  /** @format int32 */
  systemSource?: number;
  unificationCustomerFullName?: string;
  userId?: string;
  userName?: string;
  /** @format int64 */
  wareCode?: number;
}

/** QueryCustomerDetailManagerReqDTO */
export interface QueryCustomerDetailManagerReqDTO {
  customerStatusList?: number[];
  /** @format int64 */
  managerId?: number;
  /** @format int64 */
  supervisionManagerId?: number;
}

/** QueryCustomerDetailReqDTO */
export interface QueryCustomerDetailReqDTO {
  /** @format int64 */
  customerId?: number;
  /** @format int32 */
  pageNum?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int32 */
  startIndex?: number;
  /** @format int32 */
  systemSource?: number;
  unificationCustomerFullName?: string;
}

/** QueryCustomerDistributeReqDTO */
export interface QueryCustomerDistributeReqDTO {
  /** @format int32 */
  merchandiserUserEmptyFlag?: number;
  merchandiserUserId?: string;
  /** @format int32 */
  oemPurchaseUserEmptyFlag?: number;
  oemPurchaseUserId?: string;
  /** @format int32 */
  pageNum?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int32 */
  purchaseUserEmptyFlag?: number;
  purchaseUserId?: string;
  /** @format int32 */
  startIndex?: number;
  stationCode?: string;
  /** @format int32 */
  systemSource?: number;
  unificationCustomerFullName?: string;
  wareCode?: string;
}

/** QueryCustomerDistributeRespDTO */
export interface QueryCustomerDistributeRespDTO {
  /** @format date-time */
  createTime?: string;
  /** @format int64 */
  customerId?: number;
  merchandiserUserId?: string;
  merchandiserUserName?: string;
  nationCode?: string;
  oemPurchaseUserId?: string;
  oemPurchaseUserName?: string;
  purchaseUserId?: string;
  purchaseUserName?: string;
  stationCode?: string;
  /** @format int32 */
  systemSource?: number;
  unificationCustomerFullName?: string;
  wareCode?: string;
  wareName?: string;
}

/** QueryOperateLogRespDTO */
export interface QueryOperateLogRespDTO {
  content?: string;
  /** @format date-time */
  createTime?: string;
  userName?: string;
}

/** RegisterPageImageBackendDTO */
export interface RegisterPageImageBackendDTO {
  /** 图片地址 */
  imageUrl?: string;
  /** 跳转链接 */
  jumpUrl?: string;
  /**
   * id
   * @format int64
   */
  registerPageImageId?: number;
  /** 站点code */
  stationCode?: string;
}

/** RegisterPageImageBackendUpdateDTO */
export interface RegisterPageImageBackendUpdateDTO {
  /** 图片地址 */
  imageUrl?: string;
  /** 跳转链接 */
  jumpUrl?: string;
  /**
   * id
   * @format int64
   */
  registerPageImageId?: number;
  /** 站点code */
  stationCode?: string;
}

/** RegisterPageImageDTO */
export interface RegisterPageImageDTO {
  /** 图片地址 */
  imageUrl?: string;
  /** 跳转链接 */
  jumpUrl?: string;
  /** 站点code */
  stationCode?: string;
}

/** ReserveCallBackReqDTO */
export interface ReserveCallBackReqDTO {
  AccountID?: string;
  ActivityID?: string;
  ActivityName?: string;
  EventType?: string;
  LiveTime?: string;
  RequestUuid?: string;
  Sign?: string;
  Timestamp?: string;
  UserInfos?: UserReservationInfo[];
  ViewUrl?: string;
}

/** ResetPasswordReqDTO */
export interface ResetPasswordReqDTO {
  customerEmail?: string;
  /** 手机号 */
  customerMobile?: string;
  /** 国际区号 */
  globalRoaming?: string;
  newPassword?: string;
  verificationCode?: string;
}

/** RoleUserRespDTO */
export interface RoleUserRespDTO {
  /** @format int32 */
  count?: number;
  departmentName?: string;
  mobile?: string;
  nickname?: string;
  userId?: string;
  userName?: string;
}

/** RouteRespDTO */
export interface RouteRespDTO {
  alwaysShow?: boolean;
  children?: RouteRespDTO[];
  hidden?: boolean;
  id?: string;
  meta?: Meta;
  name?: string;
  path?: string;
  redirect?: string;
  /** @format int32 */
  sort?: number;
  /** @format int32 */
  type?: number;
}

/** SaveCustomerConfigReqDTO */
export interface SaveCustomerConfigReqDTO {
  /** 配置 code */
  configCode?: string;
  /** 配置名称 */
  configName?: string;
  /** 配置值 */
  configValue?: string;
}

/** SearchResultDTO */
export interface SearchResultDTO {
  /**
   * 图搜剩余次数
   * @format int32
   */
  imageSearchKey?: number;
  /**
   * 图搜最大次数
   * @format int32
   */
  imageSearchKeyMax?: number;
  /**
   * 价格跟踪剩余次数
   * @format int32
   */
  priceTrackingTimes?: number;
  /**
   * 价格跟踪最大次数
   * @format int32
   */
  priceTrackingTimesMax?: number;
  /**
   * 关键字搜索剩余次数
   * @format int32
   */
  searchKeywordKey?: number;
  /**
   * 关键字搜索最大次数
   * @format int32
   */
  searchKeywordKeyMax?: number;
}

/** SelectTypeDicDTO */
export interface SelectTypeDicDTO {
  /** 业务类型 */
  businessType?: string;
  /** 站点code */
  stationCode?: string;
  /** key */
  typeKey?: string;
  /** value */
  typeValue?: string;
}

/** ShopAdditionAddressRespDTO */
export interface ShopAdditionAddressRespDTO {
  additionItemList?: AdditionItemRespDTO[];
  /** @format int32 */
  additionScene?: number;
  addressAdditionRespDTO?: ShopAddressAdditionRespDTO;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  createTime?: string;
  /** @format int64 */
  customerId?: number;
  /** @format int64 */
  customerShopId?: number;
  /** @format int64 */
  shopAdditionId?: number;
  /** @format int32 */
  systemSource?: number;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  updateTime?: string;
}

/** ShopAdditionDetailRespDTO */
export interface ShopAdditionDetailRespDTO {
  additionItemList?: AdditionItemRespDTO[];
  /** @format int32 */
  additionScene?: number;
  categoryAdditionList?: CategoryAdditionRespDTO[];
  /** @example "yyyy-MM-dd HH:mm:ss" */
  createTime?: string;
  /** @format int64 */
  customerId?: number;
  /** @format int64 */
  customerShopId?: number;
  /** @format int64 */
  shopAdditionId?: number;
  shopAddressAdditionRespDTO?: ShopAddressAdditionRespDTO;
  /** @format int32 */
  systemSource?: number;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  updateTime?: string;
}

/** ShopAdditionRespDTO */
export interface ShopAdditionRespDTO {
  additionItemList?: AdditionItemRespDTO[];
  /** @format int32 */
  additionScene?: number;
  categoryAdditionList?: CategoryAdditionRespDTO[];
  /** @example "yyyy-MM-dd HH:mm:ss" */
  createTime?: string;
  /** @format int64 */
  customerId?: number;
  /** @format int64 */
  customerShopId?: number;
  /** @format int32 */
  noAdditionalFlag?: number;
  /** @format int64 */
  shopAdditionId?: number;
  /** 水洗标配置 */
  shopAdditionWashedLabelRespDTO?: ShopAdditionWashedLabelRespDTO;
  /** @format int32 */
  systemSource?: number;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  updateTime?: string;
}

/** ShopAdditionWashedLabelRespDTO */
export interface ShopAdditionWashedLabelRespDTO {
  /** @format int32 */
  additionScene?: number;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  createTime?: string;
  /** @format int64 */
  customerId?: number;
  /** @format int64 */
  customerShopId?: number;
  /** @format int64 */
  shopAdditionId?: number;
  /** @format int32 */
  systemSource?: number;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  updateTime?: string;
  washedLabelRespDTOList?: WashedLabelRespDTO[];
}

/** ShopAddressAdditionReqDTO */
export interface ShopAddressAdditionReqDTO {
  address?: string;
  area?: string;
  city?: string;
  countryCode?: string;
  email?: string;
  /** @format int64 */
  logisticsId?: number;
  logisticsName?: string;
  postalCode?: string;
  sendName?: string;
  sendTel?: string;
}

/** ShopAddressAdditionRespDTO */
export interface ShopAddressAdditionRespDTO {
  address?: string;
  area?: string;
  city?: string;
  countryCode?: string;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  createTime?: string;
  /** @format int64 */
  customerShopId?: number;
  email?: string;
  /** @format int64 */
  logisticsId?: number;
  logisticsName?: string;
  postalCode?: string;
  sendName?: string;
  sendTel?: string;
  /** @format int64 */
  shopAdditionId?: number;
  /** @format int64 */
  shopAddressAdditionId?: number;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  updateTime?: string;
}

/** ShopClearanceAddressRespDTO */
export interface ShopClearanceAddressRespDTO {
  address?: string;
  addressEn?: string;
  area?: string;
  areaEn?: string;
  city?: string;
  cityEn?: string;
  /** 市名称 */
  cityName?: string;
  clearanceCode?: string;
  /** @format int32 */
  clearanceType?: number;
  companyCode?: string;
  companyName?: string;
  companyNameEn?: string;
  countryCode?: string;
  /** 国家名称 */
  countryName?: string;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  createTime?: string;
  /** @format int64 */
  customerShopId?: number;
  /** @format int32 */
  defaultAddress?: number;
  email?: string;
  /** 国家代码 */
  nationCode?: string;
  /** 国家id */
  nationId?: string;
  phone?: string;
  postalCode?: string;
  /** 省名称 */
  provinceName?: string;
  receiveName?: string;
  receiveNameEn?: string;
  /** @format int64 */
  shopClearanceAddressId?: number;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  updateTime?: string;
}

/** ShopOverviewRespDTO */
export interface ShopOverviewRespDTO {
  availableAmount?: number;
  /** @format int64 */
  customerId?: number;
  customerShop?: AppShopRespDTO;
  /** @format int32 */
  customerStatus?: number;
  /** @format int32 */
  gender?: number;
  /** @format int64 */
  mainCustomerId?: number;
  /** @format int32 */
  systemSource?: number;
}

/** ShopPurchaseRespDTO */
export interface ShopPurchaseRespDTO {
  /**
   * api自动下单配置 1-每次询问 2-不询问，以一件代发价下单
   * @format int32
   */
  apiAutoCreateOrderType?: number;
  /** 创建时间 */
  createTime?: string;
  /**
   * 用户店铺id
   * @format int64
   */
  customerShopId?: number;
  /**
   * 发货配置项,0:每次联系 1:无需联络 2:普通无需,组合需要
   * @format int32
   */
  deliveryConfigurationType?: number;
  /**
   * 次品付费重购,0:其他 1:接受次品重拍 2:次品退货退款
   * @format int32
   */
  payRepurchaseType?: number;
  /**
   * 能接受的采购价格上涨幅度
   * @format int32
   */
  purchasePriceAdd?: number;
  /** @format int32 */
  repurchaseFree?: number;
  /** @format int32 */
  repurchasePrice?: number;
  /** @format int32 */
  repurchaseTryMax?: number;
  /**
   * 主键
   * @format int64
   */
  shopPurchaseId?: number;
  /** 更新时间 */
  updateTime?: string;
}

/** ShopShippingAddressRespDTO */
export interface ShopShippingAddressRespDTO {
  address?: string;
  addressEn?: string;
  area?: string;
  areaEn?: string;
  city?: string;
  cityEn?: string;
  /** 市名称 */
  cityName?: string;
  countryCode?: string;
  /** 国家名称 */
  countryName?: string;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  createTime?: string;
  /** @format int64 */
  customerShopId?: number;
  /** @format int32 */
  defaultAddress?: number;
  email?: string;
  /** 国家代码 */
  nationCode?: string;
  /** 国家id */
  nationId?: string;
  phone?: string;
  postalCode?: string;
  /** 省名称 */
  provinceName?: string;
  receiveName?: string;
  receiveNameEn?: string;
  /** @format int64 */
  shopShippingAddressId?: number;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  updateTime?: string;
}

/** ShopTaxDeductionAccountPageDTO */
export interface ShopTaxDeductionAccountPageDTO {
  /** @format int64 */
  customerShopId?: number;
  /** @format int32 */
  pageNum?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int32 */
  startIndex?: number;
}

/** ShopTaxDeductionAccountRespDTO */
export interface ShopTaxDeductionAccountRespDTO {
  branchCode?: string;
  /** @format int64 */
  customerShopId?: number;
  financialInstitutionCode?: string;
  /** @format int64 */
  id?: number;
  importerCode?: string;
  /** @format int32 */
  isDefault?: number;
  taxDeductionAccount?: string;
}

/** ShopWashedLabelAdditionReqDTO */
export interface ShopWashedLabelAdditionReqDTO {
  companyAddress?: string;
  companyLogo?: string;
  companyName?: string;
  /** @format int64 */
  customerShopId?: number;
  /** @format int32 */
  imageRatio?: number;
  /** @format int32 */
  model?: number;
  /** @format int32 */
  productDesignation?: number;
  /** @format int64 */
  shopAdditionId?: number;
  /** @format int64 */
  shopAddressWashedlabelId?: number;
}

/** SignUpReqDTO */
export interface SignUpReqDTO {
  /**
   * 业务类型 0直采 1代采
   * @format int32
   */
  buType?: number;
  /** 渠道 */
  channel?: string;
  /** chartWorkId */
  chatWorkId?: string;
  /** 区号 */
  countryCode?: string;
  /** 国家code */
  countryPlaceCode?: string;
  /** 邮箱地址 */
  customerEmail?: string;
  /** 客户手机号 */
  customerMobile?: string;
  /** 联系人姓名 */
  customerName?: string;
  /**
   * 客户状态：1-正常；2-停用
   * @format int32
   * @example 1
   */
  customerStatus?: number;
  /**
   * 性别：1-男；2-女；3-未知
   * @format int32
   * @example 1
   */
  gender?: number;
  /** 三方邀请码 */
  invitationCode?: string;
  /**
   * 邀请平台:0-内部,1-aucfan
   * @format int32
   */
  invitationPlatform?: number;
  /** 语言代码 */
  langcode?: string;
  /** 登录名 */
  loginName?: string;
  /** 登录密码 */
  password?: string;
  /**
   * 平台类型:1网页端,2APP,3插件,4H5
   * @format int32
   */
  platformType?: number;
  /** 三方推荐邮箱 */
  recommendationEmail?: string;
  /** skype */
  skype?: string;
  /** spm */
  spm?: string;
  /** 站点code */
  stationCode?: string;
  /**
   * 隐私协议id
   * @format int64
   */
  termId?: number;
  /** 三方平台用户账号,可以理解为唯一标识 */
  thirdCustomerId?: string;
  /** 三方平台用户名称 */
  thirdCustomerName?: string;
  /** 三方平台用户邮箱 */
  thirdEmail?: string;
  /** 三方平台标识 */
  thirdPlatform?: string;
  /** 时区 */
  utcTimeZone?: string;
  /** 验证码 */
  verificationCode?: string;
}

/** SignUpRespDTO */
export interface SignUpRespDTO {
  uuid?: string;
}

/** SupportCenterContentDTO */
export interface SupportCenterContentDTO {
  /** 业务类型: 2B, 2C */
  bizType?: string;
  /** 内容类型: text, video */
  contentType?: string;
  /** 内容url */
  contentUrl?: string;
  /**
   * 发生时间
   * @format date-time
   */
  createTime?: string;
  /**
   * 创建者id
   * @format int64
   */
  creator?: number;
  /** 描述 */
  description?: string;
  /**
   * 修改者id
   * @format int64
   */
  modifier?: number;
  /** @format int64 */
  parentSupportCenterSubjectId?: number;
  /**
   * 排序
   * @format int32
   */
  sort?: number;
  /** 站点code */
  stationCode?: string;
  /**
   * id
   * @format int64
   */
  supportCenterContentId?: number;
  /**
   * 所属分类id
   * @format int64
   */
  supportCenterSubjectId?: number;
  /** 标题 */
  title?: string;
  /**
   * 更新时间
   * @format date-time
   */
  updateTime?: string;
  /** 封面图片 */
  videoImageUrl?: string;
}

/** SupportCenterContentUpdateBackendDTO */
export interface SupportCenterContentUpdateBackendDTO {
  /** 业务类型: 2B, 2C */
  bizType?: string;
  /** 内容 */
  content?: string;
  /** 内容类型: text, video */
  contentType?: string;
  /** 描述 */
  description?: string;
  /**
   * 排序
   * @format int32
   */
  sort?: number;
  /** 站点code */
  stationCode?: string;
  /**
   * id
   * @format int64
   */
  supportCenterContentId?: number;
  /**
   * 所属分类id
   * @format int64
   */
  supportCenterSubjectId?: number;
  /** 标题 */
  title?: string;
  /** 封面图片 */
  videoImageUrl?: string;
}

/** SupportCenterSubjectDTO */
export interface SupportCenterSubjectDTO {
  /** 业务类型: 2B, 2C */
  bizType?: string;
  /** 子目录集合 */
  childrenList?: SupportCenterSubjectDTO[];
  /** 下属的文档 */
  contentList?: SupportCenterContentDTO[];
  /** 菜单类型： video；text */
  contentType?: string;
  /**
   * 发生时间
   * @format date-time
   */
  createTime?: string;
  /**
   * 创建者id
   * @format int64
   */
  creator?: number;
  /**
   * 修改者id
   * @format int64
   */
  modifier?: number;
  /**
   * 父级分类id
   * @format int64
   */
  parentSupportCenterSubjectId?: number;
  /**
   * 排序
   * @format int32
   */
  sort?: number;
  /** 站点code */
  stationCode?: string;
  /** 分类名称 */
  subject?: string;
  /**
   * id
   * @format int64
   */
  supportCenterSubjectId?: number;
  /**
   * 更新时间
   * @format date-time
   */
  updateTime?: string;
}

/** SupportCenterSubjectUpdateBackendDTO */
export interface SupportCenterSubjectUpdateBackendDTO {
  /** 业务类型: 2B, 2C */
  bizType?: string;
  /**
   * 父级分类id
   * @format int64
   */
  parentSupportCenterSubjectId?: number;
  /**
   * 排序
   * @format int32
   */
  sort?: number;
  /** 站点code */
  stationCode?: string;
  /** 分类名称 */
  subject?: string;
  /**
   * id
   * @format int64
   */
  supportCenterSubjectId?: number;
}

/** TakeUserAddReqDTO */
export interface TakeUserAddReqDTO {
  /** @format int32 */
  cityId?: number;
  email?: string;
  password?: string;
  takeUserMallReqDTOList?: TakeUserMallReqDTO[];
  username?: string;
}

/** TakeUserEditReqDTO */
export interface TakeUserEditReqDTO {
  /** @format int32 */
  cityId?: number;
  email?: string;
  password?: string;
  /** @format int64 */
  takeUserId?: number;
  takeUserMallReqDTOList?: TakeUserMallReqDTO[];
  username?: string;
}

/** TakeUserLoginReqDTO */
export interface TakeUserLoginReqDTO {
  email?: string;
  password?: string;
}

/** TakeUserMallReqDTO */
export interface TakeUserMallReqDTO {
  mallId?: string;
  mallName?: string;
}

/** TakeUserReqDTO */
export interface TakeUserReqDTO {
  /** @format int32 */
  pageNum?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int32 */
  startIndex?: number;
  username?: string;
}

/** TakeUserRespDTO */
export interface TakeUserRespDTO {
  /** @format int32 */
  cityId?: number;
  /** @format date-time */
  createTime?: string;
  email?: string;
  mallId?: string;
  mallName?: string;
  password?: string;
  /** @format int64 */
  takeUserId?: number;
  /** @format date-time */
  updateTime?: string;
  username?: string;
}

/** TermConfigDTO */
export interface TermConfigDTO {
  /**
   * 客户同意时间
   * @format date-time
   */
  agreeTime?: string;
  /**
   * 发生时间
   * @format date-time
   */
  createTime?: string;
  /**
   * 创建者id
   * @format int64
   */
  creator?: number;
  /** 创建者名称 */
  creatorName?: string;
  /** 配置地址 */
  fileUrl?: string;
  /**
   * 是否生效: 0 未生效, 1 生效中
   * @format int32
   */
  isEffect?: number;
  /**
   * 修改者id
   * @format int64
   */
  modifier?: number;
  /** 修改者名称 */
  modifierName?: string;
  /** 站点code */
  stationCode?: string;
  /**
   * 条款id
   * @format int64
   */
  termId?: number;
  /** 条款类型: utilize:利用规约, privacy:隐私权限, regular:次转正提醒, oem:oem条款 */
  termType?: string;
  /**
   * 更新时间
   * @format date-time
   */
  updateTime?: string;
}

/** TermUpdateDTO */
export interface TermUpdateDTO {
  content?: string;
  /** @format int64 */
  termId?: number;
}

/** Type */
export interface Type {
  typeName?: string;
}

/** UpdateAdditionAddressReqDTO */
export interface UpdateAdditionAddressReqDTO {
  additionList?: AddAdditionItemReqDTO[];
  /** @format int32 */
  additionScene?: number;
  addressAddition?: ShopAddressAdditionReqDTO;
}

/** UpdateAdditionReqDTO */
export interface UpdateAdditionReqDTO {
  additionList?: AddAdditionItemReqDTO[];
  /** @format int32 */
  additionScene?: number;
  addressAddition?: ShopAddressAdditionReqDTO;
  categoryCodeList?: number[];
  /** @format int32 */
  noAdditionalFlag?: number;
  /** @format int64 */
  shopAdditionId?: number;
}

/** UpdateAdditionWashedLabelReqDTO */
export interface UpdateAdditionWashedLabelReqDTO {
  /** @format int32 */
  additionScene?: number;
  /** @format int64 */
  customerShopId?: number;
  shopAdditionId?: string;
  washedLabelAdditionReqDTOs?: ShopWashedLabelAdditionReqDTO[];
}

/** UpdateBannerReqDTO */
export interface UpdateBannerReqDTO {
  /** @format int64 */
  bannerApplicableTemplateId?: number;
  bannerDesignatedUserId?: string;
  /** @format int64 */
  bannerId?: number;
  bannerImgUrl?: string;
  bannerJumpLink?: string;
  bannerName?: string;
  /** @format int32 */
  bannerStatus?: number;
  /** @format int32 */
  bannerType?: number;
  /** @format date-time */
  endTime?: string;
  /** @format int64 */
  mallCategoryId?: number;
  /** @format date-time */
  startTime?: string;
  /** @format int32 */
  systemSource?: number;
}

/** UpdateClearanceAddressReqDTO */
export interface UpdateClearanceAddressReqDTO {
  address?: string;
  addressEn?: string;
  area?: string;
  areaEn?: string;
  city?: string;
  cityEn?: string;
  /** 市名称 */
  cityName?: string;
  /** 通关码 */
  clearanceCode?: string;
  /** @format int32 */
  clearanceType?: number;
  companyCode?: string;
  companyName?: string;
  companyNameEn?: string;
  /** 国家名称 */
  countryName?: string;
  /** @format int32 */
  defaultAddress?: number;
  email?: string;
  /** 国家代码 */
  nationCode?: string;
  /** 国家id */
  nationId?: string;
  phone?: string;
  postalCode?: string;
  /** 省名称 */
  provinceName?: string;
  receiveName?: string;
  receiveNameEn?: string;
  shopClearanceAddressId?: string;
}

/** UpdateClearanceDefaultAddressReqDTO */
export interface UpdateClearanceDefaultAddressReqDTO {
  shopClearanceAddressId?: string;
}

/** UpdateCustomerReqDTO */
export interface UpdateCustomerReqDTO {
  chatWorkId?: string;
  countryCode?: string;
  /** @format int64 */
  customerId?: number;
  customerMobile?: string;
  customerName?: string;
  /** @format int32 */
  gender?: number;
  googleUrl?: string;
  loginName?: string;
  skype?: string;
  /** @format int32 */
  systemSource?: number;
}

/** UpdateCustomerShopDTO */
export interface UpdateCustomerShopDTO {
  clientId?: string;
  clientSecret?: string;
  /** @format int32 */
  customerShopAuthStatus?: number;
  /** @format int64 */
  customerShopId?: number;
  customerShopLogo?: string;
  customerShopName?: string;
  /** @format int32 */
  customerShopPlatform?: number;
  customerShopUrl?: string;
  wareCode?: string;
}

/** UpdateDepartmentReqDTO */
export interface UpdateDepartmentReqDTO {
  directorId?: string;
  id?: string;
  name?: string;
  /** @format int32 */
  sort?: number;
}

/** UpdateFrogArticleReqDTO */
export interface UpdateFrogArticleReqDTO {
  frogArticleContent?: string;
  /** @format int32 */
  frogArticleEnableStatus?: number;
  /** @format int64 */
  frogArticleId?: number;
  frogArticleImgUrl?: string;
  frogArticleSubTitle?: string;
  frogArticleTitle?: string;
  /** @format int32 */
  frogArticleType?: number;
  /** @format int64 */
  frogArticleViewCount?: number;
  /**
   * 未登录限制；1.可查看全文；2.部分可查看；3.不可查看
   * @format int32
   */
  noLoginRestriction?: number;
  /**
   * 未入会限制；1.可查看全文；2.部分可查看；3.不可查看
   * @format int32
   */
  noMembershipRestriction?: number;
  stationCode?: string;
}

/** UpdateFrogArticleStatusReqDTO */
export interface UpdateFrogArticleStatusReqDTO {
  /** @format int64 */
  frogArticleId?: number;
}

/** UpdateManageBannerReqDTO */
export interface UpdateManageBannerReqDTO {
  /** @format int64 */
  bannerId?: number;
}

/** UpdateManageUserReqDTO */
export interface UpdateManageUserReqDTO {
  avatar?: string;
  deptId?: string;
  /** @format int32 */
  directoried?: number;
  email?: string;
  /** @format int32 */
  gender?: number;
  id?: string;
  mobile?: string;
  nickname?: string;
  password?: string;
  relationDd?: string;
  relationDdDeptId?: string;
  relationDdDeptName?: string;
  relationDdUserId?: string;
  relationDdUserName?: string;
  roleIds?: string[];
  /** @format int32 */
  status?: number;
  username?: string;
  wareCode?: string[];
}

/** UpdateManagerPasswordReqDTO */
export interface UpdateManagerPasswordReqDTO {
  id?: string;
  newPassword?: string;
  oldPassword?: string;
}

/** UpdateMenuReqDTO */
export interface UpdateMenuReqDTO {
  backEndPath?: string;
  btnPerm?: string;
  code?: string;
  icon?: string;
  id?: string;
  name?: string;
  redirect?: string;
  routeName?: string;
  /** @format int32 */
  sort?: number;
  /** @format int32 */
  type?: number;
  /** @format int32 */
  visible?: number;
}

/** UpdateMenuVisibleReqDTO */
export interface UpdateMenuVisibleReqDTO {
  id?: string;
  /** @format int32 */
  visible?: number;
}

/** UpdatePasswordReqDTO */
export interface UpdatePasswordReqDTO {
  /** @format int64 */
  customerId?: number;
  newPassword?: string;
  oldPassword?: string;
}

/**
 * UpdatePasswordReqManagerDTO
 * 更新管理员密码DTO
 */
export interface UpdatePasswordReqManagerDTO {
  id?: string;
  password?: string;
  /** @format int32 */
  status?: number;
}

/** UpdateRoleMenusReqDTO */
export interface UpdateRoleMenusReqDTO {
  id?: string;
  menuIds?: string[];
}

/** UpdateRoleReqDTO */
export interface UpdateRoleReqDTO {
  id?: string;
  name?: string;
  remark?: string;
  /** @format int32 */
  sort?: number;
  /** @format int32 */
  status?: number;
}

/** UpdateShippingAddressReqDTO */
export interface UpdateShippingAddressReqDTO {
  address?: string;
  addressEn?: string;
  area?: string;
  areaEn?: string;
  city?: string;
  cityEn?: string;
  /** 市名称 */
  cityName?: string;
  countryCode?: string;
  /** 国家名称 */
  countryName?: string;
  /** @format int32 */
  defaultAddress?: number;
  email?: string;
  /** 国家代码 */
  nationCode?: string;
  /** 国家id */
  nationId?: string;
  phone?: string;
  postalCode?: string;
  /** 省名称 */
  provinceName?: string;
  receiveName?: string;
  receiveNameEn?: string;
  shopShippingAddressId?: string;
}

/** UpdateShippingDefaultAddressReqDTO */
export interface UpdateShippingDefaultAddressReqDTO {
  shopShippingAddressId?: string;
}

/** UpdateShopDeliveryReqDTO */
export interface UpdateShopDeliveryReqDTO {
  /** @format int64 */
  customerShopId?: number;
  /**
   * 发货配置项,0:每次联系 1:无需联络 2:普通无需,组合需要
   * @format int32
   */
  deliveryConfigurationType?: number;
  /** @format date-time */
  updateTime?: string;
}

/** UpdateShopPurchaseReqDTO */
export interface UpdateShopPurchaseReqDTO {
  /**
   * api自动下单配置 1-每次询问 2-不询问，以一件代发价下单
   * @format int32
   */
  apiAutoCreateOrderType?: number;
  /**
   * 发货配置项,0:每次联系 1:无需联络 2:普通无需,组合需要
   * @format int32
   */
  deliveryConfigurationType?: number;
  /**
   * 次品付费重购,0:其他 1:接受次品重拍 2:次品退货退款
   * @format int32
   */
  payRepurchaseType?: number;
  /**
   * 能接受的采购价格上涨幅度
   * @format int32
   */
  purchasePriceAdd?: number;
  /**
   * 更新渠道:1-接口2-feign
   * @format int32
   */
  updateChannel?: number;
}

/** UpdateShopTaxDeductionAccountDTO */
export interface UpdateShopTaxDeductionAccountDTO {
  /** 分行代码 */
  branchCode?: string;
  /** 金融机构代码 */
  financialInstitutionCode?: string;
  /**
   * 唯一ID
   * @format int64
   */
  id?: number;
  /** 进口商标准代码 */
  importerCode?: string;
  /**
   * 是否是默认设置 0 否 1 是
   * @format int32
   */
  isDefault?: number;
  /** 扣税账号 */
  taxDeductionAccount?: string;
}

/** UpdateWareOemReqDTO */
export interface UpdateWareOemReqDTO {
  /** @format int64 */
  customerShopId?: number;
  /** @format int32 */
  isExistOem?: number;
}

/** UserManagerDetailRespDTO */
export interface UserManagerDetailRespDTO {
  businessRepresentativeId?: string;
  businessRepresentativeName?: string;
  customerDirectorId?: string;
  customerDirectorName?: string;
  /** @format int64 */
  customerId?: number;
  customerName?: string;
}

/** UserReservationInfo */
export interface UserReservationInfo {
  ExternalID?: string;
  Extra?: string;
  NickName?: string;
  ReservationTel?: string;
  UserAccessIP?: string;
  UserAgent?: string;
  UserID?: string;
}

/** UserWarehouseRespDTO */
export interface UserWarehouseRespDTO {
  /** @format date-time */
  createTime?: string;
  manageUserId?: string;
  /** @format int64 */
  manageUserWarehouseId?: number;
  wareCode?: string;
  wareName?: string;
}

/**
 * VideoActivityDTO
 * 直播间对象
 */
export interface VideoActivityDTO {
  /**
   * 活动id
   * @format int64
   */
  activityId?: number;
  /**
   * 关播时间
   * @format int64
   */
  closeTime?: number;
  /** 直播间封面 */
  coverImage?: string;
  /**
   * 是否开启显示观看人数 0：关闭 1：开启
   * @format int32
   */
  enablePeopleCount?: number;
  /**
   * 是否开启直播预约功能 0-关闭 1-开启
   * @format int32
   */
  enableReserve?: number;
  /**
   * 开播时间
   * @format int64
   */
  liveTime?: number;
  /** 直播间名称 */
  name?: string;
  /** PC 端页头图的URL */
  pcBackImageUrl?: string;
  /** 默认PC端背景图的URL */
  pcBackImageUrlDefault?: string;
  /** PC端页头图的URL */
  pcHeaderImageUrl?: string;
  /**
   * 直播间预约状态 0-未预约 1-已预约
   * @format int32
   */
  reserveFlag?: number;
  /**
   * 直播状态 1：直播中 2：预告 3：回放 4：停止
   * @format int32
   */
  status?: number;
  /** token 游客模式-1 */
  token?: string;
  /**
   * 播放量
   * @format int64
   */
  uv?: number;
  /** 直播间地址 */
  viewUrl?: string;
}

/** WareNameLangDTO */
export interface WareNameLangDTO {
  lang?: string;
  wareName?: string;
}

/** WashedLabelRespDTO */
export interface WashedLabelRespDTO {
  companyAddress?: string;
  companyLogo?: string;
  companyName?: string;
  /** @format date-time */
  createTime?: string;
  /** @format int64 */
  customerShopId?: number;
  /** @format int32 */
  imageRatio?: number;
  /** @format int32 */
  model?: number;
  /** @format int32 */
  productDesignation?: number;
  /** @format int64 */
  shopAdditionId?: number;
  /** @format int64 */
  shopAddressWashedlabelId?: number;
  /** @format date-time */
  updateTime?: string;
}

/** BizResponse«CustomerConfigRespDTO» */
export interface BizResponseCustomerConfigRespDTO {
  code?: string;
  data?: CustomerConfigRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«CustomerDetailManagerRespDTO» */
export interface BizResponseCustomerDetailManagerRespDTO {
  code?: string;
  data?: CustomerDetailManagerRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«CustomerDetailRespDTO» */
export interface BizResponseCustomerDetailRespDTO {
  code?: string;
  data?: CustomerDetailRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«CustomerDistributeQueryRespDTO» */
export interface BizResponseCustomerDistributeQueryRespDTO {
  code?: string;
  data?: CustomerDistributeQueryRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«CustomerGuide» */
export interface BizResponseCustomerGuide {
  code?: string;
  data?: CustomerGuide;
  msg?: string;
  success?: boolean;
}

/** BizResponse«CustomerLoginRespDTO» */
export interface BizResponseCustomerLoginRespDTO {
  code?: string;
  data?: CustomerLoginRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«CustomerMembershipResDTO» */
export interface BizResponseCustomerMembershipResDTO {
  code?: string;
  data?: CustomerMembershipResDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«CustomerOverviewRespDTO» */
export interface BizResponseCustomerOverviewRespDTO {
  code?: string;
  data?: CustomerOverviewRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«CustomerRespDTO» */
export interface BizResponseCustomerRespDTO {
  code?: string;
  data?: CustomerRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«CustomerShopAddressRespDTO» */
export interface BizResponseCustomerShopAddressRespDTO {
  code?: string;
  data?: CustomerShopAddressRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«CustomerShopRespDTO» */
export interface BizResponseCustomerShopRespDTO {
  code?: string;
  data?: CustomerShopRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«DataDictRespDTO» */
export interface BizResponseDataDictRespDTO {
  code?: string;
  data?: DataDictRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«DepartmentDetailRespDTO» */
export interface BizResponseDepartmentDetailRespDTO {
  code?: string;
  data?: DepartmentDetailRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«FrogArticleDetailRespDTO» */
export interface BizResponseFrogArticleDetailRespDTO {
  code?: string;
  data?: FrogArticleDetailRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«FrogArticlePrepAndNextRespDTO» */
export interface BizResponseFrogArticlePrepAndNextRespDTO {
  code?: string;
  data?: FrogArticlePrepAndNextRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«GetActivitiesRespDTO» */
export interface BizResponseGetActivitiesRespDTO {
  code?: string;
  /** 获取直播间列表 */
  data?: GetActivitiesRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«GetThirdDataRespDTO» */
export interface BizResponseGetThirdDataRespDTO {
  code?: string;
  data?: GetThirdDataRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«HashSet«long»» */
export interface BizResponseHashSetLong {
  code?: string;
  data?: number[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«IntAreaDTO» */
export interface BizResponseIntAreaDTO {
  code?: string;
  data?: IntAreaDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«InvitationInfoRespDTO» */
export interface BizResponseInvitationInfoRespDTO {
  code?: string;
  data?: InvitationInfoRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«JSONArray» */
export interface BizResponseJSONArray {
  code?: string;
  data?: object[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«JSONObject» */
export interface BizResponseJSONObject {
  code?: string;
  data?: Record<string, object>;
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«AdditionTemplateRespDTO»» */
export interface BizResponseListAdditionTemplateRespDTO {
  code?: string;
  data?: AdditionTemplateRespDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«CategoryCodeRespDTO»» */
export interface BizResponseListCategoryCodeRespDTO {
  code?: string;
  data?: CategoryCodeRespDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«CustomerCanTransformRespDTO»» */
export interface BizResponseListCustomerCanTransformRespDTO {
  code?: string;
  data?: CustomerCanTransformRespDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«CustomerDetailRespDTO»» */
export interface BizResponseListCustomerDetailRespDTO {
  code?: string;
  data?: CustomerDetailRespDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«CustomerMembershipResDTO»» */
export interface BizResponseListCustomerMembershipResDTO {
  code?: string;
  data?: CustomerMembershipResDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«CustomerShopBySuperRespDTO»» */
export interface BizResponseListCustomerShopBySuperRespDTO {
  code?: string;
  data?: CustomerShopBySuperRespDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«CustomerShopPageDTO»» */
export interface BizResponseListCustomerShopPageDTO {
  code?: string;
  data?: CustomerShopPageDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«CustomerShopRespDTO»» */
export interface BizResponseListCustomerShopRespDTO {
  code?: string;
  data?: CustomerShopRespDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«CustomerStationDTO»» */
export interface BizResponseListCustomerStationDTO {
  code?: string;
  data?: CustomerStationDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«CustomerTagDTO»» */
export interface BizResponseListCustomerTagDTO {
  code?: string;
  data?: CustomerTagDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«CustomerThirdBindRespDTO»» */
export interface BizResponseListCustomerThirdBindRespDTO {
  code?: string;
  data?: CustomerThirdBindRespDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«Customer»» */
export interface BizResponseListCustomer {
  code?: string;
  data?: Customer[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«DataDictRespDTO»» */
export interface BizResponseListDataDictRespDTO {
  code?: string;
  data?: DataDictRespDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«DepartmentDetailRespDTO»» */
export interface BizResponseListDepartmentDetailRespDTO {
  code?: string;
  data?: DepartmentDetailRespDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«DepartmentTreeRespDTO»» */
export interface BizResponseListDepartmentTreeRespDTO {
  code?: string;
  data?: DepartmentTreeRespDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«DingCompany对象»» */
export interface BizResponseListDingCompany {
  code?: string;
  data?: DingCompany[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«FeedbackInfoRspDTO»» */
export interface BizResponseListFeedbackInfoRspDTO {
  code?: string;
  data?: FeedbackInfoRspDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«FrogArticleArchiveSortRespDTO»» */
export interface BizResponseListFrogArticleArchiveSortRespDTO {
  code?: string;
  data?: FrogArticleArchiveSortRespDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«HolidayDateDTO»» */
export interface BizResponseListHolidayDateDTO {
  code?: string;
  data?: HolidayDateDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«HotFrogArticleSortDTO»» */
export interface BizResponseListHotFrogArticleSortDTO {
  code?: string;
  data?: HotFrogArticleSortDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«IntAreaDTO»» */
export interface BizResponseListIntAreaDTO {
  code?: string;
  data?: IntAreaDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«ManageBannerRespDTO»» */
export interface BizResponseListManageBannerRespDTO {
  code?: string;
  data?: ManageBannerRespDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«ManageRoleRespDTO»» */
export interface BizResponseListManageRoleRespDTO {
  code?: string;
  data?: ManageRoleRespDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«ManageUserRespDTO»» */
export interface BizResponseListManageUserRespDTO {
  code?: string;
  data?: ManageUserRespDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«MaterialPrice对象»» */
export interface BizResponseListMaterialPrice {
  code?: string;
  data?: MaterialPrice[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«MembershipOrderDTO»» */
export interface BizResponseListMembershipOrderDTO {
  code?: string;
  data?: MembershipOrderDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«MenuTreeListRespDTO»» */
export interface BizResponseListMenuTreeListRespDTO {
  code?: string;
  data?: MenuTreeListRespDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«MenuTreeRespDTO»» */
export interface BizResponseListMenuTreeRespDTO {
  code?: string;
  data?: MenuTreeRespDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«QueryOperateLogRespDTO»» */
export interface BizResponseListQueryOperateLogRespDTO {
  code?: string;
  data?: QueryOperateLogRespDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«RegisterPageImageBackendDTO»» */
export interface BizResponseListRegisterPageImageBackendDTO {
  code?: string;
  data?: RegisterPageImageBackendDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«RoleUserRespDTO»» */
export interface BizResponseListRoleUserRespDTO {
  code?: string;
  data?: RoleUserRespDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«RouteRespDTO»» */
export interface BizResponseListRouteRespDTO {
  code?: string;
  data?: RouteRespDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«SelectTypeDicDTO»» */
export interface BizResponseListSelectTypeDicDTO {
  code?: string;
  data?: SelectTypeDicDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«ShopAdditionRespDTO»» */
export interface BizResponseListShopAdditionRespDTO {
  code?: string;
  data?: ShopAdditionRespDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«ShopClearanceAddressRespDTO»» */
export interface BizResponseListShopClearanceAddressRespDTO {
  code?: string;
  data?: ShopClearanceAddressRespDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«ShopPurchaseRespDTO»» */
export interface BizResponseListShopPurchaseRespDTO {
  code?: string;
  data?: ShopPurchaseRespDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«ShopShippingAddressRespDTO»» */
export interface BizResponseListShopShippingAddressRespDTO {
  code?: string;
  data?: ShopShippingAddressRespDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«SupportCenterContentDTO»» */
export interface BizResponseListSupportCenterContentDTO {
  code?: string;
  data?: SupportCenterContentDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«SupportCenterSubjectDTO»» */
export interface BizResponseListSupportCenterSubjectDTO {
  code?: string;
  data?: SupportCenterSubjectDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«TermConfigDTO»» */
export interface BizResponseListTermConfigDTO {
  code?: string;
  data?: TermConfigDTO[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«long»» */
export interface BizResponseListLong {
  code?: string;
  data?: number[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«List«string»» */
export interface BizResponseListString {
  code?: string;
  data?: string[];
  msg?: string;
  success?: boolean;
}

/** BizResponse«ManageLoginRespDTO» */
export interface BizResponseManageLoginRespDTO {
  code?: string;
  data?: ManageLoginRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«ManageMenuRespDTO» */
export interface BizResponseManageMenuRespDTO {
  code?: string;
  data?: ManageMenuRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«ManageRoleRespDTO» */
export interface BizResponseManageRoleRespDTO {
  code?: string;
  data?: ManageRoleRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«ManageUserDetailRespDTO» */
export interface BizResponseManageUserDetailRespDTO {
  code?: string;
  data?: ManageUserDetailRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«ManageUserInfoRespDTO» */
export interface BizResponseManageUserInfoRespDTO {
  code?: string;
  data?: ManageUserInfoRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«ManageUserLoginRespDTO» */
export interface BizResponseManageUserLoginRespDTO {
  code?: string;
  data?: ManageUserLoginRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«ManageUserRespDTO» */
export interface BizResponseManageUserRespDTO {
  code?: string;
  data?: ManageUserRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«Map«long,CustomerDistributeQueryRespDTO»» */
export interface BizResponseMapLongCustomerDistributeQueryRespDTO {
  code?: string;
  data?: Record<string, CustomerDistributeQueryRespDTO>;
  msg?: string;
  success?: boolean;
}

/** BizResponse«Map«string,List«DepartmentDetailRespDTO»»» */
export interface BizResponseMapStringListDepartmentDetailRespDTO {
  code?: string;
  data?: Record<string, DepartmentDetailRespDTO[]>;
  msg?: string;
  success?: boolean;
}

/** BizResponse«Map«string,List«string»»» */
export interface BizResponseMapStringListString {
  code?: string;
  data?: Record<string, string[]>;
  msg?: string;
  success?: boolean;
}

/** BizResponse«NotifyPrivateVO» */
export interface BizResponseNotifyPrivateVO {
  code?: string;
  data?: NotifyPrivateVO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«Page«CustomerDetailManagerRespDTO»» */
export interface BizResponsePageCustomerDetailManagerRespDTO {
  code?: string;
  data?: PageCustomerDetailManagerRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«Page«CustomerDetailRespDTO»» */
export interface BizResponsePageCustomerDetailRespDTO {
  code?: string;
  data?: PageCustomerDetailRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«Page«CustomerGoogle对象»» */
export interface BizResponsePageCustomerGoogle {
  code?: string;
  data?: PageCustomerGoogle;
  msg?: string;
  success?: boolean;
}

/** BizResponse«Page«CustomerItemRespDTO»» */
export interface BizResponsePageCustomerItemRespDTO {
  code?: string;
  data?: PageCustomerItemRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«Page«CustomerPageDTO»» */
export interface BizResponsePageCustomerPageDTO {
  code?: string;
  data?: PageCustomerPageDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«Page«CustomerShopPageDTO»» */
export interface BizResponsePageCustomerShopPageDTO {
  code?: string;
  data?: PageCustomerShopPageDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«Page«CustomerShopRespDTO»» */
export interface BizResponsePageCustomerShopRespDTO {
  code?: string;
  data?: PageCustomerShopRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«Page«CustomerShopWmsRespDTO»» */
export interface BizResponsePageCustomerShopWmsRespDTO {
  code?: string;
  data?: PageCustomerShopWmsRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«Page«DeclarationWhitelistRespDTO»» */
export interface BizResponsePageDeclarationWhitelistRespDTO {
  code?: string;
  data?: PageDeclarationWhitelistRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«Page«DepartmentDetailRespDTO»» */
export interface BizResponsePageDepartmentDetailRespDTO {
  code?: string;
  data?: PageDepartmentDetailRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«Page«FrogArticleRespDTO»» */
export interface BizResponsePageFrogArticleRespDTO {
  code?: string;
  data?: PageFrogArticleRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«Page«HotFrogArticleRespDTO»» */
export interface BizResponsePageHotFrogArticleRespDTO {
  code?: string;
  data?: PageHotFrogArticleRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«Page«ManageBannerRespDTO»» */
export interface BizResponsePageManageBannerRespDTO {
  code?: string;
  data?: PageManageBannerRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«Page«ManageFrogArticleRespDTO»» */
export interface BizResponsePageManageFrogArticleRespDTO {
  code?: string;
  data?: PageManageFrogArticleRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«Page«ManageMenuRespDTO»» */
export interface BizResponsePageManageMenuRespDTO {
  code?: string;
  data?: PageManageMenuRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«Page«ManageUserRespDTO»» */
export interface BizResponsePageManageUserRespDTO {
  code?: string;
  data?: PageManageUserRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«Page«NotifyPrivateVO»» */
export interface BizResponsePageNotifyPrivateVO {
  code?: string;
  data?: PageNotifyPrivateVO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«Page«NotifyPublicVO»» */
export interface BizResponsePageNotifyPublicVO {
  code?: string;
  data?: PageNotifyPublicVO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«Page«QueryCustomerDistributeRespDTO»» */
export interface BizResponsePageQueryCustomerDistributeRespDTO {
  code?: string;
  data?: PageQueryCustomerDistributeRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«Page«ShopTaxDeductionAccountRespDTO»» */
export interface BizResponsePageShopTaxDeductionAccountRespDTO {
  code?: string;
  data?: PageShopTaxDeductionAccountRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«Page«TakeUserRespDTO»» */
export interface BizResponsePageTakeUserRespDTO {
  code?: string;
  data?: PageTakeUserRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«RegisterPageImageDTO» */
export interface BizResponseRegisterPageImageDTO {
  code?: string;
  data?: RegisterPageImageDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«SearchResultDTO» */
export interface BizResponseSearchResultDTO {
  code?: string;
  data?: SearchResultDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«ShopAdditionAddressRespDTO» */
export interface BizResponseShopAdditionAddressRespDTO {
  code?: string;
  data?: ShopAdditionAddressRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«ShopAdditionDetailRespDTO» */
export interface BizResponseShopAdditionDetailRespDTO {
  code?: string;
  data?: ShopAdditionDetailRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«ShopAdditionWashedLabelRespDTO» */
export interface BizResponseShopAdditionWashedLabelRespDTO {
  code?: string;
  data?: ShopAdditionWashedLabelRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«ShopClearanceAddressRespDTO» */
export interface BizResponseShopClearanceAddressRespDTO {
  code?: string;
  data?: ShopClearanceAddressRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«ShopOverviewRespDTO» */
export interface BizResponseShopOverviewRespDTO {
  code?: string;
  data?: ShopOverviewRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«ShopPurchaseRespDTO» */
export interface BizResponseShopPurchaseRespDTO {
  code?: string;
  data?: ShopPurchaseRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«ShopShippingAddressRespDTO» */
export interface BizResponseShopShippingAddressRespDTO {
  code?: string;
  data?: ShopShippingAddressRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«ShopTaxDeductionAccountRespDTO» */
export interface BizResponseShopTaxDeductionAccountRespDTO {
  code?: string;
  data?: ShopTaxDeductionAccountRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«SignUpRespDTO» */
export interface BizResponseSignUpRespDTO {
  code?: string;
  data?: SignUpRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«TakeUserRespDTO» */
export interface BizResponseTakeUserRespDTO {
  code?: string;
  data?: TakeUserRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«TermConfigDTO» */
export interface BizResponseTermConfigDTO {
  code?: string;
  data?: TermConfigDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«UserManagerDetailRespDTO» */
export interface BizResponseUserManagerDetailRespDTO {
  code?: string;
  data?: UserManagerDetailRespDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«VideoActivityDTO» */
export interface BizResponseVideoActivityDTO {
  code?: string;
  /** 直播间对象 */
  data?: VideoActivityDTO;
  msg?: string;
  success?: boolean;
}

/** BizResponse«Void» */
export interface BizResponseVoid {
  code?: string;
  msg?: string;
  success?: boolean;
}

/** BizResponse«boolean» */
export interface BizResponseBoolean {
  code?: string;
  data?: boolean;
  msg?: string;
  success?: boolean;
}

/** BizResponse«int» */
export interface BizResponseInt {
  code?: string;
  /** @format int32 */
  data?: number;
  msg?: string;
  success?: boolean;
}

/** BizResponse«long» */
export interface BizResponseLong {
  code?: string;
  /** @format int64 */
  data?: number;
  msg?: string;
  success?: boolean;
}

/** BizResponse«object» */
export interface BizResponseObject {
  code?: string;
  data?: object;
  msg?: string;
  success?: boolean;
}

/** BizResponse«string» */
export interface BizResponseString {
  code?: string;
  data?: string;
  msg?: string;
  success?: boolean;
}

/**
 * CustomerGoogle对象
 * 客户谷歌表
 */
export interface CustomerGoogle {
  /** @example "yyyy-MM-dd HH:mm:ss" */
  createTime?: string;
  createrId?: string;
  /** @format int64 */
  customerGooleId?: number;
  /** @format int32 */
  customerId?: number;
  developerCredentials?: string;
  googleId?: string;
  googleUrl?: string;
  managerId?: string;
  managerName?: string;
  remark?: string;
  /** @format int32 */
  status?: number;
  unificationCustomerFullName?: string;
  /** @example "yyyy-MM-dd HH:mm:ss" */
  updateTime?: string;
}

/**
 * DingCompany对象
 * 公司钉钉信息表
 */
export interface DingCompany {
  agentId?: string;
  appKey?: string;
  appSecret?: string;
  corpId?: string;
  /** @format date-time */
  createTime?: string;
  deleted?: string;
  dingCompanyId?: string;
  leader?: string;
  name?: string;
  /** @format date-time */
  updateTime?: string;
}

/** Map«long,CustomerDistributeQueryRespDTO» */
export type MapLongCustomerDistributeQueryRespDTO = Record<string, CustomerDistributeQueryRespDTO>;

/** Map«string,List«DepartmentDetailRespDTO»» */
export type MapStringListDepartmentDetailRespDTO = Record<string, any>;

/** Map«string,List«string»» */
export type MapStringListString = Record<string, any>;

/**
 * MaterialPrice对象
 * 国际料金表
 */
export interface MaterialPrice {
  /** 创建时间 */
  createTime?: string;
  /** @format int64 */
  materialPriceId?: number;
  /** 名称 */
  name?: string;
  stationCode?: string;
  /** 显示位置 */
  systemSource?: string;
  /** 文件类型 */
  type?: string;
  /** 更新时间 */
  updateTime?: string;
  /** 操作人 */
  updateUser?: string;
  /** 链接 */
  url?: string;
}

/** Page«CustomerDetailManagerRespDTO» */
export interface PageCustomerDetailManagerRespDTO {
  countId?: string;
  /** @format int64 */
  current?: number;
  hitCount?: boolean;
  /** @format int64 */
  maxLimit?: number;
  optimizeCountSql?: boolean;
  orders?: OrderItem[];
  /** @format int64 */
  pages?: number;
  records?: CustomerDetailManagerRespDTO[];
  searchCount?: boolean;
  /** @format int64 */
  size?: number;
  /** @format int64 */
  total?: number;
}

/** Page«CustomerDetailRespDTO» */
export interface PageCustomerDetailRespDTO {
  countId?: string;
  /** @format int64 */
  current?: number;
  hitCount?: boolean;
  /** @format int64 */
  maxLimit?: number;
  optimizeCountSql?: boolean;
  orders?: OrderItem[];
  /** @format int64 */
  pages?: number;
  records?: CustomerDetailRespDTO[];
  searchCount?: boolean;
  /** @format int64 */
  size?: number;
  /** @format int64 */
  total?: number;
}

/** Page«CustomerGoogle对象» */
export interface PageCustomerGoogle {
  countId?: string;
  /** @format int64 */
  current?: number;
  hitCount?: boolean;
  /** @format int64 */
  maxLimit?: number;
  optimizeCountSql?: boolean;
  orders?: OrderItem[];
  /** @format int64 */
  pages?: number;
  records?: CustomerGoogle[];
  searchCount?: boolean;
  /** @format int64 */
  size?: number;
  /** @format int64 */
  total?: number;
}

/** Page«CustomerItemRespDTO» */
export interface PageCustomerItemRespDTO {
  countId?: string;
  /** @format int64 */
  current?: number;
  hitCount?: boolean;
  /** @format int64 */
  maxLimit?: number;
  optimizeCountSql?: boolean;
  orders?: OrderItem[];
  /** @format int64 */
  pages?: number;
  records?: CustomerItemRespDTO[];
  searchCount?: boolean;
  /** @format int64 */
  size?: number;
  /** @format int64 */
  total?: number;
}

/** Page«CustomerPageDTO» */
export interface PageCustomerPageDTO {
  countId?: string;
  /** @format int64 */
  current?: number;
  hitCount?: boolean;
  /** @format int64 */
  maxLimit?: number;
  optimizeCountSql?: boolean;
  orders?: OrderItem[];
  /** @format int64 */
  pages?: number;
  records?: CustomerPageDTO[];
  searchCount?: boolean;
  /** @format int64 */
  size?: number;
  /** @format int64 */
  total?: number;
}

/** Page«CustomerShopPageDTO» */
export interface PageCustomerShopPageDTO {
  countId?: string;
  /** @format int64 */
  current?: number;
  hitCount?: boolean;
  /** @format int64 */
  maxLimit?: number;
  optimizeCountSql?: boolean;
  orders?: OrderItem[];
  /** @format int64 */
  pages?: number;
  records?: CustomerShopPageDTO[];
  searchCount?: boolean;
  /** @format int64 */
  size?: number;
  /** @format int64 */
  total?: number;
}

/** Page«CustomerShopRespDTO» */
export interface PageCustomerShopRespDTO {
  countId?: string;
  /** @format int64 */
  current?: number;
  hitCount?: boolean;
  /** @format int64 */
  maxLimit?: number;
  optimizeCountSql?: boolean;
  orders?: OrderItem[];
  /** @format int64 */
  pages?: number;
  records?: CustomerShopRespDTO[];
  searchCount?: boolean;
  /** @format int64 */
  size?: number;
  /** @format int64 */
  total?: number;
}

/** Page«CustomerShopWmsRespDTO» */
export interface PageCustomerShopWmsRespDTO {
  countId?: string;
  /** @format int64 */
  current?: number;
  hitCount?: boolean;
  /** @format int64 */
  maxLimit?: number;
  optimizeCountSql?: boolean;
  orders?: OrderItem[];
  /** @format int64 */
  pages?: number;
  records?: CustomerShopWmsRespDTO[];
  searchCount?: boolean;
  /** @format int64 */
  size?: number;
  /** @format int64 */
  total?: number;
}

/** Page«DeclarationWhitelistRespDTO» */
export interface PageDeclarationWhitelistRespDTO {
  countId?: string;
  /** @format int64 */
  current?: number;
  hitCount?: boolean;
  /** @format int64 */
  maxLimit?: number;
  optimizeCountSql?: boolean;
  orders?: OrderItem[];
  /** @format int64 */
  pages?: number;
  records?: DeclarationWhitelistRespDTO[];
  searchCount?: boolean;
  /** @format int64 */
  size?: number;
  /** @format int64 */
  total?: number;
}

/** Page«DepartmentDetailRespDTO» */
export interface PageDepartmentDetailRespDTO {
  countId?: string;
  /** @format int64 */
  current?: number;
  hitCount?: boolean;
  /** @format int64 */
  maxLimit?: number;
  optimizeCountSql?: boolean;
  orders?: OrderItem[];
  /** @format int64 */
  pages?: number;
  records?: DepartmentDetailRespDTO[];
  searchCount?: boolean;
  /** @format int64 */
  size?: number;
  /** @format int64 */
  total?: number;
}

/** Page«FrogArticleRespDTO» */
export interface PageFrogArticleRespDTO {
  countId?: string;
  /** @format int64 */
  current?: number;
  hitCount?: boolean;
  /** @format int64 */
  maxLimit?: number;
  optimizeCountSql?: boolean;
  orders?: OrderItem[];
  /** @format int64 */
  pages?: number;
  records?: FrogArticleRespDTO[];
  searchCount?: boolean;
  /** @format int64 */
  size?: number;
  /** @format int64 */
  total?: number;
}

/** Page«HotFrogArticleRespDTO» */
export interface PageHotFrogArticleRespDTO {
  countId?: string;
  /** @format int64 */
  current?: number;
  hitCount?: boolean;
  /** @format int64 */
  maxLimit?: number;
  optimizeCountSql?: boolean;
  orders?: OrderItem[];
  /** @format int64 */
  pages?: number;
  records?: HotFrogArticleRespDTO[];
  searchCount?: boolean;
  /** @format int64 */
  size?: number;
  /** @format int64 */
  total?: number;
}

/** Page«ManageBannerRespDTO» */
export interface PageManageBannerRespDTO {
  countId?: string;
  /** @format int64 */
  current?: number;
  hitCount?: boolean;
  /** @format int64 */
  maxLimit?: number;
  optimizeCountSql?: boolean;
  orders?: OrderItem[];
  /** @format int64 */
  pages?: number;
  records?: ManageBannerRespDTO[];
  searchCount?: boolean;
  /** @format int64 */
  size?: number;
  /** @format int64 */
  total?: number;
}

/** Page«ManageFrogArticleRespDTO» */
export interface PageManageFrogArticleRespDTO {
  countId?: string;
  /** @format int64 */
  current?: number;
  hitCount?: boolean;
  /** @format int64 */
  maxLimit?: number;
  optimizeCountSql?: boolean;
  orders?: OrderItem[];
  /** @format int64 */
  pages?: number;
  records?: ManageFrogArticleRespDTO[];
  searchCount?: boolean;
  /** @format int64 */
  size?: number;
  /** @format int64 */
  total?: number;
}

/** Page«ManageMenuRespDTO» */
export interface PageManageMenuRespDTO {
  countId?: string;
  /** @format int64 */
  current?: number;
  hitCount?: boolean;
  /** @format int64 */
  maxLimit?: number;
  optimizeCountSql?: boolean;
  orders?: OrderItem[];
  /** @format int64 */
  pages?: number;
  records?: ManageMenuRespDTO[];
  searchCount?: boolean;
  /** @format int64 */
  size?: number;
  /** @format int64 */
  total?: number;
}

/** Page«ManageUserRespDTO» */
export interface PageManageUserRespDTO {
  countId?: string;
  /** @format int64 */
  current?: number;
  hitCount?: boolean;
  /** @format int64 */
  maxLimit?: number;
  optimizeCountSql?: boolean;
  orders?: OrderItem[];
  /** @format int64 */
  pages?: number;
  records?: ManageUserRespDTO[];
  searchCount?: boolean;
  /** @format int64 */
  size?: number;
  /** @format int64 */
  total?: number;
}

/** Page«NotifyPrivateVO» */
export interface PageNotifyPrivateVO {
  countId?: string;
  /** @format int64 */
  current?: number;
  hitCount?: boolean;
  /** @format int64 */
  maxLimit?: number;
  optimizeCountSql?: boolean;
  orders?: OrderItem[];
  /** @format int64 */
  pages?: number;
  records?: NotifyPrivateVO[];
  searchCount?: boolean;
  /** @format int64 */
  size?: number;
  /** @format int64 */
  total?: number;
}

/** Page«NotifyPublicVO» */
export interface PageNotifyPublicVO {
  countId?: string;
  /** @format int64 */
  current?: number;
  hitCount?: boolean;
  /** @format int64 */
  maxLimit?: number;
  optimizeCountSql?: boolean;
  orders?: OrderItem[];
  /** @format int64 */
  pages?: number;
  records?: NotifyPublicVO[];
  searchCount?: boolean;
  /** @format int64 */
  size?: number;
  /** @format int64 */
  total?: number;
}

/** Page«QueryCustomerDistributeRespDTO» */
export interface PageQueryCustomerDistributeRespDTO {
  countId?: string;
  /** @format int64 */
  current?: number;
  hitCount?: boolean;
  /** @format int64 */
  maxLimit?: number;
  optimizeCountSql?: boolean;
  orders?: OrderItem[];
  /** @format int64 */
  pages?: number;
  records?: QueryCustomerDistributeRespDTO[];
  searchCount?: boolean;
  /** @format int64 */
  size?: number;
  /** @format int64 */
  total?: number;
}

/** Page«ShopTaxDeductionAccountRespDTO» */
export interface PageShopTaxDeductionAccountRespDTO {
  countId?: string;
  /** @format int64 */
  current?: number;
  hitCount?: boolean;
  /** @format int64 */
  maxLimit?: number;
  optimizeCountSql?: boolean;
  orders?: OrderItem[];
  /** @format int64 */
  pages?: number;
  records?: ShopTaxDeductionAccountRespDTO[];
  searchCount?: boolean;
  /** @format int64 */
  size?: number;
  /** @format int64 */
  total?: number;
}

/** Page«TakeUserRespDTO» */
export interface PageTakeUserRespDTO {
  countId?: string;
  /** @format int64 */
  current?: number;
  hitCount?: boolean;
  /** @format int64 */
  maxLimit?: number;
  optimizeCountSql?: boolean;
  orders?: OrderItem[];
  /** @format int64 */
  pages?: number;
  records?: TakeUserRespDTO[];
  searchCount?: boolean;
  /** @format int64 */
  size?: number;
  /** @format int64 */
  total?: number;
}

/** 手机号登录请求DTO */
export interface _DTO {
  /** @format int32 */
  buType?: number;
  customerMobile?: string;
  /** 国际区号 */
  globalRoaming?: string;
  /** @format int32 */
  platformType?: number;
  /** 三方平台用户账号,可以理解为唯一标识 */
  thirdCustomerId?: string;
  /** 三方平台用户名称 */
  thirdCustomerName?: string;
  /** 三方平台用户邮箱 */
  thirdEmail?: string;
  /** 三方平台标识 */
  thirdPlatform?: string;
  verificationCode?: string;
}

/** 绑定邮箱请求DTO */
export interface DTO2 {
  /** 新的邮箱 */
  newCustomerEmail?: string;
  /** 邮箱验证码 */
  verificationCode?: string;
}

/** 请求验证码DTO */
export interface DTO3 {
  /**
   * 1注册 2重置密码 3登录 4 修改手机号
   * @format int32
   */
  bizType?: number;
  /** 国家code */
  countryPlaceCode?: string;
  /** 用户手机号 */
  customerMobile?: string;
  /** 国际区号 */
  globalRoaming?: string;
  /** 语言代码 */
  langcode?: string;
  /** 人机校验code,不能为空 */
  recaptchaCode?: string;
}

import axios, { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || "//master-gateway.theckb.com/customer",
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => response.data);
  };
}

/**
 * @title API文档
 * @version v1.0.0
 * @baseUrl //master-gateway.theckb.com/customer
 * @contact 史尼芙 (https://www.taobaockb.com/)
 *
 * 史尼芙API文档
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  base = {
    /**
     * No description
     *
     * @tags 官网接口
     * @name FeedbackAddFeedbackInfo
     * @summary 保存客户信息
     * @request POST:/base/feedback/addFeedbackInfo
     */
    feedbackAddFeedbackInfo: (feedbackInfoReqDTO: FeedbackInfoReqDTO, params: RequestParams = {}) =>
      this.request<BizResponseBoolean, any>({
        path: `/base/feedback/addFeedbackInfo`,
        method: "POST",
        body: feedbackInfoReqDTO,
        type: ContentType.Json,
        ...params,
      }),
  };
}
