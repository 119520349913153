
/*
 * @Author: shiguang
 * @Date: 2024-03-12 11:55:19
 * @LastEditors: shiguang
 * @LastEditTime: 2024-03-14 21:19:12
 * @Description:
 */
import { createStore } from 'jotai';
import { Lang, getLang } from '@/i18n/i18n';
import { ENUM_SYSTEM_LANGUAGE, atomSystemLanguage } from './atomSystemLanguage';

export const myStore = createStore();
// const countAtom = atom(0)
// myStore.set(countAtom, 1)

myStore.set(atomSystemLanguage, getLang() === 'en_GB' ? ENUM_SYSTEM_LANGUAGE.ENGLISH : ENUM_SYSTEM_LANGUAGE.CHINESE);

myStore.sub(atomSystemLanguage, () => {

    const systemLanguage = myStore.get(atomSystemLanguage);
    if (systemLanguage === ENUM_SYSTEM_LANGUAGE.ENGLISH) {
        window.localStorage.setItem('web_site_lang', Lang.en_GB);
    } else {
        window.localStorage.setItem('web_site_lang', Lang.zh_CN);
    }
    const hash = window.location.hash;
    setTimeout(() => {
        Array.from(document.querySelectorAll('video')).forEach(item => {
            try {
                item.play();
            } catch (error) {
                console.log(error);
            }
        });
        window.fullpage_api?.reBuild?.();
        window.location.hash = hash;
    }, 100);
});