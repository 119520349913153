/*
 * @Author: liuliangliang liuliangliang@sniffgroup.com
 * @Date: 2024-03-06 09:52:25
 * @LastEditors: liuliangliang liuliangliang@sniffgroup.com
 * @LastEditTime: 2024-03-06 10:40:36
 * @FilePath: /ckb-official-website-fed/src/common/Scrollbar/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';
import './index.scss';

interface ScrollbarProps {
  length: number;
  currentPageSize: number;
  pageSize: number;
}

const Scrollbar = (props: ScrollbarProps) => {
    const { length, currentPageSize, pageSize } = props;
    const scrollItem = Array.from({ length }, (_, i) => i);
    return (
        <div className="scrollbar">
            {
                scrollItem.map((item, index) => {
                    return (
                        <div
                            className={item === 0 ? 'targetScroll' : ''}
                            key={index}
                            style={{
                                transform: `translateY(calc(${currentPageSize * 100 / pageSize}*var(--vh)))`
                            }}
                        />
                    );


                })}

        </div>
    );


};

export default Scrollbar;