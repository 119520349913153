import { strings } from '@/i18n/i18n';
import { ENUM_DEVICE_TYPE } from '@/hooks/useDeviceType';
import { ENUM_SYSTEM_LANGUAGE } from '@/atom/atomSystemLanguage';

export interface CultureContentItem {
  /** 标题 */
  title: () => string;
  /** 描述 */
  desc: () => string;
  /** 背景图 */
  imgUrlPC?: string;
  imgUrlH5?: string;
  imgUrlIpad?: string;
  dataTitle1?: () => string;
  dataTitle2?: () => string;
  dataNum1?: () => string;
  dataNum2?: () => string;
  btn?: string;
  sliceString?: Object;
}
export const maskImgPC = 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_pc_mask.png';
export const maskImgIpad = 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_pc_mask.png';
export const maskImgH5 = 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_h5_mask.png';
export const cultureContent: CultureContentItem[] = [
    {
        title: () => strings.t('产品出海'),
        desc: () => strings.t('随着我国产能不断提升，国内市场日趋饱和，为寻求进一步步的发展，开拓市场，产品出海成为各企业的必要选择。但产品出海往往会遭遇销售网络阻塞，文化差异过大、贸易壁垒高筑等多方方挑战。 \nSNIFF集团多年来深耕跨境电商领域，建立了专业完备的海每内外团队，通过专业化的作业机制，帮助各类企业完成产品出海。目前已涵盖服装、日用百货、户外产品、运动产品、家具装饰品品等各类产品。SNIFF致力于帮助中国优秀产品走向海外，平均每月向海外输出货物超2000万件!'),
        imgUrlPC: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_pc_brand.png',
        dataTitle1: () => strings.t('月出口商品数'),
        dataTitle2: () => strings.t('可寄往多国'),
        dataNum1: () => strings.t('1500万件+'),
        dataNum2: () => '50+',
        sliceString: {
            [ENUM_DEVICE_TYPE.PC]: {
                [ENUM_SYSTEM_LANGUAGE.CHINESE]: 79,
                [ENUM_SYSTEM_LANGUAGE.ENGLISH]: 155
            },
            [ENUM_DEVICE_TYPE.MOBILE]: {
                [ENUM_SYSTEM_LANGUAGE.CHINESE]: 50,
                [ENUM_SYSTEM_LANGUAGE.ENGLISH]: 102
            },
            [ENUM_DEVICE_TYPE.IPAD]: {
                [ENUM_SYSTEM_LANGUAGE.CHINESE]: 87,
                [ENUM_SYSTEM_LANGUAGE.ENGLISH]: 165
            }
        }
    },
    {
        title: () => strings.t('供应链出海'),
        desc: () => strings.t('SNIFF合作多家了供应链工厂，强大的供应链优势，让SNIFF可以更好的满足客户订购、采购、OEM需求，为客户提供高效文、快速、可靠的服务。 \n产能过剩和国内市场的饱和让许多产品滞留在仓库中，而无法流通、无法被使用。SNIFF注重资源的合理利用用，因此我们将供应链出海作为一个重要的战略方向。 \n帮助供应链出海在增加贸易产值的同时，可以让我们的资源在合适的地方发挥作用，避免产品囤积，造成资源浪费。'),
        imgUrlPC: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_pc_supply.png',
        dataTitle1: () => strings.t('系统接入供应链数'),
        dataTitle2: () => strings.t('注册用户数'),
        dataNum1: () => strings.t('20万+'),
        dataNum2: () => strings.t('10万+'),
        sliceString: {
            [ENUM_DEVICE_TYPE.PC]: {
                [ENUM_SYSTEM_LANGUAGE.CHINESE]: 82,
                [ENUM_SYSTEM_LANGUAGE.ENGLISH]: 146
            },
            [ENUM_DEVICE_TYPE.MOBILE]: {
                [ENUM_SYSTEM_LANGUAGE.CHINESE]: 55,
                [ENUM_SYSTEM_LANGUAGE.ENGLISH]: 102
            },
            [ENUM_DEVICE_TYPE.IPAD]: {
                [ENUM_SYSTEM_LANGUAGE.CHINESE]: 95,
                [ENUM_SYSTEM_LANGUAGE.ENGLISH]: 165
            }
        }
    },
    {
        title: () => strings.t('软件出海'),
        desc: () => strings.t('SNIFF GROUP凭借丰富的海内外经验，助力国内优秀App迈向全球市场。 \nSNIFF GROUP与ERP厂商合作，依靠大数据为跨境卖家提供精确选品、精准营销，精细运营的发展方案。通过大数据能精准的捕捉行业变化，准确定位优质竞品，深度剖析市场流量表现，从而帮助跨境卖家构建更科学立体的运营体系。这是跨境卖家突破海外发展瓶颈，实现自身增长的重要力量。 \nSNIFF GROUP多年来不断帮助更多的国内优秀App走向世界，坚持依靠科技的力量，推动行业发展，让世界贸易变得更简单。'),
        imgUrlPC: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_pc_soft.png',
        dataTitle1: () => strings.t('系统接入供应链数'),
        dataTitle2: () => strings.t('注册用户数'),
        dataNum1: () => strings.t('20万+'),
        dataNum2: () => strings.t('10万+'),
        sliceString: {
            [ENUM_DEVICE_TYPE.PC]: {
                [ENUM_SYSTEM_LANGUAGE.CHINESE]: 86,
                [ENUM_SYSTEM_LANGUAGE.ENGLISH]: 150
            },
            [ENUM_DEVICE_TYPE.MOBILE]: {
                [ENUM_SYSTEM_LANGUAGE.CHINESE]: 58,
                [ENUM_SYSTEM_LANGUAGE.ENGLISH]: 98
            },
            [ENUM_DEVICE_TYPE.IPAD]: {
                [ENUM_SYSTEM_LANGUAGE.CHINESE]: 95,
                [ENUM_SYSTEM_LANGUAGE.ENGLISH]: 170
            }
        }
    },
    {
        title: () => strings.t('品牌出海'),
        desc: () => strings.t('比起高性价比的产品，中国品牌的出海之路走的尤为艰难。面对着海外品牌认知度低，品牌声量小等多方困境。 \nSNIFF GROUP长期以来，积极帮助国产品牌走出国门，完成海外品牌构建、品牌经营营和品牌推广。至今已帮助各品类数百个品牌完成出海，并长期为品牌的海外发展提供支持。 同时，为避免海外用户陷于低价、低质、抄袭产品的漩涡中中，SNIFF GROUP更鼓励海外客户分销供货稳定、具有品质保证、原创设计的品牌商品。 \n目前已合作涵服饰、箱包、户外用品等各类目在内为，共计超过200多家中国品牌。成功助力日本电商客户达成一年50亿日元销售额、一年5000万的采购额的瞩目成就。'),
        imgUrlPC: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_pc_product.png',
        dataTitle1: () => strings.t('助力中国品牌'),
        dataTitle2: () => strings.t('可寄往多国'),
        dataNum1: () => '200+',
        dataNum2: () => '50+',
        sliceString: {
            [ENUM_DEVICE_TYPE.PC]: {
                [ENUM_SYSTEM_LANGUAGE.CHINESE]: 82,
                [ENUM_SYSTEM_LANGUAGE.ENGLISH]: 155
            },
            [ENUM_DEVICE_TYPE.MOBILE]: {
                [ENUM_SYSTEM_LANGUAGE.CHINESE]: 52,
                [ENUM_SYSTEM_LANGUAGE.ENGLISH]: 112
            },
            [ENUM_DEVICE_TYPE.IPAD]: {
                [ENUM_SYSTEM_LANGUAGE.CHINESE]: 92,
                [ENUM_SYSTEM_LANGUAGE.ENGLISH]: 180
            }
        }
    }];
/** 文化模块-内容 */
export const getCultureContent = (): CultureContentItem[] => {
    return [
        {
            title: () => strings.t('产品出海'),
            desc: () => strings.t('随着我国产能不断提升，国内市场日趋饱和，为寻求进一步步的发展，开拓市场，产品出海成为各企业的必要选择。但产品出海往往会遭遇销售网络阻塞，文化差异过大、贸易壁垒高筑等多方方挑战。 \nSNIFF集团多年来深耕跨境电商领域，建立了专业完备的海每内外团队，通过专业化的作业机制，帮助各类企业完成产品出海。目前已涵盖服装、日用百货、户外产品、运动产品、家具装饰品品等各类产品。SNIFF致力于帮助中国优秀产品走向海外，平均每月向海外输出货物超2000万件!'),
            imgUrlPC: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_pc_brand.png',
            dataTitle1: () => strings.t('月出口商品数'),
            dataTitle2: () => strings.t('可寄往多国'),
            dataNum1: () => strings.t('1500万件+'),
            dataNum2: () => '50+',
            sliceString: {
                [ENUM_DEVICE_TYPE.PC]: {
                    [ENUM_SYSTEM_LANGUAGE.CHINESE]: 79,
                    [ENUM_SYSTEM_LANGUAGE.ENGLISH]: 155
                },
                [ENUM_DEVICE_TYPE.MOBILE]: {
                    [ENUM_SYSTEM_LANGUAGE.CHINESE]: 50,
                    [ENUM_SYSTEM_LANGUAGE.ENGLISH]: 102
                },
                [ENUM_DEVICE_TYPE.IPAD]: {
                    [ENUM_SYSTEM_LANGUAGE.CHINESE]: 87,
                    [ENUM_SYSTEM_LANGUAGE.ENGLISH]: 165
                }
            }
        },
        {
            title: () => strings.t('供应链出海'),
            desc: () => strings.t('SNIFF合作多家了供应链工厂，强大的供应链优势，让SNIFF可以更好的满足客户订购、采购、OEM需求，为客户提供高效文、快速、可靠的服务。 \n产能过剩和国内市场的饱和让许多产品滞留在仓库中，而无法流通、无法被使用。SNIFF注重资源的合理利用用，因此我们将供应链出海作为一个重要的战略方向。 \n帮助供应链出海在增加贸易产值的同时，可以让我们的资源在合适的地方发挥作用，避免产品囤积，造成资源浪费。'),
            imgUrlPC: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_pc_supply.png',
            dataTitle1: () => strings.t('系统接入供应链数'),
            dataTitle2: () => strings.t('注册用户数'),
            dataNum1: () => strings.t('20万+'),
            dataNum2: () => strings.t('10万+'),
            sliceString: {
                [ENUM_DEVICE_TYPE.PC]: {
                    [ENUM_SYSTEM_LANGUAGE.CHINESE]: 82,
                    [ENUM_SYSTEM_LANGUAGE.ENGLISH]: 146
                },
                [ENUM_DEVICE_TYPE.MOBILE]: {
                    [ENUM_SYSTEM_LANGUAGE.CHINESE]: 55,
                    [ENUM_SYSTEM_LANGUAGE.ENGLISH]: 102
                },
                [ENUM_DEVICE_TYPE.IPAD]: {
                    [ENUM_SYSTEM_LANGUAGE.CHINESE]: 95,
                    [ENUM_SYSTEM_LANGUAGE.ENGLISH]: 165
                }
            }
        },
        {
            title: () => strings.t('软件出海'),
            desc: () => strings.t('SNIFF GROUP凭借丰富的海内外经验，助力国内优秀App迈向全球市场。 \nSNIFF GROUP与ERP厂商合作，依靠大数据为跨境卖家提供精确选品、精准营销，精细运营的发展方案。通过大数据能精准的捕捉行业变化，准确定位优质竞品，深度剖析市场流量表现，从而帮助跨境卖家构建更科学立体的运营体系。这是跨境卖家突破海外发展瓶颈，实现自身增长的重要力量。 \nSNIFF GROUP多年来不断帮助更多的国内优秀App走向世界，坚持依靠科技的力量，推动行业发展，让世界贸易变得更简单。'),
            imgUrlPC: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_pc_soft.png',
            dataTitle1: () => strings.t('系统接入供应链数'),
            dataTitle2: () => strings.t('注册用户数'),
            dataNum1: () => strings.t('20万+'),
            dataNum2: () => strings.t('10万+'),
            sliceString: {
                [ENUM_DEVICE_TYPE.PC]: {
                    [ENUM_SYSTEM_LANGUAGE.CHINESE]: 86,
                    [ENUM_SYSTEM_LANGUAGE.ENGLISH]: 150
                },
                [ENUM_DEVICE_TYPE.MOBILE]: {
                    [ENUM_SYSTEM_LANGUAGE.CHINESE]: 58,
                    [ENUM_SYSTEM_LANGUAGE.ENGLISH]: 98
                },
                [ENUM_DEVICE_TYPE.IPAD]: {
                    [ENUM_SYSTEM_LANGUAGE.CHINESE]: 95,
                    [ENUM_SYSTEM_LANGUAGE.ENGLISH]: 170
                }
            }
        },
        {
            title: () => strings.t('品牌出海'),
            desc: () => strings.t('比起高性价比的产品，中国品牌的出海之路走的尤为艰难。面对着海外品牌认知度低，品牌声量小等多方困境。 \nSNIFF GROUP长期以来，积极帮助国产品牌走出国门，完成海外品牌构建、品牌经营营和品牌推广。至今已帮助各品类数百个品牌完成出海，并长期为品牌的海外发展提供支持。 同时，为避免海外用户陷于低价、低质、抄袭产品的漩涡中中，SNIFF GROUP更鼓励海外客户分销供货稳定、具有品质保证、原创设计的品牌商品。 \n目前已合作涵服饰、箱包、户外用品等各类目在内为，共计超过200多家中国品牌。成功助力日本电商客户达成一年50亿日元销售额、一年5000万的采购额的瞩目成就。'),
            imgUrlPC: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_pc_product.png',
            dataTitle1: () => strings.t('助力中国品牌'),
            dataTitle2: () => strings.t('可寄往多国'),
            dataNum1: () => '200+',
            dataNum2: () => '50+',
            sliceString: {
                [ENUM_DEVICE_TYPE.PC]: {
                    [ENUM_SYSTEM_LANGUAGE.CHINESE]: 82,
                    [ENUM_SYSTEM_LANGUAGE.ENGLISH]: 155
                },
                [ENUM_DEVICE_TYPE.MOBILE]: {
                    [ENUM_SYSTEM_LANGUAGE.CHINESE]: 52,
                    [ENUM_SYSTEM_LANGUAGE.ENGLISH]: 112
                },
                [ENUM_DEVICE_TYPE.IPAD]: {
                    [ENUM_SYSTEM_LANGUAGE.CHINESE]: 92,
                    [ENUM_SYSTEM_LANGUAGE.ENGLISH]: 180
                }
            }
        }];
};


export const cultureContent2: CultureContentItem[] = [
    {
        title: () => strings.t('一个有生命力的团队'),
        desc: () => strings.t('一个有文化的团队才称得上是团队，他们拥有共同的目标、愿景、思想、沟通方式、类似的兴趣爱好等等，这样才称得上是有生命力的团队。'),
        imgUrlPC: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_pc_1.png',
        imgUrlH5: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_h5_1.png',
        imgUrlIpad: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_ipad_1.png',
        btn: strings.t('走进SNIFF')
    }];

export const getCultureContent2 = (): CultureContentItem[] => {
    return [
        {
            title: () => strings.t('一个有生命力的团队'),
            desc: () => strings.t('一个有文化的团队才称得上是团队，他们拥有共同的目标、愿景、思想、沟通方式、类似的兴趣爱好等等，这样才称得上是有生命力的团队。'),
            imgUrlPC: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_pc_1.png',
            imgUrlH5: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_h5_1.png',
            imgUrlIpad: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_ipad_1.png',
            btn: strings.t('走进SNIFF')
        }];
};