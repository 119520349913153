/*
 * @Author: yusha
 * @Date: 2023-08-24 10:59:13
 * @LastEditors: liuliangliang liuliangliang@sniffgroup.com
 * @LastEditTime: 2024-03-05 17:33:01
 * @Description: 测试相关路由
 */
import { lazy } from 'react';

const test = [
    {
        path: '/demo',
        title: '测试列表2',
        component: lazy(() => import('../../../container/Culture/Demo')),
    },
    {
        path: '/demo2',
        title: '测试列表2',
        component: lazy(() => import('../../../container/Culture/index')),
    },
];
export default test;