/*
 * @Author: liuliangliang liuliangliang@sniffgroup.com
 * @Date: 2024-03-01 16:08:54
 * @LastEditors: shiguang
 * @LastEditTime: 2024-03-12 21:10:53
 * @FilePath: /ckb-official-website-fed/src/container/Culture/index.tsx
 * @Description: 企业文化
 */
import React, { useEffect, useState } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import { useSetAtom } from 'jotai';
import { useSearchParams } from 'react-router-dom';
import Scrollbar from '@/common/Scrollbar';
import useDeviceType, { ENUM_DEVICE_TYPE } from '@/hooks/useDeviceType';
import { atomLogoTheme, atomMenuTheme } from '@/atom/atomPanelVisible';
import { strings } from '@/i18n/i18n';
import ContactUs from '../../common/ContactUs';
import SectionItem from './components/SectionItem';
import { CultureContentItem } from './config';

const Culture = () => {
    const [currentPageSize, setCurrentPageSize] = useState(0);

    const cultureContent: CultureContentItem[] = [
        {
            title: () => strings.t('一个有生命力的团队'),
            descList: [
                () => strings.t('一个有文化的团队才称得上是团队，他们拥有共同的目标、愿景、思想、沟通方式、类似的兴趣爱好等等，这样才称得上是有生命力的团队。')],

            imgUrlPC: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_pc_1.png',
            imgUrlH5: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_h5_1.png',
            imgUrlIpad: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_ipad_1.png'
        },
        {
            title: () => strings.t('企业愿景'),
            descList: [() => strings.t('成为世界一流的跨境电商综合系统服务商。')],
            imgUrlPC: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_pc_2.png',
            imgUrlH5: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_h5_2.png',
            imgUrlIpad: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_ipad_2.png'
        },
        {
            title: () => strings.t('企业使命'),
            descList: [() => strings.t('让世界贸易变得更简单'), () => strings.t('帮助中国制造走出去，他国制造走进来，实现全球买、全球卖。')],
            imgUrlPC: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_pc_3.png',
            imgUrlH5: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_h5_3.png',
            imgUrlIpad: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_ipad_3.png'
        },
        {
            title: () => strings.t('企业文化'),
            descList: [() => strings.t('感恩 、分享、助力客户、成就员工。')],
            imgUrlPC: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_pc_4.png',
            imgUrlH5: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_h5_4.png',
            imgUrlIpad: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_pc_4.png'
        },
        {
            title: () => strings.t('企业核心能力'),
            descList: [() => strings.t('多语种、智能化、专业性、前瞻性。')],
            imgUrlPC: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_pc_5.png',
            imgUrlH5: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_h5_5.png',
            imgUrlIpad: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_pc_5.png'
        },
        {
            title: () => strings.t('企业核心价值观'),
            descList: [() => strings.t('感恩分享、不断创新、成就客户、贡献社会。')],
            imgUrlPC: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_pc_6.png',
            imgUrlH5: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_h5_6.png',
            imgUrlIpad: 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_bg_pc_6.png'
        }];

    const [pageSize, setPageSize] = useState(cultureContent.length);
    const anchors = Array.from({ length: pageSize }, (_, index) => `page${index}`);

    const { deviveType2 } = useDeviceType();
    const setAtomDrawerFlag = useSetAtom(atomMenuTheme);
    const setAtomDrawerLogo = useSetAtom(atomLogoTheme);
    const [search] = useSearchParams();
    const section = (search.get('section') as string);
    useEffect(() => {
        if (section) {
            // eslint-disable-next-line radix
            window.fullpage_api.silentMoveTo(parseInt(section));
        }
    }, [section]);
    const initFullPage = (state) => {
        console.log(state.destination);

        /** 获取当前页数 */
        if (state.destination) {
            setCurrentPageSize((state.destination.index as number));
            if (deviveType2 === ENUM_DEVICE_TYPE.MOBILE) {
                if (state.destination.index === 6) {
                    setAtomDrawerLogo('#FF5010');
                    setAtomDrawerFlag('black');
                } else {
                    setAtomDrawerLogo('white');
                    setAtomDrawerFlag('white');
                }
            } else {
                if (state.destination.index === 6) {
                    setAtomDrawerLogo('#FF5010');
                    setAtomDrawerFlag('white');
                } else {
                    setAtomDrawerLogo('white');
                    setAtomDrawerFlag('white');
                }
            }
        }
        /** 初始化完成，获取总页数 */
        if (state.initialized) {
            setPageSize(state.sectionCount);
        }
    };
    return (
        <div className="culture">
            <div>
                <ReactFullpage
                    credits={{}}
                    // 每一部分的锚点, 就是url#后面的部分
                    anchors={anchors}
                    // navigation // 是否显示右侧小圆圈
                    // navigationTooltips={anchors} // 鼠标放到小圆圈上显示的提示
                    // 每一个部分你的背景色
                    sectionsColor={['rgba(0,0,0,0)', 'rgba(0,0,0,0)', 'rgba(0,0,0,0)']}
                    onLeave={() => {


                        // 离开某一部分你的回调, 参数分别表示从哪里开 去哪里 方向. 前两者是自定义类型nn, 方向是up或down
                        // console.log("onLeave event", { origin, destination, direction });
                    }} render={({ state, fullpageApi }) => { initFullPage(state);return (
                        <div style={{ width: '100vw', height: '100vh' }}>
                            {cultureContent.map((item) => { return <SectionItem {...item} key={item.title()} />; })}

                            <ContactUs />
                        </div>
                    );
                    }}
                />


                <Scrollbar length={pageSize} currentPageSize={currentPageSize} pageSize={pageSize} />
            </div>
        </div>
    );


};

export default Culture;