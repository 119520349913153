import { strings } from '@/i18n/i18n';
/*
 * @Author: liuliangliang liuliangliang@sniffgroup.com
 * @Date: 2024-03-04 13:18:28
 * @LastEditors: shiguang
 * @LastEditTime: 2024-03-14 21:38:20
 * @FilePath: /ckb-official-website-fed/src/container/JoinUs/config.ts
 * @Description: 加入我们/配置内容
 */

export interface JoinUsContentItem {
  /** 目标 */
  flag?: string;
  /** 标题描述 */
  flagDesc?: string;
  /** 内容 */
  contentTitle?: string;
  /** 内容 */
  contentDesc?: string[];
  /** 文章类型 */
  contentType?: ENUM_CONTENT_TYPE;
  btnText?: string;
  imgUrlH5?: string;
  imgUrlPC?: string;
}
/** 文章类型 */
export enum ENUM_CONTENT_TYPE {
  /** 目标 */
  FLAG = 'flag',
  /** 爱心 */
  LOVE = 'love',
  /** 孝心 */
  SOLICITUDE = 'solicitude',
  /** 健康 */
  HEALTH = 'health',
  /** 开放 */
  OPENNESS = 'openness',
  /** 办公城市 */
  OFFICE_CITY = 'officeCity',
}

/** 简历投递地址 */
export const resumeSubmissionUrl =
'https://s.xinrenxinshi.com/recruitGate/list#/ey=0ebdb0058d4a716b8f15cca6cc385e4089c663';
export const icon_swap_right =
'https://static-s.theckb.com/BusinessMarket/offcialWebsite/swap-right.png';
/** 加入我们-内容 */
export const joinUsContent: JoinUsContentItem[] = [
    {
        flag: strings.t('我们立志成为全球优秀雇佣公司'),
        flagDesc: strings.t(
            '我们的目标：消除办公场地、时间、距离限制、享受工作 与生活平衡的乐趣'
        ),

        contentTitle: strings.t('爱心'),
        contentType: ENUM_CONTENT_TYPE.LOVE,
        imgUrlPC:
  'https://static-s.theckb.com/BusinessMarket/offcialWebsite/joinus_bg_pc_1.png',
        imgUrlH5:
  'https://static-s.theckb.com/BusinessMarket/offcialWebsite/joinus_bg_h5_2.png?xx=111',
        btnText: strings.t('立即投递简历'),
        contentDesc: [
            strings.t('定期发放宠物基金，让铲屎官们轻松陪伴爱宠'),
            strings.t(
                'SNIFF GROUP是一个温暖有爱的公司，我们深知宠物是铲屎官们非常重要的朋友和家人。为了让员工能更轻松的享受爱宠的陪伴，帮助员工照顾宠物，公司提供了专属的宠物福利——“宠物基金”。宠物基金每月按时发放，公司用实际行动为萌宠加餐啦！'
            )]

    },
    {
        flag: strings.t('我们立志成为全球优秀雇佣公司'),
        flagDesc: strings.t(
            '我们的目标：消除办公场地、时间、距离限制、享受工作 与生活平衡的乐趣'
        ),

        contentTitle: strings.t('孝心'),
        imgUrlPC:
  'https://static-s.theckb.com/BusinessMarket/offcialWebsite/joinus_bg_pc_1.png',
        imgUrlH5:
  'https://static-s.theckb.com/BusinessMarket/offcialWebsite/joinus_bg_h5_3.png',
        btnText: strings.t('立即投递简历'),
        contentType: ENUM_CONTENT_TYPE.SOLICITUDE,
        contentDesc: [
            strings.t('年末向员工父母发放慰问基金，感恩她们的付出'),
            strings.t(
                'SNIFF 父母德高，子女良教。SNIFF GROUP在感念每一位员工为公司作出的奉献的同时，也感谢他们的父母为社会和公司培养了诸多优秀人才。对于父母的辛苦付出，SNIFF GROUP感念于心，感恩于行。为父母设立了感恩基金，每年直接向父母打款，以此来表达SNIFF GROUP对父母的尊敬和感谢。'
            )]

    },
    {
        flag: strings.t('我们立志成为全球优秀雇佣公司'),
        flagDesc: strings.t(
            '我们的目标：消除办公场地、时间、距离限制、享受工作 与生活平衡的乐趣'
        ),

        imgUrlPC:
  'https://static-s.theckb.com/BusinessMarket/offcialWebsite/joinus_bg_pc_1.png',
        imgUrlH5:
  'https://static-s.theckb.com/BusinessMarket/offcialWebsite/joinus_bg_h5_4.png',
        btnText: strings.t('立即投递简历'),
        contentTitle: strings.t('健康'),
        contentType: ENUM_CONTENT_TYPE.HEALTH,
        contentDesc: [
            strings.t('定期员工体检，鼓励同学们锻炼健康的体魄'),
            strings.t(
                'SNIFF GROUP非常注重员工的身心健康，健康的身体是优质工作和生活的前提保障。SNIFF GROUP鼓励同学们经常锻炼身体，塑造健康的工作生活习惯。并为全体员工提供年度员工体检，以此来保障员工的身体健康。同时还有旅游基金，年度假期等等福利，让员工有时间放松自己，陪伴家人，更好的平衡工作和生活。'
            )]

    },
    {
        flag: strings.t('我们立志成为全球优秀雇佣公司'),
        flagDesc: strings.t(
            '我们的目标：消除办公场地、时间、距离限制、享受工作 与生活平衡的乐趣'
        ),

        contentTitle: strings.t('开放'),
        btnText: strings.t('立即投递简历'),
        imgUrlPC:
  'https://static-s.theckb.com/BusinessMarket/offcialWebsite/joinus_bg_pc_1.png',
        imgUrlH5:
  'https://static-s.theckb.com/BusinessMarket/offcialWebsite/joinus_bg_h5_5.png',
        contentType: ENUM_CONTENT_TYPE.OPENNESS,
        contentDesc: [
            strings.t('定期语言交流学习，多国家同学碰撞思想火花'),
            strings.t(
                'SNIFF GROUP是一个国际化的公司，拥有多元的文化交流背景，日、韩、泰等多个国家地区的员工一起上班，互相交流学习，共同进步。我们一直以来坚持开放包容的态度，鼓励各国员工积极交流，互相探讨，不断开阔彼此视野。还会定期开放语言交流学习，来帮助同学们了解不同国家的文化，让多国同学们碰撞出更璀璨的思想火花。'
            )]

    },
    {
        flag: strings.t('我们立志成为全球优秀雇佣公司'),
        flagDesc: strings.t(
            '我们的目标：消除办公场地、时间、距离限制、享受工作 与生活平衡的乐趣'
        ),

        btnText: strings.t('立即投递简历'),
        imgUrlPC:
  'https://static-s.theckb.com/BusinessMarket/offcialWebsite/joinus_bg_pc_1.png',
        imgUrlH5: '',
        contentType: ENUM_CONTENT_TYPE.OFFICE_CITY
    }];


interface OfficeCityItem {
  cityName: string;
  cityCode: ENUM_OFFICE_CITY;
}
export enum ENUM_OFFICE_CITY {
  HANGZHOU = 'Hangzhou',
  TOKYO = 'Tokyo',
  DALIAN = 'Dalian',
  GUANGZHOU = 'Guangzhou',
  HONGKONG = 'Hongkong',
  KOREA_GIMPO = 'Korea_Gimpo',
}
/** 办公环境地址 */
export const officeCityList: OfficeCityItem[] = [
    { cityName: strings.t('杭州'), cityCode: ENUM_OFFICE_CITY.HANGZHOU },
    { cityName: strings.t('东京'), cityCode: ENUM_OFFICE_CITY.TOKYO },
    { cityName: strings.t('大连'), cityCode: ENUM_OFFICE_CITY.DALIAN },
    { cityName: strings.t('广州'), cityCode: ENUM_OFFICE_CITY.GUANGZHOU },
    { cityName: strings.t('香港'), cityCode: ENUM_OFFICE_CITY.HONGKONG },
    { cityName: strings.t('韩国金浦'), cityCode: ENUM_OFFICE_CITY.KOREA_GIMPO }];


/** 办公环境地址信息 */
export const MAP_OFFICE_CITY_INFO = new Map([
    [
        ENUM_OFFICE_CITY.HANGZHOU,
        {
            cityName: () => strings.t('杭州'),
            cityDesc: () => [
                strings.t(
                    '杭州自古以来就是我国著名的经济文化中心，流淌千年运的河，滋养出杭州独有的贸易灵魂。'
                ),
                strings.t(
                    '杭州也是SNIFF的起点，是一切有关“让贸易变得更简单”的愿景起航的地方。这里有SNIFF中国供应链部门、日语客户支持部门、跨境电商运营部门、平台运营部门、技术开发分公司、萧山仓储物流分公司、西子精简分公司等核心业务部门，是SNIFF的大本营。'
                ),
                strings.t(
                    '在这座钟灵毓秀的城市里，我们工作、我们生活，我们努力，我们成长，在这里我们和SNIFF一起奋斗，书写出独一无二的贸易，与众不同的你我。SNIFF欢迎你的加入！'
                )],


            cityDescImg: [
                'https://static-s.theckb.com/BusinessMarket/offcialWebsite/hangzhou_1.png',
                'https://static-s.theckb.com/BusinessMarket/offcialWebsite/hangzhou_2.png',
                'https://static-s.theckb.com/BusinessMarket/offcialWebsite/hangzhou_3.png']

        }],


    [
        ENUM_OFFICE_CITY.TOKYO,
        {
            cityName: () => strings.t('东京'),
            cityDesc: () => [
                strings.t(
                    '东京分公司是Sniff的日本办公中心，员工都是更熟悉当地文化的日本籍成员，主要负责与日方各大公司进行合作洽谈，商务对接等工作。'
                ),
                strings.t(
                    '日籍员工可以更顺畅与客户进行交流和沟通，完成大客户的拜访和维护，帮助日本项目顺利推进。对于当地文化环境的了解决定来他们能掌握迅速识别和判断市场动向，掌握第一手资料。日本分公司能迅速洞察当地人们的兴趣变化，并依此来进行日本市场的宣传推广工作。欢迎加入Sniff Group，与世界各地的同事一起交流！'
                )],


            cityDescImg: [
                'https://static-s.theckb.com/BusinessMarket/offcialWebsite/Tokyo_1.png',
                'https://static-s.theckb.com/BusinessMarket/offcialWebsite/Tokyo_2.png',
                'https://static-s.theckb.com/BusinessMarket/offcialWebsite/Tokyo_3.png']

        }],


    [
        ENUM_OFFICE_CITY.DALIAN,
        {
            cityName: () => strings.t('大连'),
            cityDesc: () => [
                strings.t(
                    '大连是一座依山傍海的城市，大连分公司就坐落在这样美丽城市的海边。地址位于辽宁省大连市高新园区万科创智中心C3号楼15楼1506室。'
                ),
                strings.t(
                    '大连培养了众多优秀日语人才，因此我们将大连分公司作为我们的日语客服中心，承接客户售前&售后的客户线上问询或者电话咨询。'
                ),
                strings.t(
                    '大连分公司拥有日本领导层的管理，和日本的培训专员。因此大连的日语客服更加了解日本文化，能够同理客户的需求，可以给日本客户提供日本标准的客服对应质量。'
                ),
                strings.t(
                    '我们珍惜每一位客户，珍惜每一位员工，珍惜每一个来之不易的机会！所以你也珍惜这次的机会哦！'
                )],


            cityDescImg: [
                'https://static-s.theckb.com/BusinessMarket/offcialWebsite/Dalian_1.png',
                'https://static-s.theckb.com/BusinessMarket/offcialWebsite/dalian_2.png',
                'https://static-s.theckb.com/BusinessMarket/offcialWebsite/Dalian_3.png']

        }],


    [
        ENUM_OFFICE_CITY.GUANGZHOU,
        {
            cityName: () => strings.t('广州'),
            cityDesc: () => [
                strings.t(
                    '广州分公司主要负责华南地区供应商的海外供货，并提供质检，仓储，物流等服务。'
                ),
                strings.t(
                    '广州位于珠江口，毗邻香港，地理位置优越，是中国最大的对外贸易口岸之一，具有地理位置优越、交通便利、产品资源丰富等优势。'
                ),
                strings.t(
                    '广州不仅拥有完善的海空陆交通网络，可以方便地将商品运往世界各地；同时还具备丰富的产品资源，产品种类丰富多样，能满足全球各地人们的生产生活需求。因此，广州也成为SNIFF发展跨境事业的新基地！'
                )],


            cityDescImg: [
                'https://static-s.theckb.com/BusinessMarket/offcialWebsite/Guangzhou_1.png']

        }],


    [
        ENUM_OFFICE_CITY.HONGKONG,
        {
            cityName: () => strings.t('香港'),
            cityDesc: () => [
                strings.t(
                    '中国香港是一座高度繁荣的自由港和国际大都市，重要的国际贸易、航运中心和国际创新科技中心。'
                ),
                strings.t(
                    '中国香港是全球少有之“自由港城”，地理位置优越，处于亚洲的中心，交通畅通，货物进出便利，在跨境电商行业的发展上具有得天独厚的优势。'
                ),
                strings.t(
                    '同时香港还具有境内备货方便，外汇收款自由，税务政策便利等多种优势，是发展跨境事业最合适的港湾！'
                )],


            cityDescImg: [
                'https://static-s.theckb.com/BusinessMarket/offcialWebsite/Hangkong_1.png']

        }],


    [
        ENUM_OFFICE_CITY.KOREA_GIMPO,
        {
            cityName: () => strings.t('韩国金浦'),
            cityDesc: () => [
                strings.t(
                    '韩国拥有良好的互联网基础设施，为电子商务的发展打下坚实基础。韩国电子商务行业市场规模位居世界前列，是重要的消费市场。'
                ),
                strings.t(
                    'SNIFF韩国分公司位于京畿道金浦市金浦汉江9路75号街190,7楼715号-V3(九来洞,Inner Mass汉江)，主要负责韩国地区市场开拓，招商等工作。韩国分公司是SNIFF拓展韩国市场，增加海外市场厚度的重要力量！'
                )],


            cityDescImg: [
                'https://static-s.theckb.com/BusinessMarket/offcialWebsite/Korea_Gimpo_1.png']

        }]]

);