/*
 * @Author: liangliang
 * @Date: 2023-07-25 14:11:02
 * @LastEditors: shiguang
 * @LastEditTime: 2024-03-14 14:06:20
 * @Description: 路由页面配置
 */
import { ComponentType, LazyExoticComponent, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import callUS from '@/container/callUS';
import { Lang, getLang } from '@/i18n/i18n';
import Summary from '../../container/summary';

import Home from '../../container/Home';
import News from '../../container/News';
import NewsDetail from '../../container/NewsDetail';
import Culture from '../../container/Culture';
import JoinUs from '../../container/JoinUs';
import ContactUs from '../../common/ContactUs';
import About from '../../container/Home/About';
import test from './biz/test';

export interface RouteItem {
  /**
   * 路由页面
   * */
  component?: LazyExoticComponent<ComponentType<any>> | ComponentType<any>;
  /**
   * 路由地址
   */
  path?: string;
  /**
   * 父级路由，用于menu的高亮判断，例如 detail 页面 要定位到 列表页面 菜单上
   */
  activePath?: string;
  /**
   * 默认false，如果为true时，需要和路由相同时才能匹配，但是如果有斜杠也是可以匹配上的。
   */
  exact?: boolean;
  /**
   * 是否严格匹配斜杠结尾
   */
  strict?: boolean;
  /**
   * 页面标题
  */
  title?: string;
  /**
   * 显示左侧菜单，默认为true
   */
  showMenu?: boolean;
  /**
   * 显示头部，默认为true，如果设为false，则无头部组件
   */
  showHeader?: boolean;
  showLayout?: boolean;
  /** 页面权限code */
  pagePermissionCode?: string;
  /** 操作权限code */
  actionPermissionCode?: string[];
}

const INDEX_NAME = '/home';

const rederect = () => {
    const lang = getLang() === Lang.zh_CN ? 'cn' : 'en';
    return <Navigate to={`/${lang}${INDEX_NAME}`} />;
};

const originRouterList: RouteItem[] = [

    ...test,
    {
        path: '/summary',
        title: '首页',
        component: Summary,
    },
    {
        path: INDEX_NAME,
        title: '首页',
        component: Home,
    },
    {
        path: '/news',
        title: '最新资讯',
        component: News,
    },
    {
        path: '/newsDetail',
        title: '资讯详情',
        component: NewsDetail,
    },
    {
        path: '/culture',
        title: '企业文化',
        component: Culture,
    },
    {
        path: '/joinus',
        title: '加入我们',
        component: JoinUs,
    },
    {
        path: '/contactus',
        title: '联系我们',
        component: ContactUs,
    },
    {
        path: '/fullpage',
        title: '全屏测试',
        component: lazy(() => import('../../container/Culture/Demo')),
    },
    {
        path: '/about',
        title: '了解我们',
        component: About,
    },
    {
        path: '/callus',
        title: '联系我们',
        component: callUS,
    },
];


const routerList = [
    {
        path: '/',
        title: '',
        component: rederect
    },
    ...originRouterList.map((item) => {
        return [
            { ...item, path: '/en' + item.path },
            { ...item, path: '/cn' + item.path }
        ];
    }).flat(),
    {
        path: '*',
        title: '',
        component: rederect
    }
];

export default routerList;