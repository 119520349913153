/*
 * @Author: liangliang
 * @Date: 2023-07-25 14:03:48
 * @LastEditors: liuliangliang liuliangliang@sniffgroup.com
 * @LastEditTime: 2024-03-07 16:44:46
 * @Description:
 */
import qs from 'query-string';

const getQueryEnv = () => {
    const query = qs.parse(window.location.search) as { _env?: string; };
    return query?._env;
};


const getRquestBaseUrlByEnv = (env: string) => {
    const prodUrl = 'https://gateway-prod.theckb.com';
    const preUrl = 'https://pre-gateway.theckb.com';
    if(env === 'prod'){
        return prodUrl;
    }
    if(env === 'pre'){
        return preUrl;
    }
    return 'https://master-gateway.theckb.com';
    // return `https://${env}-gateway.theckb.com`;
};

// 1. 可以在 url 上配置
// 2. 可以在 代码中 动态改动
// 3. 根据域名处理
export const serviceConfig = (() => {
    return {
        baseURL: (() => {
            const queryEnv = getQueryEnv();
            if(queryEnv){
                return getRquestBaseUrlByEnv(queryEnv);
            }
            const devUrl = 'https://master-gateway.theckb.com';
            // const devUrl = 'https://gateway-prod.theckb.com';
            if(window.location.port) return devUrl;
            const arr = window.location.host.split('-');
            let [env] = arr;
            if(arr.length < 2) env = 'prod';
            return getRquestBaseUrlByEnv(env);
        })(),
    };
})();