/*
 * @Author: liuliangliang liuliangliang@sniffgroup.com
 * @Date: 2024-03-04 10:40:40
 * @LastEditors: shiguang
 * @LastEditTime: 2024-03-12 21:00:08
 * @FilePath: /ckb-official-website-fed/src/container/Culture/components/SectionItem/index.tsx
 * @Description: 文化/内容页
 */
import React from 'react';
import useDeviceType, { ENUM_DEVICE_TYPE } from '@/hooks/useDeviceType';
import { CultureContentItem, maskImgH5, maskImgPC } from '../../config';
import './index.scss';

const SectionItem = (props: CultureContentItem) => {
    const { deviceType } = useDeviceType();

    return (
        <div className="section" style={{ width: '100vw', height: 'calc(var(--vh) * 100)', position: 'relative' }}>
            <div
                style={{ position: 'relative' }}
            >


                <img
                    src={props[`imgUrl${deviceType}`]}
                    className="section-item-img"
                    style={{
                        width: '100vw',
                        height: 'calc(var(--vh) * 100)',
                        objectFit: 'cover'
                    }} alt=""
                />


                <img
                    src={deviceType === ENUM_DEVICE_TYPE.MOBILE ? maskImgH5 : maskImgPC}
                    style={{
                        width: '100%',
                        height: 'calc(var(--vh) * 100)',
                        objectFit: 'cover',
                        position: 'absolute',
                        zIndex: 22,
                        top: 0,
                        left: 0

                    }} alt=""
                />


            </div>

            <div className="culture-content">
                <div className="section-item-title">{props.title()}</div>
                {
                    props.descList.map((item) => {
                        return (
                            <div className="section-item-desc1" key={item()}>{item()}</div>);

                    })}

            </div>
        </div>
    );


};

export default SectionItem;