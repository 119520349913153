/*
 * @Author: yusha
 * @Date: 2024-03-07 21:50:31
 * @LastEditors: tianzhitong laotianwy@163.com
 * @LastEditTime: 2024-06-11 12:48:49
 * @Description:
 */
import React, { useEffect, useState } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import { useSetAtom } from 'jotai';
import { atomMenuTheme, atomLogoTheme } from '@/atom/atomPanelVisible';
import { ENUM_DEVICE_TYPE } from '@/hooks/useDeviceType';
import ContactUs from '../../common/ContactUs';
import SectionHome from './modules/sectionHome';
import SectionProduct from './modules/SectionProduct';
import SectionNews from './modules/sectionNews';
import { getCultureContent, cultureContent2, cultureContent } from './config';
import SectionItem from './modules/sectionCompany';
import JoinUS from './modules/joinUs';
import './index.scss';
// import useDeviceType from "@/hooks/useDeviceType";

const Home = (router) => {
    useEffect(() => {
    }, []);
    const getDeviceType = (_clientWidth: number) => {
        if (_clientWidth < 768) {
            return ENUM_DEVICE_TYPE.MOBILE;
        } else if (_clientWidth > 768 && _clientWidth < 1024) {
            return ENUM_DEVICE_TYPE.IPAD;
        }
        return ENUM_DEVICE_TYPE.PC;

    };
    const [targetPage, setTargetPage] = useState(0);
    const pageSize = getDeviceType(document.body.clientWidth) === 'H5' ? 10 : 9;
    const scrollItem = Array.from({ length: pageSize }, (_, index) => index);
    const anchors = Array.from({ length: pageSize }, (_, index) => `page${index}`);
    const themeMap = {
        PC: ['white', 'white', 'white', 'white', 'white', 'black', 'white', 'white', 'white'],
        H5: ['white', 'white', 'white', 'white', 'white', 'black', 'white', 'white', 'black', 'white'],
        Ipad: ['white', 'white', 'white', 'white', 'white', 'black', 'white', 'white', 'white']
    };
    const themeLogoMap = {
        PC: ['white', '#FF5010', '#FF5010', '#FF5010', '#FF5010', '#FF5010', 'white', 'white', '#FF5010'],
        H5: ['white', 'white', 'white', 'white', 'white', '#FF5010', 'white', 'white', '#FF5010', 'white'],
        Ipad: ['white', 'white', 'white', 'white', 'white', '#ff5010', 'white', 'white', '#FF5010']
    };
    const setAtomDrawerFlag = useSetAtom(atomMenuTheme);
    const setAtomDrawerLogo = useSetAtom(atomLogoTheme);

    useEffect(() => {
        window.fullpage_api.silentMoveTo(1);
    }, []);
    return (
        <div className="culture">
            <div>
                <ReactFullpage
                    credits={{}}
                    // 每一部分的锚点, 就是url#后面的部分
                    anchors={anchors}
                    // navigation // 是否显示右侧小圆圈
                    // navigationTooltips={anchors} // 鼠标放到小圆圈上显示的提示
                    // 每一个部分你的背景色
                    sectionsColor={['#ffffff', '#ffffff', '#ffffff']}
                    // 离开某一部分你的回调, 参数分别表示从哪里开 去哪里 方向. 前两者是自定义类型nn, 方向是up或down
                    onLeave={(origin, destination, direction) => {
                        console.log('onLeave event', { origin, destination, direction });
                        setTargetPage(destination.index);
                    }}
                    render={({ state, fullpageApi }) => {
                        // (state?.destination?.index || state?.destination?.index === 0) && setTargetPage(state?.destination.index);
                        const width = document.body.clientWidth;
                        const deviceType = getDeviceType(width);
                        setAtomDrawerFlag(themeMap[deviceType][state?.destination?.index || 0]);
                        setAtomDrawerLogo(themeLogoMap[deviceType][state?.destination?.index || 0]);
                        return (
                            <div style={{ width: '100vw', height: '100vh' }}>
                                <ReactFullpage.Wrapper>
                                    <SectionHome destination={state?.destination?.index} />
                                    {cultureContent.map((item, index) => {
                                        return (
                                            <SectionProduct
                                                {...item}
                                                key={item.title()}
                                                fullpageApi={fullpageApi}
                                                drawerFlag={themeMap[deviceType][state?.destination?.index || 0]}
                                                drawerLogo={themeLogoMap[deviceType][state?.destination?.index || 0]}
                                            />
                                        );


                                    })}
                                    <SectionNews />
                                    {cultureContent2.map((item) => {
                                        return <SectionItem {...item} key={item.title()} />;
                                    })}
                                    <JoinUS />
                                    <ContactUs />
                                </ReactFullpage.Wrapper>
                            </div>
                        );


                    }}
                />


            </div>
            <div className="scroll">
                {
                    scrollItem.map((item, key) => {
                        return <div key={key} className={item === 0 ? 'targetScroll' : ''} style={{ transform: `translateY(calc(${targetPage * 100 / pageSize}*var(--vh)))` }} />;
                    })}

            </div>
        </div>
    );


};

export default Home;