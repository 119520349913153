import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAtomValue } from 'jotai';
// import { atomLogoTheme, atomMenuTheme } from '@/atom/atomPanelVisible';
import { Modal } from 'antd';
import { strings } from '@/i18n/i18n';
import { atomSystemLanguage } from '@/atom/atomSystemLanguage';
import useDeviceType from '@/hooks/useDeviceType';
import './index.scss';
import { atomRoutePathLangPre } from '@/atom/atomRoutePathLangPre';

const SectionProduct = (props: any) => {
    const { isAboutPage } = props;
    const _atomSystemLanguage = useAtomValue(atomSystemLanguage);
    const { deviveType2 } = useDeviceType();
    const nav = useNavigate();

    // const setAtomDrawerFlag = useSetAtom(atomMenuTheme);
    // const setAtomDrawerLogo = useSetAtom(atomLogoTheme);
    const [dialogVisible, setDialogVisible] = useState(false);
    useEffect(() => {
    }, [props]);
    const routePathLangPre = useAtomValue(atomRoutePathLangPre);

    const openConsult = () => {
        setDialogVisible(false);
        if (isAboutPage) {
            window.fullpage_api.silentMoveTo(6);
        } else {
            nav(`/${routePathLangPre}/about#page5`);
        }
    };
    return (
        <div className="section sectionProduct" style={{ width: '100vw', height: 'calc(var(--vh) * 100)' }}>
            <div className="mark_h5">
                <img src="https://static-s.theckb.com/BusinessMarket/offcialWebsite/culture_h5_mask.png" alt="" />
            </div>
            <div className="sectionContentBox">
                <div className="sectionContent">
                    <div className="title">{props.title()}</div>
                    <div className="data">
                        <div className="dateItem">
                            <div>{props.dataTitle1()}</div>
                            <div>{props.dataNum1()}</div>
                        </div>
                        <div className="dateItem">
                            <div>{props.dataTitle2()}</div>
                            <div>{props.dataNum2()}</div>
                        </div>
                    </div>
                    <div className="info">
                        {props.desc().slice(0, props.sliceString[deviveType2][_atomSystemLanguage])}
                        <span> ...
                            <span onClick={() => { setDialogVisible(true);props.fullpageApi.setAllowScrolling(false); }}>{strings.t('查看更多')}</span>
                        </span>
                    </div>

                    <div className="buttonBox">
                        <div className="button sectionButton" onClick={openConsult}>{strings.t('商务咨询')}</div>
                    </div>
                </div>
            </div>
            <img className="sectionBg" src={props.imgUrlPC} alt="" />
            <Modal
                className="sectionProductDialog" title=""
                open={dialogVisible} footer={null}
                onCancel={() => {
                    setDialogVisible(false);
                    props.fullpageApi.setAllowScrolling(true);
                }}
            >


                <div className="sectionProductDialog-content">
                    <div className="sectionProductDialog-title">{props.title()}</div>
                    <div className="sectionProductDialog-data">
                        <div className="sectionProductDialog-dateItem">
                            <div>{props.dataTitle1}</div>
                            <div>{props.dataNum1}</div>
                        </div>
                        <div className="sectionProductDialog-dateItem">
                            <div>{props.dataTitle2}</div>
                            <div>{props.dataNum2}</div>
                        </div>
                    </div>
                    <div className="sectionProductDialog-info">{props.desc()}</div>
                    <div className="sectionProductDialog-buttonBox">
                        <div className="sectionProductDialog-sectionButton" onClick={openConsult}>{strings.t('商务咨询')}</div>
                    </div>
                </div>
            </Modal>

            {/* {
        dialogVisible && <div className='sectionProductDialogBg' >
        <div className='sectionProductDialog'>
        <div style={{ position: 'relative' }}>
        <div className='sectionProductDialog-content'>
        <div className='sectionProductDialog-title'>{props.title}</div>
        <div className='sectionProductDialog-data'>
        <div className='sectionProductDialog-dateItem'>
        <div>{props.dataTitle1}</div>
        <div>{props.dataNum1}</div>
        </div>
        <div className='sectionProductDialog-dateItem'>
        <div>{props.dataTitle2}</div>
        <div>{props.dataNum2}</div>
        </div>
        </div>
        <div className='sectionProductDialog-info'>{props.desc}</div>
        <div className='sectionProductDialog-buttonBox'>
        <div className='sectionProductDialog-sectionButton' onClick={() => nav('/about#page5')}>{strings.t('商务咨询')}</div>
        </div>
        </div>
        <div className='sectionProductDialog-close close-H5' onClick={() =>{setDialogVisible(false);setAtomDrawerFlag(props.drawerFlag);setAtomDrawerLogo(props.drawerLogo);props.fullpageApi.setAllowScrolling(true)}}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M12 13.3805L16.6195 18L18 16.6194L13.3806 12L18 7.38057L16.6195 6.00004L12 10.6195L7.38053 6L6 7.38053L10.6195 12L6 16.6195L7.38053 18L12 13.3805Z" fill="black" fill-opacity="0.9" />
        </svg>
        </div>
        </div>
        <div className='sectionProductDialog-close close-PC' onClick={() =>{setDialogVisible(false);setAtomDrawerFlag(props.drawerFlag);setAtomDrawerLogo(props.drawerLogo);props.fullpageApi.setAllowScrolling(true)}}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M12 13.3805L16.6195 18L18 16.6194L13.3806 12L18 7.38057L16.6195 6.00004L12 10.6195L7.38053 6L6 7.38053L10.6195 12L6 16.6195L7.38053 18L12 13.3805Z" fill="black" fill-opacity="0.9"/>
        </svg>
        </div>
        </div>
        </div>} */}


        </div>
    );


};


export default SectionProduct;