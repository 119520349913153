/*
 * @Author: liuliangliang liuliangliang@sniffgroup.com
 * @Date: 2024-03-04 11:10:05
 * @LastEditors: liuliangliang liuliangliang@sniffgroup.com
 * @LastEditTime: 2024-03-06 10:32:49
 * @FilePath: /ckb-official-website-fed/src/hooks/useDeviceType/index.tsx
 * @Description: 获取设备类型
 */

import { useEffect, useState } from 'react';

/** 设备类型 */
export enum ENUM_DEVICE_TYPE {
    PC = 'PC',
    MOBILE = 'H5',
    IPAD = 'Ipad',
  }

const useDeviceType = () => {
    /** body宽度 */
    const [clientWidth, setClientWidth] = useState(window.screen.width);
    /** 设备类型 */
    const [deviceType, setDeviceType] = useState<ENUM_DEVICE_TYPE>();

    useEffect(() => {
        getDeviceType(document.body.clientWidth);

        const handleResize = () => {
            const _clientWidth = document.body.clientWidth;
            setClientWidth(_clientWidth);
            getDeviceType(_clientWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getDeviceType = (_clientWidth: number) => {
        if (_clientWidth < 768) {
            setDeviceType(ENUM_DEVICE_TYPE.MOBILE);
        } else if (_clientWidth > 768 && _clientWidth < 1024) {
            setDeviceType(ENUM_DEVICE_TYPE.IPAD);
        } else {
            setDeviceType(ENUM_DEVICE_TYPE.PC);
        }
    };

    const getDeviceType2 = (_clientWidth: number) => {
        if (_clientWidth < 768) {
            // setDeviceType(ENUM_DEVICE_TYPE.MOBILE)
            return ENUM_DEVICE_TYPE.MOBILE;
        } else if (_clientWidth > 768 && _clientWidth < 1024) {
            // setDeviceType(ENUM_DEVICE_TYPE.IPAD)
            return ENUM_DEVICE_TYPE.IPAD;
        }
        // setDeviceType(ENUM_DEVICE_TYPE.PC)
        return ENUM_DEVICE_TYPE.PC;

    };
    return {
        clientWidth,
        deviceType,
        deviveType2: getDeviceType2(document.body.clientWidth)
    };
};

export default useDeviceType;