/*
 * @Author: shiguang
 * @Date: 2023-04-25 10:46:45
 * @LastEditors: shiguang
 * @LastEditTime: 2024-03-12 20:13:42
 * @Description: App
 */
import React, { Suspense, useEffect } from 'react';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import 'normalize.css';
import { RouterProvider, RouterProviderProps, createBrowserRouter } from 'react-router-dom';
// import { getRouter } from './config/router/createRouter';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider, useAtom, useAtomValue } from 'jotai';
import classNames from 'classnames';
import routeList, { RouteItem } from './config/router';
import HeadMenu from './common/HeadMenu';
import './g.scss';
import { ENUM_SYSTEM_LANGUAGE, atomSystemLanguage } from './atom/atomSystemLanguage';
import { Lang, getLang } from './i18n/i18n';
import { myStore } from './atom/store';
const PageContainer = (
    props: { originPage?: React.ComponentType<any> | React.LazyExoticComponent<React.ComponentType<any>>; routeConf: RouteItem; }
) => {
    const { originPage: OriginPage, routeConf } = props;
    const systemLanguage = useAtomValue(atomSystemLanguage);
    useEffect(() => {
        if (window.location.pathname.includes('/en/')) {
            window.localStorage.setItem('web_site_lang', Lang.en_GB);
        } else {
            window.localStorage.setItem('web_site_lang', Lang.zh_CN);
        }
        getLang();
    }, []);
    return OriginPage ? <OriginPage lang={systemLanguage} key={systemLanguage + routeConf.path} /> : null;
    // return OriginPage ? <OriginPage /> : null;
};
const createRouter = (): RouterProviderProps['router'] => {
    return createBrowserRouter(
        routeList.map((item) => {
            const Comp = item.component;
            return {
                element: (() => {
                    return (
                        <>
                            <HeadMenu />
                            {/* <Suspense fallback={<div>Loading...</div>}> */}
                            <PageContainer originPage={Comp} routeConf={item} />
                            {/* </Suspense> */}
                        </>
                    );
                })(),
                path: item.path,
                errorElement: <div />
            };
        }),
        {
            // basename: ROUTER_BASENAME
        }
    );
};

export const router = createRouter();

export const getRouter = () => {
    return router;
};

function App() {
    const [currentSystemLanguageType, setAtomSystemLanguage] = useAtom(atomSystemLanguage);
    useEffect(() => {
        const lang = getLang();
        setAtomSystemLanguage(lang === Lang.en_GB ? ENUM_SYSTEM_LANGUAGE.ENGLISH : ENUM_SYSTEM_LANGUAGE.CHINESE);
    }, [setAtomSystemLanguage]);
    return (
        <Provider store={myStore} >
            <div className={classNames('App', {
                'en-all-family': currentSystemLanguageType === ENUM_SYSTEM_LANGUAGE.ENGLISH,
                'zh-all-family': currentSystemLanguageType === ENUM_SYSTEM_LANGUAGE.CHINESE,
            })}
            >
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 40,
                    position: 'fixed',
                    top: 0
                }}
                />
                <ConfigProvider
                    locale={zhCN}
                    theme={{
                        token: {
                            colorPrimary: '#1890ff',
                        }
                    }}
                >
                    <RouterProvider
                        router={router}
                        fallbackElement={<div>fallbackElement</div>}
                    />

                </ConfigProvider>
            </div>
        </Provider>
    );
}


export default App;
