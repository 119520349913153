/*
 * @Author: liuliangliang liuliangliang@sniffgroup.com
 * @Date: 2024-03-06 17:17:22
 * @LastEditors: liuliangliang liuliangliang@sniffgroup.com
 * @LastEditTime: 2024-03-07 21:49:47
 * @FilePath: /ckb-official-website-fed/src/container/JoinUs/comonents/SectionItem/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import useDeviceType, { ENUM_DEVICE_TYPE } from '@/hooks/useDeviceType';
import { ENUM_CONTENT_TYPE, JoinUsContentItem, resumeSubmissionUrl } from '../../config';
import './index.scss';
import OfficalCity from '../OfficeCity';

const SectionItem = (props: JoinUsContentItem) => {
    const { deviveType2 } = useDeviceType();
    console.log(deviveType2);

    const renderContentDesc = () => {
        if (props.contentDesc) {

            return props.contentDesc.map((item, index) => {
                return (
                    <div className={`section-joinus-content-desc desc-${props.contentType}`} key={index}>{item}</div>);

            });
        }
    };

    return (
        <div className="section" style={{ width: '100vw', height: 'calc(var(--vh) * 100)' }}>
            {
                [ENUM_DEVICE_TYPE.IPAD, ENUM_DEVICE_TYPE.PC].includes(deviveType2!) &&
      <div style={{ display: 'flex' }}>
          <div className="section-left">
              <img src={props.imgUrlPC} alt="" className="section-left-img" />
              <div className="section-joinus-content">
                  <div className="section-item-flag">{props.flag}</div>
                  <div className="section-item-flagDesc">{props.flagDesc}</div>
                  <a href={resumeSubmissionUrl} target="_blank" className="section-item-btn" rel="noreferrer">{props.btnText}</a>
              </div>
          </div>
          <div className="section-right">
              <div className="section-joinus-content">
                  <div className="section-joinus-content-title">{props.contentTitle}</div>
                  {renderContentDesc()}
                  {props.contentType === ENUM_CONTENT_TYPE.OFFICE_CITY && <OfficalCity />}
              </div>
          </div>
      </div>}


            {
                ENUM_DEVICE_TYPE.MOBILE === deviveType2 && (
                    props.contentType === ENUM_CONTENT_TYPE.OFFICE_CITY ?
                        <div style={{ width: '100vw', height: 'calc(var(--vh) * 100)' }}>
                            <OfficalCity />
                        </div> :

                        <div>
                            <img
                                src={props.imgUrlH5} alt="" style={{
                                    width: '100vw',
                                    height: 'calc(var(--vh) * 100)',
                                    objectFit: 'cover'
                                }}
                            />


                            <div className="section-joinus-content">
                                <div className="section-item-title">{props.contentTitle}</div>
                                {renderContentDesc()}
                                <a href={resumeSubmissionUrl} target="_blank" className="section-item-btn" rel="noreferrer">{props.btnText}</a>
                            </div>
                        </div>)}


        </div>
    );


};

export default SectionItem;