/*
 * @Author: liuliangliang liuliangliang@sniffgroup.com
 * @Date: 2024-03-05 13:47:10
 * @LastEditors: liuliangliang liuliangliang@sniffgroup.com
 * @LastEditTime: 2024-03-05 13:47:29
 * @FilePath: /ckb-official-website-fed/src/container/News/NewsDetail/config.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import axios from 'axios';

/** 资讯详情接口 */
export const getNewsDetailByNoApi = (no: string) => {
    return axios({ url: `https://static-s.theckb.com/sniffGroup/article/articleItems/${no}.prod.json` });
};
/** 文章列表 */
export const getArticleListApi = () => {
    return axios({ url: `https://static-s.theckb.com/sniffGroup/article/articleList.prod.json?_time=${new Date().getTime()}` });
};
export interface ArtcleDetailItem {
  /** 分类 */
  classification?: string;
  /** 中文标题 */
  title?: string;
  /** 英文标题 */
  enTitle: string;
  /** 中文内容 */
  content?: string;
  /** 英文内容 */
  enContent?: string;
  /** 日期 */
  date?: string;
}
export interface ArticleListItem extends ArtcleDetailItem {
  /** 文章编号 */
  no: string;
  /** 文章标题 */
  title: string;
  /** 文章描述 */
  desc: string;
  /** 文章封面图 */
  imgUrl: string;
}
export const icon_arrow_left = 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/arrow-left.png';