/*
 * @Author: shiguang
 * @Date: 2024-03-06 19:48:28
 * @LastEditors: shiguang
 * @LastEditTime: 2024-03-12 20:39:10
 * @Description: 系统语言
 */
import { atom } from 'jotai';
import { getLang } from '@/i18n/i18n';

export enum ENUM_SYSTEM_LANGUAGE {
  ENGLISH = 'EN',
  CHINESE = 'CN'
}
/** 系统语言 */
export const atomSystemLanguage = atom<ENUM_SYSTEM_LANGUAGE>(getLang() === 'en_GB' ? ENUM_SYSTEM_LANGUAGE.ENGLISH : ENUM_SYSTEM_LANGUAGE.CHINESE);