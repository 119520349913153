/*
 * @Author: yusha
 * @Date: 2023-08-25 10:59:31
 * @LastEditors: yusha
 * @LastEditTime: 2023-08-25 11:00:37
 * @Description: 首页
 */
const Summary = () => {
    return (
        <div>欢迎光临</div>
    );
};

export default Summary;