import React, { useState } from 'react';
import { strings } from '@/i18n/i18n';
/*
 * @Author: liuliangliang liuliangliang@sniffgroup.com
 * @Date: 2024-03-04 15:43:21
 * @LastEditors: tianzhitong laotianwy@163.com
 * @LastEditTime: 2024-03-07 22:38:56
 * @FilePath: /ckb-official-website-fed/src/container/JoinUs/comonents/OfficeCity/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import './index.scss';
import { ENUM_OFFICE_CITY, icon_swap_right } from '../../config';
import CityInfoModal from '../CityInfoModal';

interface OfficeCityItem {
  cityName: string;
  cityCode: ENUM_OFFICE_CITY;
}

const OfficalCity = () => {
    const [currentCityCode, setCurrentCityCode] = useState(ENUM_OFFICE_CITY.HANGZHOU);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const onClickCityName = (cityCode: ENUM_OFFICE_CITY) => {
        setCurrentCityCode(cityCode);
        setIsModalOpen(true);
    };

    const officeCityList: OfficeCityItem[] = [
        { cityName: strings.t('杭州'), cityCode: ENUM_OFFICE_CITY.HANGZHOU },
        { cityName: strings.t('东京'), cityCode: ENUM_OFFICE_CITY.TOKYO },
        { cityName: strings.t('大连'), cityCode: ENUM_OFFICE_CITY.DALIAN },
        { cityName: strings.t('广州'), cityCode: ENUM_OFFICE_CITY.GUANGZHOU },
        { cityName: strings.t('香港'), cityCode: ENUM_OFFICE_CITY.HONGKONG },
        { cityName: strings.t('韩国金浦'), cityCode: ENUM_OFFICE_CITY.KOREA_GIMPO }];

    return (
        <div className="office-city">
            <div className="office-city-tips">{strings.t('选择你的办公城市')}</div>
            <div className="office-city-content">
                {
                    officeCityList.map((item) => {
                        return (
                            <div
                                className="office-city-item" key={item.cityCode}
                                onClick={() => onClickCityName(item.cityCode)}
                            >


                                <div className="office-city-name">{item.cityName}</div>
                                <div className="office-city-btn">{strings.t('办公环境')}

                                    <img
                                        src={icon_swap_right}
                                        style={{
                                            width: 16, height: 16,
                                            marginLeft: 8,
                                            marginTop: -4
                                        }}
                                        alt=""
                                    />


                                </div>
                            </div>
                        );


                    })}

            </div>
            <CityInfoModal
                visible={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                cityCode={currentCityCode}
            />


        </div>
    );


};

export default OfficalCity;