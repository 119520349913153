/*
 * @Author: liuliangliang liuliangliang@sniffgroup.com
 * @Date: 2024-03-01 16:09:48
 * @LastEditors: tianzhitong laotianwy@163.com
 * @LastEditTime: 2024-07-12 19:02:35
 * @FilePath: /ckb-official-website-fed/src/container/News/index.tsx
 * @Description: 最新资讯
 */
import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useRequest } from 'ahooks';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import moment from 'moment';
import { useSetAtom, useAtomValue } from 'jotai';
import { atomMenuTheme, atomLogoTheme } from '@/atom/atomPanelVisible';
import { atomSystemLanguage, ENUM_SYSTEM_LANGUAGE } from '@/atom/atomSystemLanguage';
import { strings } from '@/i18n/i18n';
// import { getLang } from "@/i18n/i18n";
import './index.scss';
import { atomRoutePathLangPre } from '@/atom/atomRoutePathLangPre';

const Information = () => {
    const setAtomDrawerFlag = useSetAtom(atomMenuTheme);
    const setAtomDrawerLogo = useSetAtom(atomLogoTheme);
    const _atomSystemLanguage = useAtomValue(atomSystemLanguage);

    // const _lang = getLang()
    // console.log("_artcles", _lang);

    const { i18n } = useTranslation();
    function getArtcles() {
        return axios({
            url: `https://static-s.theckb.com/sniffGroup/article/articleList.prod.json?_time=${new Date().getTime()}`
        });
    }
    const [searchParams] = useSearchParams();
    // const { lang: langInPath } = useParams();
    // const langInPath = "cn";
    // const currentPageNo = Number(searchParams.get("pageNo")) || 1;
    const pageSize = Number(searchParams.get('pageSize')) || 99;

  interface artcleItem {
    classification: string;
    date: string;
    enImgUrl: string;
    enOverview: string;
    enTitle: string;
    formInstId: string;
    gmtCreate: string;
    gmtModified: string;
    imgUrl: string;
    overview: string;
    title: string;
  }

  const [artcles, setArtcles] = useState(([] as artcleItem[]));
  const [type, setType] = useState('ALL');
  // const articleClassification = type || "企业合作";
  // const [artclesLength, setArtclesLength] = useState(0);
  // const [currentPageNo, setCurrentPageNo] = useState(1);
  const currentPageNo = 1;

  // const pageArr = Array.from({ length: Math.ceil(artclesLength / pageSize) }, (_, index) => index);
  // const articleStation = langMapArticleStation[langInPath];
  const { run } = useRequest(getArtcles, {
      onSuccess(data) {
          const list = data.data
              .filter(({ title, enTitle }) => {
                  if (_atomSystemLanguage === ENUM_SYSTEM_LANGUAGE.ENGLISH && enTitle) {
                      return true;
                  }
                  if (_atomSystemLanguage !== ENUM_SYSTEM_LANGUAGE.ENGLISH && title) {
                      return true;
                  }
                  return false;
              })
              .filter(
                  ({ classification }) => {
                      if (type === 'ALL') {
                          return true;
                      }
                      return classification === type;

                  }
              );
          // setArtclesLength(list.length);

          const displayRangeStart = currentPageNo * pageSize - pageSize;
          const displayRangeEnd = currentPageNo * pageSize - 1;
          const _artcles = list.filter(
              (_, index) => index >= displayRangeStart && index <= displayRangeEnd
          );

          setArtcles(_artcles);
      }
  });

  useEffect(() => {
      setAtomDrawerFlag('black');
      setAtomDrawerLogo('#FF5010');
      run();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, type, currentPageNo, pageSize]);
  const routePathLangPre = useAtomValue(atomRoutePathLangPre);

  const nav = useNavigate();
  const navigatorToDetail = (article) => {
      nav(`/${routePathLangPre}/newsDetail?no=${article.no}`);
  };
  return (
      <>
          <div className="headMenuBg" />
          <div className="informationLayout">
              <div className="breadcrumb"><span onClick={() => nav(`/${routePathLangPre}/home`)}>{strings.t('首页')}</span> {'>'} {strings.t('最新资讯')}</div>
              <div className="layoutTitle">{strings.t('SNIFF最新资讯')}</div>
              <div className="layoutTabs">
                  <div className={type === 'ALL' ? 'onSelect' : ''} onClick={() => setType('ALL')}>ALL</div>
                  {/* 注意此处不要翻译用 strings.t 否则后端数据异常 */}
                  <div className={type === '企业合作' ? 'onSelect' : ''} onClick={() => setType('企业合作')}>{strings.t('企业合作')}</div>
                  {/* 注意此处不要翻译用 strings.t 否则后端数据异常 */}
                  <div className={type === '产品动态' ? 'onSelect' : ''} onClick={() => setType('产品动态')}>{strings.t('产品动态')}</div>
                  {/* 注意此处不要翻译用 strings.t 否则后端数据异常 */}
                  <div className={type === '公告' ? 'onSelect' : ''} onClick={() => setType('公告')}>{strings.t('公告')}</div>
                  {/* 注意此处不要翻译用 strings.t 否则后端数据异常 */}
              </div>
              {artcles.map((article) =>
                  <div
                      className="informationItem"
                      key={article.enTitle}
                  >


                      {/* <div className="informationImg" onClick={() => navigatorToDetail(article)}> */}
                      {/* <img src={article.imgUrl} alt="" /> */}
                      {/* 有英文用英文 没有英文默认图兜底 */}
                      <img
                          className="informationImg pc:!w-[720px] pc:!h-[540px] pc:!object-cover !block pc:flex-shrink-0 "
                          onClick={() => navigatorToDetail(article)}
                          src={(_atomSystemLanguage === ENUM_SYSTEM_LANGUAGE.ENGLISH ? article.enImgUrl : article.imgUrl) ?? article.imgUrl } alt=""
                      />
                      {/* </div> */}
                      {/* <img src={langInPath === 'en' ? article.enImgUrl : article.imgUrl} alt='' /> */}
                      <div className="itemInfoBox" onClick={() => navigatorToDetail(article)}>
                          <div className="itemTitle">{_atomSystemLanguage === ENUM_SYSTEM_LANGUAGE.ENGLISH ? article.enTitle : article.title}</div>
                          <div className="itemTime">
                              {moment(article.date).format('YYYY-MM-DD').replace(/-/g, '.')}
                          </div>
                          <div className="itemDesc">
                              <div className="itemDescOmit">
                                  {_atomSystemLanguage === ENUM_SYSTEM_LANGUAGE.ENGLISH ? article.enOverview : article.overview}
                              </div>
                              <span>{strings.t('查看更多')}</span>
                          </div>
                      </div>
                  </div>
              )}

              {/* <div className="paging-wrap">
          {
          pageArr.map((v) => {
          return (
          <div className="pageNum" onClick={() => setCurrentPageNo(v + 1)}>{v + 1}
          {
          v + 1 === currentPageNo && <div className="underLine"></div>}
          </div>);
          })}
          </div> */}


          </div>
      </>
  );


};

export default Information;