/*
 * @Author: liuliangliang liuliangliang@sniffgroup.com
 * @Date: 2024-03-01 16:08:19
 * @LastEditors: tianzhitong laotianwy@163.com
 * @LastEditTime: 2024-06-11 13:36:18
 * @FilePath: /ckb-official-website-fed/src/common/ContactUs/index.tsx
 * @Description: 联系我们
 */
import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import './index.scss';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import useDeviceType, { ENUM_DEVICE_TYPE } from '@/hooks/useDeviceType';
import { strings } from '@/i18n/i18n';
import { request } from '@/config/request';
import { ENUM_SYSTEM_LANGUAGE, atomSystemLanguage } from '@/atom/atomSystemLanguage';

export const phoneAreaList = [
    {
        label: '+81',
        value: '+81',
        country: strings.t('日本')
    },
    {
        label: '+82',
        value: '+82',
        country: strings.t('韩国')
    },
    {
        label: '+86',
        value: '+86',
        country: strings.t('中国')
    }];

const HANGZHOU_POSITION = 'https://www.google.com/maps/place/%E9%98%BF%E9%87%8C%E5%B7%B4%E5%B7%B4B2B%E4%BA%8C%E5%8F%B7%E5%9B%AD%E5%8C%BA/@30.1920537,120.191311,17.44z/data=!4m6!3m5!1s0x344c82eec3dcaa3b:0x24e51fd20b4f10a8!8m2!3d30.192426!4d120.189642!16s%2Fg%2F11cnpb9h03?entry=ttu';
// const icon_caret_down = 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/icon_caret_down.png';
const icon_wechat = 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/icon_wechat.png';
const icon_qrcode = 'https://static-s.theckb.com/BusinessMarket/offcialWebsite/master_qrCode.png';

const ContactUs = () => {
    //
    // const [ entered, setEntered ] = useState<Record<string, boolean>>({})
    const [focusForm, setFocusForm] = useState<Record<string, boolean>>({});
    const systemLanguage = useAtomValue(atomSystemLanguage);
    const [messageApi, contextHolder] = message.useMessage();
    // const [phoneArea, setPhoneArea] = useState('+86');
    const { deviveType2 } = useDeviceType();

    const [disabledBtn, setDisabledBtn] = useState(true);
    const [form] = Form.useForm();
    const userName = Form.useWatch('userName', form);
    const companyName = Form.useWatch('companyName', form);
    const phone = Form.useWatch('phone', form);
    const email = Form.useWatch('email', form);

    /** 提交信息 */
    const submitInfo = async () => {
        const params = await form.validateFields();

        if (!params) return;
        const res = await request.customer.base.feedbackAddFeedbackInfo({
            ...params
            // phone: phoneArea + '-' + params.phone
        });
        if (res.success) {
            messageApi.open({
                type: 'success',
                content: strings.t('提交成功')
            });
        }
    };

    useEffect(() => {

        // if (userName && phone && companyName && email) {
        if (userName && /^[\d]{8,20}$/.test(phone) && companyName &&
    /[\d\w-]+@([a-zA-ZA-z0-9-]+\.)+[a-z]+/.test(email))
        {
            setDisabledBtn(false);
            return;
        }
        setDisabledBtn(true);
    }, [companyName, email, phone, userName]);


    // const createEmptyNoError = (name: string) => ({
    //   validator(rule, value, callback) {
    //       if(!value && !entered[name]) {
    //         return Promise.resolve();
    //       }
    //       console.log(value, '--')
    //       // if(!value){
    //       //   return Promise.reject(strings.t('格式错误'))
    //       //   // console.log('eeeee');
    //       // }
    //       return  Promise.resolve();
    //   }
    // })
    const zz: any = window;
    zz.form = form;
    console.log(focusForm);
    const createVerificationTiming = (name: string) => {
        const onFocus = () => {
            setFocusForm({ ...focusForm, [name]: true });
            console.log(666666);
            setTimeout(() => {
                form.validateFields([name]);
            }, 100);
        };
        const onBlur = () => {
            setFocusForm({ ...focusForm, [name]: false });
            setTimeout(() => {
                form.validateFields([name]);
            }, 100);
        };
        return {
            inputParms: {
                onFocus,
                onBlur
            }
        };
    };


    // console.log(entered)
    // const createSetEnteredByFormChange = ( name: string) => (value: string) => {
    //   if(value && !entered[name]){
    //     setEntered(
    //       {
    //         ...entered,
    //         [name]: true
    //       }
    //     )
    //   }
    // }
    /** 渲染Form */
    const renderForm = () => {
        return (
            <>
                {contextHolder}
                <Form
                    form={form}
                    name="contactUs"
                    autoComplete="off"
                    style={{ maxWidth: 600 }}
                    className={classNames({
                        'contactUs-form': true,
                        'enStyle': systemLanguage === ENUM_SYSTEM_LANGUAGE.ENGLISH
                    })}
                    layout="vertical"
                >


                    <Form.Item
                        name="userName" label={strings.t('名字')}
                        rules={focusForm.userName ? [{ validator: () => Promise.resolve() }] : [
                            // createEmptyNoError('userName'),
                            // {
                            //   required: true,
                            //   message: ''
                            // }
                        ]}
                        validateTrigger={['onBlur']}
                    >


                        <Input
                            className={classNames({
                                'enStyle': systemLanguage === ENUM_SYSTEM_LANGUAGE.ENGLISH
                            })}
                            placeholder={strings.t('请输入您的姓名')}
                            {...createVerificationTiming('userName').inputParms}
                        />


                    </Form.Item>
                    <Form.Item
                        name="phone" label={strings.t('电话')}
                        rules={focusForm.phone ? [{ validator: () => Promise.resolve() }] : [
                            // createEmptyNoError('phone'),
                            {
                                pattern: new RegExp(/^[\d]{8,20}$/),
                                message: strings.t('格式错误')
                            }]}
                        validateTrigger={['onBlur']}
                        className={phone ? 'form-item-label-focus' : ''}
                    >


                        {/* <div className='form-item-phone'>
              <div className='form-item-phoneArea'>
              <div className='form-item-phoneArea-lable'>
              {phoneArea}
              <div className='phoneArea-selecter'>
              {
              phoneAreaList.map((item) => {
              return (
              <div
              className='phoneArea-selecter-item'
              onClick={() => setPhoneArea(item.value)}>
              {item.label}
              </div>);
              })}
              </div>
              </div>
              <img src={icon_caret_down} alt='' className='phoneArea-select-icon' />
              <Divider type="vertical" />
              </div>
              <Input placeholder={strings.t('请输入您的电话号码')} />
              </div> */}


                        <Input
                            className={classNames({
                                'enStyle': systemLanguage === ENUM_SYSTEM_LANGUAGE.ENGLISH
                            })} placeholder={strings.t('请输入您的电话号码')}
                            {...createVerificationTiming('phone').inputParms}
                        />


                    </Form.Item>
                    <Form.Item
                        name="companyName" label={strings.t('公司')}
                        validateTrigger={['onBlur']}
                        className={companyName ? 'form-item-label-focus' : ''}
                    >


                        <Input
                            className={classNames({
                                'enStyle': systemLanguage === ENUM_SYSTEM_LANGUAGE.ENGLISH
                            })} placeholder={strings.t('请输入您的公司名称')}
                            {...createVerificationTiming('companyName').inputParms}
                        />


                    </Form.Item>
                    <Form.Item
                        name="email" label={strings.t('邮件')}
                        rules={focusForm.email ? [{ validator: () => Promise.resolve() }] : [
                            {
                                pattern: new RegExp(/[\d\w-]+@([a-zA-ZA-z0-9-]+\.)+[a-z]+/),
                                message: strings.t('格式错误')
                            }]}
                        validateTrigger={['onBlur']}
                        className={email ? 'form-item-label-focus' : ''}
                    >


                        <Input
                            placeholder={strings.t('请输入您的邮件地址')}
                            className={classNames({
                                'enStyle': systemLanguage === ENUM_SYSTEM_LANGUAGE.ENGLISH
                            })}
                            // onChange={(e) => createSetEnteredByFormChange('email')(e.target.value)}
                            {...createVerificationTiming('email').inputParms}
                        />


                    </Form.Item>
                    <Form.Item style={{ paddingTop: 8 }}>
                        <Button
                            type="primary"
                            // className='contactUs-form-submit'
                            // className={`contactUs-form-submit ${disabledBtn ? 'disabled' : 'un-disabled'}`}
                            className={classNames({
                                'contactUs-form-submit': true,
                                'disabled': disabledBtn,
                                'un-disabled': !disabledBtn,
                                'enStyle': systemLanguage === ENUM_SYSTEM_LANGUAGE.ENGLISH
                            })}
                            disabled={disabledBtn}
                            onClick={submitInfo}
                        >


                            {strings.t('提交')}
                        </Button>
                    </Form.Item>
                </Form>
            </>
        );


    };


    const renderContactUsRight = () => {
        return (
            <div className="contactUs-right">
                <div className="contactUs-right-content">
                    <div className="desc">{strings.t('立即反馈')}</div>
                    <a className="desc email" href="mailto:interact@sniffgroup.com">interact@sniffgroup.com</a>
                    <div className="position">
                        <div className="position-city">Hangzhou</div>
                        <div className="position-desc">
                            <a href={HANGZHOU_POSITION} target="_blank" rel="noreferrer">{strings.t('查看办公地址')}</a>
                        </div>
                        <div
                            className="filings"
                            onClick={() => {
                                window.open('https://beian.miit.gov.cn', '_blank');
                            }}
                        >浙ICP备16032890号-10</div>
                    </div>
                </div>
                <div className="contactUs-right-master">
                    <div className="master-item">
                        <div className="master-item-wrap">
                            <div className="master-item-left">
                                <img src="https://static-s.theckb.com/BusinessMarket/offcialWebsite/avater-contacts-1.png" alt="" className="master-item-img" />
                                <div className="master-wechat">
                                    <img src={icon_wechat} alt="" className="master-wechat-icon" />
                                </div>
                            </div>
                            <div className="master-item-right">
                                <div className="master-name">
                                    <div>{strings.t('胡女士')}</div>
                                </div>
                                <div className="master-identity">
                                    {strings.t('招商总监')}
                                </div>
                            </div>
                        </div>
                        <div className="master-qrcode">
                            <div className="master-qrcode-wrap">
                                <img src={icon_qrcode} alt="" className="master-qrcode-icon" />
                                <div className="master-qrcode-desc">
                                    <div style={{ width: '200px', textAlign: 'center' }}>{strings.t('微信扫一扫，添加她为好友，')}</div>
                                    <div>{strings.t('快速取得联系与合作。')}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );


    };

    return (
        <>
            <div className="section">
                <div className="contactUs">
                    <div className="contactUs-left">
                        <div className="contactUs-left-wrap">
                            <div className="contactUs-tips">{strings.t('联系我们1')}</div>
                            <div className="contactUs-content">
                                {renderForm()}
                            </div>
                            <div className="audit">
                                <a href="https://beian.miit.gov.cn">浙ICP备16032890号-10 </a>
                            </div>
                        </div>
                    </div>
                    {[ENUM_DEVICE_TYPE.IPAD, ENUM_DEVICE_TYPE.PC].includes(deviveType2!) && renderContactUsRight()}
                </div>
            </div>
            {
                ENUM_DEVICE_TYPE.MOBILE === deviveType2 &&
      <div className="section">
          {renderContactUsRight()}
      </div>}
        </>
    );


};

export default ContactUs;