import { strings } from '@/i18n/i18n';
import './index.scss';
const CallUs = () => {
    return (
        <div className="section callUsSection" style={{ width: '100vw', height: 'calc(var(--vh) * 100)' }}>
            <div className="headMenuBg" />
            <div className="callUsTitle">{strings.t('CKB业务咨询')}</div>
            <div className="callUsBox">
                <div className="callUsItem">
                    <div className="callUsItemSon">
                        <div className="callUsItemWay">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M11.3309 5.12402C11.8152 5.12402 12.2615 5.3867 12.4967 5.8102L14.1276 8.74807C14.3411 9.13274 14.3512 9.598 14.1544 9.99154L12.5832 13.134C12.5832 13.134 13.0385 15.4749 14.9441 17.3805C16.8497 19.2861 19.1828 19.7336 19.1828 19.7336L22.3248 18.1627C22.7186 17.9657 23.1842 17.9759 23.569 18.1899L26.5152 19.8279C26.9382 20.0631 27.2007 20.5091 27.2007 20.9932V24.3755C27.2007 26.098 25.6007 27.3421 23.9687 26.7913C20.6167 25.6603 15.4136 23.5069 12.1157 20.2089C8.81778 16.9111 6.66432 11.7079 5.5333 8.356C4.98262 6.72394 6.22668 5.12402 7.94912 5.12402H11.3309Z" fill="black" stroke="black" strokeWidth="1.33333" strokeLinejoin="round" />
                            </svg>
                            <div className="callUsItemWayName">PHONE</div>
                        </div>
                        <div className="callUsItemNum">03-4446-7313</div>
                    </div>
                    <div>
                        <div className="callUsItemTime">
                            <div className="callUsItemTimeData">
                                {strings.t('工作时间')}
                                <span className="colon">：</span>
                            </div>
                            <div>{strings.t('10:00-13:00 14:15-19:00 北京（GMT+8）')}</div>
                        </div>
                    </div>
                    <div className="callUsItemBtn">
                        <a href="tel:03-4446-7313">
                            {strings.t('立即咨询')}
                        </a>
                    </div>
                </div>
                <div className="callUsItem">
                    <div className="callUsItemSon">
                        <div className="callUsItemWay">
                            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
                                <path d="M3 26H29.6667V16V6H16.3333H3V16V26Z" fill="black" stroke="black" strokeWidth="1.33333" strokeLinejoin="round" />
                                <path d="M3 6L16.3333 16L29.6667 6" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M16.3333 6H3V16" stroke="black" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M29.6663 16V6H16.333" stroke="black" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <div className="callUsItemWayName">E-MAIL</div>
                        </div>
                        <div className="callUsItemNum">info@theckb.com</div>
                    </div>
                    <div className="callUsItemTime callUsItemTimeEmail" />
                    <div className="callUsItemBtn">
                        <a href="mailto:info@theckb.com">
                            {strings.t('立即咨询')}
                        </a>
                    </div>
                </div>
                <div className="callUsItem callUsItemLast">
                    <div className="callUsItemSon">
                        <div className="callUsItemWay">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" height="27" viewBox="0 0 29 27" fill="none">
                                <path d="M28.6665 11.3657C28.6665 5.0995 22.384 0 14.6665 0C6.949 0 0.666504 5.0995 0.666504 11.3657C0.666504 16.9785 5.64817 21.6813 12.374 22.575C12.8302 22.6707 13.4508 22.876 13.6083 23.2633C13.7483 23.6145 13.7005 24.157 13.6527 24.5233L13.4613 25.7133C13.4088 26.0645 13.1813 27.097 14.6852 26.4658C16.1913 25.837 22.7538 21.7082 25.6938 18.3283C27.7052 16.1245 28.6665 13.867 28.6665 11.3657Z" fill="black" />
                                <path d="M23.9964 11.5756C23.9964 11.1719 23.6686 10.8394 23.2614 10.8394H21.2139V9.52689H23.2614C23.6686 9.52689 23.9964 9.19905 23.9964 8.79188C23.9964 8.38938 23.6686 8.05688 23.2649 8.05688H20.4812C20.0752 8.05688 19.7462 8.38938 19.7462 8.79188V14.3581C19.7462 14.7594 20.0752 15.0919 20.4777 15.0919H23.2614C23.6686 15.0919 23.9964 14.7594 23.9964 14.3581C23.9964 13.9532 23.6686 13.6231 23.2614 13.6231H21.2139V12.3106H23.2614C23.6686 12.3106 23.9964 11.9781 23.9964 11.5756Z" fill="white" />
                                <path d="M18.2599 15.0534C18.5609 14.9531 18.7639 14.6731 18.7639 14.3581V8.79188C18.7639 8.38938 18.4314 8.05688 18.0289 8.05688C17.6229 8.05688 17.2939 8.38938 17.2939 8.79188V12.2406L14.4216 8.35555C14.2816 8.18055 14.0716 8.05922 13.8441 8.05922C13.7764 8.05922 13.6877 8.07088 13.6177 8.09772C13.3179 8.19688 13.1161 8.47688 13.1161 8.79188V14.3581C13.1161 14.7594 13.4427 15.0919 13.8464 15.0919C14.2571 15.0919 14.5826 14.7594 14.5826 14.3581V10.9281L17.4327 14.7979C17.5716 14.9846 17.7816 15.0896 18.0277 15.0896C18.1047 15.0896 18.1852 15.0779 18.2599 15.0534Z" fill="white" />
                                <path d="M11.3299 15.0919C11.7371 15.0919 12.0661 14.7594 12.0661 14.3581V8.79188C12.0661 8.38938 11.7371 8.05688 11.3334 8.05688C10.9274 8.05688 10.5984 8.38938 10.5984 8.79188V14.3581C10.5984 14.7594 10.9274 15.0919 11.3299 15.0919Z" fill="white" />
                                <path d="M6.40541 15.0919H9.18908C9.59391 15.0919 9.92291 14.7594 9.92291 14.3581C9.92291 13.9532 9.59508 13.6231 9.18908 13.6231H7.14041V8.79188C7.14041 8.38938 6.81141 8.05688 6.40541 8.05688C6.00291 8.05688 5.67041 8.38938 5.67041 8.79188V14.3581C5.67041 14.7594 6.00291 15.0919 6.40541 15.0919Z" fill="white" />
                            </svg>
                            <div className="callUsItemWayName">LINE</div>
                        </div>
                        <div className="callUsItemNum">{strings.t('THE 直行便')}</div>
                    </div>
                    <div className="callUsItemTime">
                        <div className="callUsItemTimeData">
                            {strings.t('工作时间')}
                            <span className="colon">：</span>
                        </div>
                        <div>{strings.t('10:00-13:00 14:15-19:00 北京（GMT+8）')}</div>
                    </div>
                    <div className="callUsItemBtn">
                        <a target="_blank" href="https://page.line.me/962puoaf?oat_content=url&openQrModal=true" rel="noreferrer">
                            {strings.t('立即咨询')}
                        </a>
                    </div>
                </div>
                <div className="callUsItem callUsItemPad" />
            </div>
        </div>
    );


};


export default CallUs;